/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:CreateAdventureCtrl
 * @description
 * # CreateAdventureCtrl
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('CreateAdventureCtrl', ['$scope', '$filter', '$timeout', 'createAdventureService',
    'NgMap', '$location', '$window', '$state', '$stateParams', '$rootScope', '$cookies', 'operatorCalendarService', 'CONST_HELPER', 'operatorProfileService', 'uiCalendarConfig', 'uppercaseFirstLetterFilter',
    function ($scope, $filter, $timeout, createAdventureService, NgMap, $location, $window, $state, $stateParams, $rootScope, $cookies, operatorCalendarService, CONST_HELPER, operatorProfileService, uiCalendarConfig, uppercaseFirstLetterFilter) {
      // editor configuration....
      $scope.config = CONST_HELPER.EDITOR_CONFIG;
      $scope.primaryActivity = CONST_HELPER.ONE;
      $scope.cEvent = {};
      $scope.updAvlSt = [];
      $scope.addedPercentDiscount = [];
      $scope.addedValueDiscount = [];
      $scope.addedPercentMinimumDays = [];
      $scope.addedValueDiscount = [];
      $scope.addedValueMinimumDays = [];
      $scope.showDiscountInvalidValue = false;
      $scope.showDiscountInvalidMinimumDays = false;
      $scope.selectedQuestionDelete = null;
      $scope.bookingTypeWasChanged = false;
      $scope.disableNightlyPriceSeats = false;
      $scope.actionEdit = 'edit';
      $scope.actionAdd = 'add';
      $scope.texts = {
        dynamicButtonTextSuffix: 'Selected'
      };
      $scope.dmDiscountData = {

      };
      $scope.addNewRoomFlag = false;
      /* event sources array */
      // $scope.eventSources = [];
      $scope.events = [];
      $scope.packageGuestFormEvents = {
        onItemDeselect: function (form) {
          var form_to_delete = $scope.packageForms.find(function (elem) {
            return (elem.id == form.id || elem.parent_id == form.id);
          });

          if(form_to_delete){
            $scope.deleteSelectedForm(form_to_delete);
          }
          var indexOf = CONST_HELPER.MINUS_ONE;
          $scope.packageForms.find(function (pform, index) {
            pform.id == form.id || pform._id == form.id || pform.parent_id == form.id ? indexOf = index : CONST_HELPER.MINUS_ONE;
            return pform.id == form.id || pform._id == form.id || pform.parent_id == form.id;
          });
          if (indexOf != CONST_HELPER.MINUS_ONE) {
            $scope.packageForms.splice(indexOf, 1);
          }
        },
        onItemSelect: function (form) {
          var formObject = $scope.operatorFormOptions.find(function (option) {
            return option.id == form.id;
          });
          // $scope.packageForms.push(formObject);
          $scope.formData.name = formObject.title;
          $scope.formData.parent_id = form.parent_id;
          $scope.formData.action = $scope.actionAdd;
          //$scope.parseQuestionsData(formObject.guest_form_detail ? formObject.guest_form_detail.request : formObject);
          $scope.addEditGuestFormApi(form.id, createRequestObject(true), true);
        }
      };
      $scope.packageForms = [];
      $scope.editDiscountFlag = false;
      // check if package_type or package case get changed from url...
      $scope.type = {};
      $scope.type.package_type = $state.params.package_type;
      $scope.package_case = $state.params.package_case;
      $scope.packageBookingType = $state.params.booking_type;
      $scope.ifEditSlot = $state.params.edit_slot || false;

      // get the rootscope value to compare.
      $rootScope.packageType = $rootScope.packageType ? $rootScope.packageType : $cookies.get('package_type') || $scope.type.package_type;
      $rootScope.packageCase = $rootScope.packageCase ? $rootScope.packageCase : $cookies.get('package_case') || $scope.package_case;
      $rootScope.packageBookingType = $rootScope.packageBookingType ? $rootScope.packageBookingType : $cookies.get('booking_type') || $scope.packageBookingType;

      $scope.changingFlag = function () {
        $scope.addNewRoomFlag = true;
      };
      if (parseInt($scope.type.package_type) !== $rootScope.packageType || parseInt($scope.package_case) !== $rootScope.packageCase || parseInt($scope.packageBookingType) !== $rootScope.packageBookingType) {
        $state.params.package_type = $rootScope.packageType;
        $state.params.package_case = $rootScope.packageCase;
        $state.params.booking_type = $rootScope.packageBookingType;
        $scope.type.package_type = $rootScope.packageType;
        $scope.package_case = $rootScope.packageCase;
        $scope.packageBookingType = $rootScope.packageBookingType;
        $location.search($state.params); // update the url to previous state if anything get changed...
      }
      window.onunload = function () {
        // reset cookies on window reload
        $cookies.put('package_type', $rootScope.packageType);
        $cookies.put('package_case', $rootScope.packageCase);
        $cookies.put('booking_type', $rootScope.packageBookingType);
      };

      var tempStore = {}; // Store newPackage.package_slots value and publish,create obj

      $scope.slotIndex = CONST_HELPER.ZERO; // set slotIndex CONST_HELPER.ZERO for add slot functionality
      $scope.editorEnabled = CONST_HELPER.MINUS_ONE;
      $scope.updatePrimaryActive = function (params) {
        $scope.primaryActivity = params.id;
      };

      $('.datepicker').datepicker(); // call datepicker
      $scope.newSlots = [];
      $scope.errors = [];
      $scope.nextTabErrorEventDetail = false;
      $scope.nextTabErrorLodging = false;
      $scope.nextTabErrorDuration = false;
      $scope.nextTabErrorItinerary = false;
      $scope.nextTabErrorGallery = false;
      $scope.nextTabErrorAmenities = false;
      $scope.addSlot = {}; // add sloat from popup
      $scope.videoUrl = [];
      $scope.validation = {};
      $scope.newPackage = {}; // scope for new package data
      $scope.addPrevious = {};
      $scope.skiAbilities = [];
      $scope.editAddOnRow = {};
      $scope.requestObject = {};
      $scope.package_sports = []; // array for sports
      $scope.rangesData = []; // array  to store multiple event dates.
      $scope.package_addons = {};
      $scope.cancelPolicyDetail = [];
      $scope.amenities_package_list = [];
      $scope.showStartAndEnd = false;
      $scope.newPackage.base_price = CONST_HELPER.ZERO;
      $scope.newPackage.window_id = CONST_HELPER.THREE;

      if ($rootScope.packageBookingType == CONST_HELPER.BOOKING_TYPE.PACKAGE_SLOTS) {
        $scope.newPackage.booking_type = CONST_HELPER.ONE;
        $scope.newPackage.booking_type_choise = CONST_HELPER.ONE;
        $scope.newPackage.single_day_activity = CONST_HELPER.ZERO;
        $scope.newPackage.daily_rate_activity = CONST_HELPER.ZERO;
        $scope.newPackage.lodging_included = '1';
        $scope.newPackage.event_lodging_status = CONST_HELPER.ONE;
      } else {
        $scope.newPackage.booking_type = CONST_HELPER.TWO;
        if ($rootScope.packageBookingType == CONST_HELPER.BOOKING_TYPE.NIGHTLY_SLOTS) {
          $scope.newPackage.single_day_activity = CONST_HELPER.ZERO;
          $scope.newPackage.daily_rate_activity = CONST_HELPER.ZERO;
          $scope.newPackage.booking_type_choise = CONST_HELPER.TWO;
          $scope.newPackage.lodging_included = '1';
          $scope.newPackage.event_lodging_status = CONST_HELPER.ONE;
          $scope.bookinLimitPlaceholder = 'nights';
        } else if ($rootScope.packageBookingType == CONST_HELPER.BOOKING_TYPE.DAILY_RATE) {
          $scope.newPackage.single_day_activity = CONST_HELPER.ZERO;
          $scope.newPackage.daily_rate_activity = CONST_HELPER.ONE;
          $scope.newPackage.booking_type_choise = CONST_HELPER.FOUR;
          $scope.newPackage.lodging_included = '1';
          $scope.newPackage.event_lodging_status = CONST_HELPER.ONE;
          $scope.bookinLimitPlaceholder = 'days';
        } else if ($rootScope.packageBookingType == CONST_HELPER.BOOKING_TYPE.SINGLE_DAY) {
          $scope.newPackage.single_day_activity = CONST_HELPER.ONE;
          $scope.newPackage.daily_rate_activity = CONST_HELPER.ZERO;
          $scope.newPackage.booking_type_choise = CONST_HELPER.THREE;
          $scope.newPackage.event_lodging_status = CONST_HELPER.ZERO;
          $scope.newPackage.lodging_included = '0';
        }
      }

      $scope.newPackage.advance_notice_id = CONST_HELPER.ZERO; // default same day notice
      $scope.newPackage.package_discounts = []; // package discounts
      $scope.newCreatedForms = [];
      $scope.newPackage.booking_limit = 0;
      $scope.newPackage.booking_minimum_days = '';
      $scope.newPackage.booking_maximum_days = '';
      $scope.newPackage.tax = {};
      $scope.operatorTaxFeeOptionsInternational = [];
      $scope.operatorTaxFeeOptionsDomestic = [];
      $scope.operatorTaxFeeInternational = [];
      $scope.operatorTaxFeeDomestic = [];

      if ($scope.user && $scope.user.operator_policy && $scope.user.operator_policy.operator_taxes) {
        initializeTaxesFromProfile($scope.user.operator_policy.operator_taxes);
      } else {
        operatorProfileService.getOperatorProfile($scope.user.token).then(function (response) {
          if(response && response.data.code == CONST_HELPER.API_RESPONSE.OK){
            initializeTaxesFromProfile(response.data.data.operator_policy.operator_taxes);
          }
        });
      }

      function initializeTaxesFromProfile (taxes) {
        $scope.newPackage.tax.international = taxes.international;
        $scope.newPackage.tax.domestic = taxes.domestic;
        $scope.newPackage.tax.country_id = taxes.country_id;
        if (taxes.international) {
          $scope.operatorTaxFeeOptionsInternational = taxes.international;
          $scope.operatorTaxFeeOptionsInternational.map(function (tax) {
            tax.label = tax.name;
          });
          $scope.operatorTaxFeeInternational = $scope.operatorTaxFeeOptionsInternational.slice()
        }
        if (taxes.domestic) {
          $scope.operatorTaxFeeOptionsDomestic = taxes.domestic;
          $scope.operatorTaxFeeOptionsDomestic.map(function (tax) {
            tax.label = tax.name;
          });
          $scope.operatorTaxFeeDomestic = $scope.operatorTaxFeeOptionsDomestic.slice()
        }
      }

      $scope.operatorTaxFeeOptionsInternationalEvents = {
        onItemDeselect: function (tax) {
          $scope.newPackage.tax.international = $scope.newPackage.tax.international.filter(function (taxObj) {
            if(tax.id != taxObj.id){
              taxObj.label = taxObj.name;
              return taxObj
            }
          });
        },
        onItemSelect: function (tax) {
          $scope.user.operator_policy.operator_taxes.international.find(function(findTax) {
            if (findTax.id == tax.id){
              $scope.newPackage.tax.international.push(findTax)
            }
          })
        }
      };
      $scope.operatorTaxFeeOptionsDomesticEvents = {
        onItemDeselect: function (tax) {
          $scope.newPackage.tax.domestic = $scope.newPackage.tax.domestic.filter(function (taxObj) {
            if(tax.id != taxObj.id){
              taxObj.label = taxObj.name;
              return taxObj
            }
          });
        },
        onItemSelect: function (tax) {
          $scope.user.operator_policy.operator_taxes.domestic.find(function(findTax) {
            if (findTax.id == tax.id){
              $scope.newPackage.tax.domestic.push(findTax)
            }
          })
        }
      };
      $scope.taxTypesList = [
        {
          id: CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.PERCENTAGE.ID,
          name: uppercaseFirstLetterFilter(CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.PERCENTAGE.NAME)
        },
        {
          id: CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.AMOUNT.ID,
          name: uppercaseFirstLetterFilter(CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.AMOUNT.NAME)
        }
      ];

      $scope.bookingWindow = [
        {
          'id': CONST_HELPER.THREE,
          name: '3 Months into the Future'
        },
        {
          'id': CONST_HELPER.SIX,
          name: '6 Months into the Future'
        },
        {
          'id': CONST_HELPER.TWELVE,
          name: '12 Months into the Future'
        },
        {
          'id': CONST_HELPER.ZERO,
          name: 'Custom Dates'
        }
      ];
      $scope.bookingDiscountTypes = [{
        'id': CONST_HELPER.DISCOUNT_TYPE.IN_PERCENTAGE,
        name: 'Percentage'
      },
      {
        'id': CONST_HELPER.DISCOUNT_TYPE.IN_CURRENCY,
        name: 'Amount'
      }
      ];
      $scope.customEventStartDate = '';
      $scope.customEventEndDate = '';
      $scope.nDiscount = {
        discount_type: CONST_HELPER.DISCOUNT_TYPE.IN_PERCENTAGE,
        minimum_no_days: CONST_HELPER.ONE,
        discount_value: CONST_HELPER.ONE
      };
      $scope.advanceNotice = [{
        'id': CONST_HELPER.ZERO,
        name: 'Same Day'
      },
      {
        'id': CONST_HELPER.ONE,
        name: 'At Least 1 Day Notice'
      },
      {
        'id': CONST_HELPER.TWO,
        name: 'At Least 2 Day Notice'
      },
      {
        'id': CONST_HELPER.FIVE,
        name: 'At Least 5 Day Notice'
      },
      {
        'id': CONST_HELPER.SEVEN,
        name: 'At Least 7 Day Notice'
      }
      ];
      // date range picker options
      $scope.dateOptions = {
        locale: {
          format: 'D MMM'
        },
        autoApply: false,
        singleDatePicker: true,
        eventHandlers: {
          'hide.daterangepicker': function () {
            $('.calendar-close i').trigger('click');
          },
          'show.daterangepicker': function () {
            // $scope.dateRangeClicked();
          },
          'apply.daterangepicker': function (event) {
            $scope.updateBookingWindow(event, true, true, true);
          }
        }
      };
      $scope.getCurrency = function () {
        /* get currency list  */
        $scope.homeRedirection(); // check for authorized user
        createAdventureService.getCurrencyList().then(function (response) { // fetch from the service
          if (response) {
            $scope.currencyList = response.data.data; // add to scope
            if (!package_id) {
              angular.forEach($scope.currencyList, function (currency) {
                if (currency.id === CONST_HELPER.ONE) {
                  $scope.newPackage.currency_id = currency.id; // set default value in currency scope
                  $scope.currency_code = currency.code;
                }
              });
            }
            $timeout(function () { // for custom plugin
              $('.currency-selectDropdown').selectpicker();
            }, CONST_HELPER.ZERO);
          }
        }, function () { // check for error
          console.log('error');
          $scope.showPopup('#serverErrModal');
        }).catch(function (error) {
          console.log(error);
        });
      };
      $scope.getCurrency(); // call get currency function

      $scope.getWaiverAdventureList = function (newFileAdded) {
        operatorProfileService.getWaiverDocuments($scope.user.token).then(function (response) {
          if(response && response.data.code == CONST_HELPER.API_RESPONSE.OK){
            $scope.waiverAdventureDocuments = response.data.data;
            if(newFileAdded){
              $scope.newPackage.waiver = $scope.waiverAdventureDocuments.find(function (waiverFind) {
                return waiverFind.id == newFileAdded;
              });
            }
          }
        })
      };
      $scope.getWaiverAdventureList();

      $scope.getAllCountry = function () {
        createAdventureService.getAllListCountry().then(function (response) { // fetch from the service
          if (response && response.data.code == CONST_HELPER.API_RESPONSE.OK) {
            $scope.allcountryName = response.data.data;
          }
        }, function () { // check for error
          console.log('error');
          $scope.showPopup('#serverErrModal');
        }).catch(function (error) {
          console.log(error);
        });
      }
      $scope.getAllCountry();

      $scope.minDateForPicker = moment(); // min date - current date
      $scope.showCustomDateError = false;
      $scope.dateRangeOptions = {
        opens: 'left',
        locale: {
          format: 'D MMM'
        },
        autoApply: false,
        eventHandlers: {
          'hide.daterangepicker': function () {
            $('.calendar-close i').trigger('click');
          },
          'show.daterangepicker': function () {
            // $scope.dateRangeClicked();
          },
          'apply.daterangepicker': function (event) {
            console.log($scope.pBkWndId);
            // $scope.isCustomDateSet = true;
            if (moment(event.model.startDate).isBefore(moment().format('MM/DD/YYYY')) || moment(event.model.endDate).isBefore(moment().format('MM/DD/YYYY'))) {
              $scope.showCustomDateError = true;
              return false;
            } else {
              $scope.showCustomDateError = false;
            }
            $scope.updateBookingWindow(event, true, false, true, ($scope.pBkWndId == $scope.newPackage.window_id && $scope.newPackage.window_id == CONST_HELPER.ZERO));
          }
        }
      };
      $scope.nightly_slot_lodgings = [];
      $scope.newPackage.max_seat_available = '';
      $scope.newPackage.package_slots = [];
      $scope.bookingWindowRange = {
        startDate: moment(),
        endDate: moment()
      };
      $scope.package_skiier_abilities = [];
      $scope.newPackage.package_addons = [];
      $scope.newPackage.package_case = $scope.package_case;
      $scope.newPackage.deposit_type = CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE;
      $scope.newPackage.is_collect_percentage_taxes_on_the_deposit = 0;
      var itinerary = {
        time_period: '',
        day_description: ''
      };
      $scope.newPackage.itinerary_day_description = [];
      $scope.newPackage.itinerary_day_description.push(itinerary);
      /* calendar config object */
      var calendarHeaderObject;
      if ($window.innerWidth <= 767) {
        calendarHeaderObject = {
          'monthHeader': 'ddd',
          'weekHeader': 'ddd D'
        };
      } else {
        calendarHeaderObject = {
          'monthHeader': 'dddd',
          'weekHeader': 'dddd D'
        };
      }
      /* calendar config object */
      $scope.uiConfig = {
        calendar: {
          editable: false,
          eventLimit: true,
          // defaultView: $rootScope.calendarViewType,
          // defaultDate: $rootScope.currentMonthDate,
          header: {
            left: 'prev,next title',
            center: '',
            right: ''
          },
          views: {
            month: {
              eventLimit: CONST_HELPER.FIVE, // adjust to 5 only for month
              columnFormat: calendarHeaderObject.monthHeader
            },
            agenda: {
              eventLimit: CONST_HELPER.EVENTLIMIT // adjust to 25 only for Week/Day
            },
            week: {
              columnFormat: calendarHeaderObject.weekHeader
            }
          },
          // eventClick: $scope.alertOnEventClick,
          // eventDrop: $scope.confirmSlotDateChange,
          // eventResize: $scope.alertOnResize,
          dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          eventClick: function (date) {
            // if ($scope.newPackage.lodgingDetail && $scope.newPackage.lodgingDetail.length > 0) {
            // dayClick: function (date, jsEvent, view) {
            $scope.cEvent = arguments[CONST_HELPER.ZERO];
            $scope.cEvent.start = moment($scope.cEvent.start).format('MM/DD/YYYY');
            $scope.cEvent.end = moment($scope.cEvent.start).format('MM/DD/YYYY');
            $scope.cEvent.price = parseInt($scope.cEvent.price);
            $scope.cEvent.private_price = parseInt($scope.cEvent.private_price);
            $scope.cEvent.private_slot_available = parseInt($scope.cEvent.private_price) && parseInt($scope.cEvent.private_price) > 0 ? true : false;
            $scope.cEvent.max_capacity = parseInt($scope.cEvent.max_capacity);
            $scope.cEvent.available_room = parseInt($scope.cEvent.available_room);
            // $scope.cEvent.is_block = parseInt($scope.cEvent.is_block);
            if (moment($scope.cEvent.date).isAfter($scope.bookingWindowRange.endDate)) {
              $scope.cEvent.is_block = parseInt($scope.cEvent.forceActive ? 0 : 1);
            } else {
              $scope.cEvent.is_block = parseInt($scope.cEvent.is_block);
            }
            $scope.showInvalidStartDateError = false;
            $scope.showInvalidEndDateError = false;
            $scope.showPopup('#updateCalendarEvent');
            // }
            $timeout(function () {
              $('.datepicker').datepicker('update');
            }, 0);
          },
          dayClick: function (date) {
            // if ($scope.newPackage.lodgingDetail && $scope.newPackage.lodgingDetail.length > 0) {
            // Fetch moment date
            date = date.format('YYYY-MM-DD');
            var allEvents = uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('clientEvents');
            // Fetch event from all events
            var clickedDateEvent = allEvents.find(function (e) {
              return e.date == date;
            });
            if (clickedDateEvent) {
              $scope.cEvent = clickedDateEvent;
              $scope.cEvent.start = moment($scope.cEvent.start).format('MM/DD/YYYY');
              $scope.cEvent.end = moment($scope.cEvent.start).format('MM/DD/YYYY');
              $scope.cEvent.price = parseInt($scope.cEvent.price);
              $scope.cEvent.private_price = parseInt($scope.cEvent.private_price);
              $scope.cEvent.private_slot_available = parseInt($scope.cEvent.private_price) && parseInt($scope.cEvent.private_price) > 0 ? true : false;
              $scope.cEvent.max_capacity = parseInt($scope.cEvent.max_capacity);
              if (moment(date).isAfter($scope.bookingWindowRange.endDate)) {
                $scope.cEvent.is_block = parseInt($scope.cEvent.forceActive ? 0 : 1);
              } else {
                $scope.cEvent.is_block = parseInt($scope.cEvent.is_block);
              }
              $scope.cEvent.available_room = parseInt($scope.cEvent.available_room);
              $scope.showInvalidStartDateError = false;
              $scope.showInvalidEndDateError = false;
              $scope.showPopup('#updateCalendarEvent');
              $timeout(function () {
                $('.datepicker').datepicker('update');
              }, 0);
            }
            // }
          },
          eventRender: function (event, eventElement) {
            if ($scope.newPackage.window_id != CONST_HELPER.ZERO) {
              if (moment(event.date).isAfter($scope.bookingWindowRange.endDate) && event.forceActive == CONST_HELPER.ZERO) {
                $('td.fc-day[data-date="' + event.date + '"]').addClass('block-bg');
              } else if (moment(event.date).isBefore($scope.bookingWindowRange.endDate) && event.forceActive == CONST_HELPER.ZERO && event.is_block == CONST_HELPER.ONE) {
                // SHOW DATE AS BLOCKED
                $('td.fc-day[data-date="' + event.date + '"]').addClass('block-bg');
              } else if (event.is_block == CONST_HELPER.ZERO) {
                $('td.fc-day[data-date="' + event.date + '"]').removeClass('block-bg');
              }
            } else {
              if (event.is_block == CONST_HELPER.ONE) {
                $('td.fc-day[data-date="' + event.date + '"]').addClass('block-bg');
              } else if (event.is_block == CONST_HELPER.ZERO) {
                $('td.fc-day[data-date="' + event.date + '"]').removeClass('block-bg');
              }
            }
          }
        }
      };
      // GUEST FORM DATA
      $scope.formData = {
        name: '',
        questions: []
      };

      $scope.showQuestionError = false; // Question Error
      $scope.showMaxLengthError = false; // max length error
      $scope.showOptionalError = false; // Optional field error
      $scope.showOptionsError = false; // options for dropdown and multi options error
      $scope.showFormSaveError = false; // Form save error

      $scope.temp = '';

      $scope.allQuestions = [];

      $scope.questionData = {
        type: '',
        questionText: ''
      };

      $scope.questionsType = [{
        id: 1,
        name: 'Short Text'
      }, {
        id: 2,
        name: 'Long Text'
      }, {
        id: 3,
        name: 'Statement'
      }, {
        id: 4,
        name: 'Multiple Choice'
      }, {
        id: 5,
        name: 'Dropdown'
      }];
      $scope.operatorFormModal = [];
      $scope.operatorFormOptions = [];
      $scope.multiSelectSettings = {
        checkBoxes: true,
        showCheckAll: false,
        showUncheckAll: false,
        selectedToTop: true,
        selectionCount: 'Selected'
      };
      // GUEST FORM DATA

      /* alert on eventClick */
      $scope.alertOnEventClick = function (data, jsEvent, view) {
        $scope.alertMessage = (data.title + ' was clicked ');
        $scope.eventDetail = data.slot;
        if ($window.innerWidth <= 767 && $rootScope.calendarViewType === 'basicWeek') {
          $state.go('operator-edit-slots', {
            'slot_id': data.slot.id
          });
        } else {
          $timeout(function () {
            $scope.showeventDetail = true;
          }, 200);
        }
      };
      /* Change View */
      $scope.changeView = function (view, calendar) {
        uiCalendarConfig.calendars[calendar].fullCalendar('changeView', view);
      };
      $scope.marker = {
        'lat': CONST_HELPER.DEFAULT_LATITUDE,
        'lng': CONST_HELPER.DEFAULT_LONGITUDE
      };
      $scope.mobileHash = 'LOCATION';
      // eslint-disable-next-line no-useless-escape
      $scope.linkPattern = /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/;
      $scope.currentHash = $location.hash().split('#')[CONST_HELPER.ONE] || 'location';
      $scope.newPackage.latitude = CONST_HELPER.DEFAULT_LATITUDE;
      $scope.newPackage.longitude = CONST_HELPER.DEFAULT_LONGITUDE;
      $scope.newPackage.location_name = CONST_HELPER.DEFAULT_LOCATION_NAME;
      $scope.newPackage.amount_percentage = CONST_HELPER.ZERO;
      $scope.newPackage.lodgingDetail = [];

      $scope.saveErr = false;
      $scope.disableNext = false;
      $scope.publishError = false;
      $scope.disabledEdit = false;
      $scope.showEventName = false;
      $scope.lodgingCompleted = true;
      $scope.publishPackageErr = false;
      $scope.addSlotDurationEmpty = false;
      $scope.showRedTickEvent = false;
      $scope.showRedTickGallery = false;
      $scope.showRedTickDuration = false;
      $scope.showRedTickItinerary = false;
      $scope.showRedTickAmenities = false;
      $scope.showGreenTickEvent = !!$scope.ifEditSlot;
      $scope.showGreenTickLodging = !!$scope.ifEditSlot;
      $scope.showGreenTickGallery = false;
      $scope.showGreenTickDuration = false;
      $scope.showGreenTickItinerary = false;
      $scope.showGreenTickAmenities = false;
      $scope.maxCapacityErr = false;
      $scope.availableSeatErr = false;
      $scope.timePeriodError = false; // set true when user did not enter time period.
      $scope.isCheckbox = { // using to set checkbox disabled if any package purchase
        abilityArr: [],
        sportsArr: [],
        amenityArr: []
      };
      $scope.lodging = { // initialize lodging object.
        lodging_medias: []
      };
      $scope.lodgingObjCopy = [];
      $scope.allLocations = [];
      $scope.newPackage.op_location_airports = [];
      $scope.newPackage.op_location_medias = [];
      $scope.newPackage.op_location_videos = [];
      $scope.location_description_synced = true;
      $scope.arrival_instruction_synced = true;
      $scope.depature_instruction_synced = true;
      $scope.singleCalendarMinDate = '';
      $scope.singleCalendarMaxDate = '';
      $scope.singleCalendarEndStartDate = '';
      $scope.pBkWndId = CONST_HELPER.THREE;
      $scope.isCustomDateSet = false;
      var package_id = $state.params.package_id; // set package_id in variable
      $scope.editPackage = !!$state.params.package_id;
      var maxImageLength = CONST_HELPER.PACKAGE.MAX_GALLERY_IMAGES;
      $scope.setEvents = function (newAddedLodging) {
        // Find previous events and update calendar
        var nightlySlots = {};
        if (newAddedLodging) {
          nightlySlots = $scope.nightly_slot_lodgings[0];
        } else {
          if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) && !$scope.newPackage.lodge_id) {
            // CASE A NO Lodging
            nightlySlots = $scope.nightly_slot_lodgings[0];
            $scope.rangesData = $scope.nightly_slot_lodgings[0].updated_dates;
          } else if ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && $scope.newPackage.lodge_id) {
            // CASE C NO LODGING BUT LODGE ID YES
            nightlySlots = $scope.nightly_slot_lodgings[0];
            $scope.rangesData = $scope.nightly_slot_lodgings[0].updated_dates;
          } else if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ONE || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION)) {
            // CASE A Lodging YES
            nightlySlots = $scope.nightly_slot_lodgings && $scope.nightly_slot_lodgings.length > 0 ? $scope.nightly_slot_lodgings.find(function (lodge) {
              return lodge.lodging_id == $scope.newPackage.lodge_id;
            }) : null;
            $scope.rangesData = [];
            $scope.nightly_slot_lodgings.forEach(function (lodging) {
              lodging.updated_dates ? lodging.updated_dates.forEach(function (date) {
                if (lodging.lodging_id == date.lodge_id) {
                  $scope.rangesData.push(date);
                }
              }) : null;
            });
            $scope.newPackage.available_room = nightlySlots ? nightlySlots.available_room : '';
          }
        }

        if (nightlySlots && !newAddedLodging) {
          if (nightlySlots.calendar && nightlySlots.calendar.length == CONST_HELPER.ZERO) {
            $scope.createEventsObject();
            return;
          }
          // if (nightlySlots.calendar && nightlySlots.calendar[0] && !nightlySlots.calendar[0].start) {
          if ($scope.newPackage && $scope.newPackage.window_id) {
            nightlySlots.calendar = nightlySlots.calendar.filter(function (cEvent) {
              return moment(cEvent.date).isSameOrAfter(moment($scope.bookingWindowRange.startDate).format('YYYY-MM-DD'));
            });
          }
          nightlySlots.calendar.map(function (cEvent) {
            var _price = cEvent.price || nightlySlots.price;
            var _private_price = cEvent.private_price || nightlySlots.private_price;
            // if (updateLodgePrice){ _price = nightlySlots.price; _private_price = nightlySlots.private_price; }
            cEvent.start = moment(cEvent.date).format('YYYY-MM-DD');
            cEvent.end = moment(cEvent.date).format('YYYY-MM-DD');
            cEvent.price = _price;
            cEvent.private_price = _private_price;
            cEvent.title = $filter('currency')(_price, $scope.currencySymbol[$scope.currency_code],
              CONST_HELPER.ZERO);
            cEvent.stick = true;
            cEvent.textColor = cEvent.textColor || '#787878';
            cEvent.backgroundColor = 'transparent';
            cEvent.borderColor = 'transparent';
            cEvent.className = ['myTitle'];
            return cEvent;
          });
          // }

          setTimeout(function () {
            uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('removeEvents');
            uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('renderEvents', convertToMoment(nightlySlots.calendar), true);
            uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('render');
          }, 200);
        } else {
          $scope.createEventsObject();
        }
      };
      if ($scope.ifEditSlot) {
        $scope.showGreenTickLocation = true;
        if (!$scope.$$phase) {
          $('.z-index5 a').trigger('click');
        }
      }

      checkUploadingGoogleMap();

      document.addEventListener('googleMapApiLoaded', checkUploadingGoogleMap);

      function checkUploadingGoogleMap() {
        $scope.googleMapApiLoaded = googleApiLoaded;
      }

      $scope.$on('mapentrySelected', function (event, data) {
        if($scope.googleMapApiLoaded){
          var lat = data.geometry.location.lat();
          var lng = data.geometry.location.lng();
          var bounds = new google.maps.LatLngBounds();
          var latlng = new google.maps.LatLng(lat, lng);
          bounds.extend(latlng);
          NgMap.getMap().then(function (map) {
            getLocationName(latlng, lat, lng);
            map.setCenter(bounds.getCenter());
            $scope.marker = {
              'lat': lat,
              'lng': lng
            };
            map.fitBounds(bounds);
            map.setZoom(CONST_HELPER.TWELVE);
            $scope.newPackage.latitude = lat;
            $scope.newPackage.longitude = lng;
          });
        }
      });

      $scope.$watch('newPackage.location_name', function (event, data) {
        if ($scope.marker.lat && $scope.marker.lng) {
          var bounds = new google.maps.LatLngBounds();
          var latlng = new google.maps.LatLng($scope.marker.lat, $scope.marker.lng);
          bounds.extend(latlng);
          NgMap.getMap().then(function (map) {
            map.setCenter(bounds.getCenter());
            map.fitBounds(bounds);
            map.setZoom(CONST_HELPER.TWELVE);
            $scope.newPackage.latitude = $scope.marker.lat;
            $scope.newPackage.longitude = $scope.marker.lng;
          });
        }
      });

      $scope.onDragEnd = function ($event, ctrl) {
        if($scope.googleMapApiLoaded){
          var lat = $event.latLng.lat();
          var lng = $event.latLng.lng();
          $scope.marker.lat = lat;
          $scope.marker.lng = lng;
          var bounds = new google.maps.LatLngBounds();
          var latlng = new google.maps.LatLng(lat, lng);
          bounds.extend(latlng);

          NgMap.getMap().then(function (map) {
            getLocationName(latlng, lat, lng);
          });
        }
      };


      // set Dates for Booking window
      $scope.updateBookingWindow = function (event, createEvents, resetNgtSlts, usePreviousEvents) {
        $scope.pBkWndId = JSON.parse(JSON.stringify($scope.newPackage.window_id));
        if (resetNgtSlts) {
          resetNightSlots();
        }

        if ($scope.newPackage && $scope.newPackage.window_id) {
          // $scope.rangesData = [];
          $scope.newPackage.booking_start_date = $filter('date')(new Date(), 'MM/dd/yyyy');
          $scope.bookingWindowRange.startDate = moment($scope.newPackage.booking_start_date);
          var temp_date = moment($scope.newPackage.booking_start_date).add($scope.newPackage.window_id, 'months');
          $scope.newPackage.booking_end_date = $filter('date')(new Date(temp_date), 'MM/dd/yyyy');
          $scope.bookingWindowRange.endDate = moment($scope.newPackage.booking_end_date);
        } else if ($scope.newPackage.window_id === CONST_HELPER.ZERO && event) {
          // $scope.rangesData = [];
          $scope.bookingWindowRange.startDate = moment($filter('date')(new Date(event.model.startDate), 'MM/dd/yyyy'));
          $scope.bookingWindowRange.endDate = moment($filter('date')(new Date(event.model.endDate), 'MM/dd/yyyy'));
          $scope.customEventStartDate = JSON.parse(JSON.stringify(moment($scope.bookingWindowRange.startDate).toString()));
          $scope.customEventEndDate = JSON.parse(JSON.stringify(moment($scope.bookingWindowRange.endDate).toString()));
        } else if ($scope.newPackage.window_id === CONST_HELPER.ZERO && (event === null || event === undefined)) {
          $scope.customEventStartDate = JSON.parse(JSON.stringify(moment($scope.bookingWindowRange.startDate).toString()));
          $scope.customEventEndDate = JSON.parse(JSON.stringify(moment($scope.bookingWindowRange.endDate).toString()));
          $scope.bookingWindowRange.startDate = null;
          $scope.bookingWindowRange.endDate = null;
          usePreviousEvents = false;
        }
        // CREATE EVENTS ONLY IF NIGHTLY SLOT BOOKING
        createEvents && $scope.newPackage.booking_type == CONST_HELPER.TWO ? $scope.createEventsObject(usePreviousEvents) : null;
        $scope.bookingWindowRange.endDate ? $scope.newPackage.publish_end_date = $scope.bookingWindowRange.endDate : null;
      };

      function resetNightSlots () {
        $scope.nightly_slot_lodgings ? $scope.nightly_slot_lodgings.map(function (slot) {
          slot.calendar = [];
          return slot;
        }) : null;
      }
      // CONVERT DATES TO MOMENT BEFORE RENDER
      function convertToMoment (events) {
        events.map(function (event) {
          event.start = moment(event.start).format('YYYY-MM-DD');
          event._start = moment(event.start).format('YYYY-MM-DD');
          event.end = moment(event.end).format('YYYY-MM-DD');
          event._end = moment(event.end).format('YYYY-MM-DD');
          return event;
        });
        return events;
      }

      $scope.createEventsObject = function (usePreviousEvents) {
        var event = [];
        var previousEvent = [];
        var lodgeObject = {};
        // Find Lodging Object
        // remove lodging details if selected location with default lodging for single day activity
        if($scope.newPackage.single_day_activity == 1 && !$scope.newPackage.package_id){
           $scope.newPackage.lodgingDetail = [];
        }
        lodgeObject = $scope.newPackage.lodgingDetail.find(function (lodge) {
          return lodge.id == $scope.newPackage.lodge_id;
        });
        if (usePreviousEvents) {
          // TODO CHECK IF EVENTS PRESENT use Same Events
          previousEvent = [];
          if ($scope.newPackage.window_id == CONST_HELPER.ZERO) {
            event = previousEvent;
          }
          if ($scope.rangesData && $scope.rangesData.length > CONST_HELPER.ZERO && $scope.newPackage.window_id === CONST_HELPER.ZERO) {
            $scope.rangesData.forEach(function (rangeData) {
              var endDate = moment(rangeData.end);
              for (var m = moment(rangeData.start); m.isSameOrBefore(endDate); m.add(CONST_HELPER.ONE, 'days')) {
                // TODO: Find in previous event if event already present.
                var findAlreadyIn = previousEvent.find(function (event) {
                  return moment(event.date).format('YYYY-MM-DD') == m.format('YYYY-MM-DD').toString() && rangeData.lodge_id == event.lodge_id;
                });
                if (!findAlreadyIn) {
                  var _tempObj = JSON.parse(JSON.stringify(rangeData));
                  _tempObj.start = m.format('MM/DD/YYYY').toString();
                  _tempObj.date = m.format('YYYY-MM-DD').toString();
                  _tempObj.title = $filter('currency')(_tempObj.price, $scope.currencySymbol[$scope.currency_code],
                    CONST_HELPER.ZERO);
                  _tempObj.textColor = rangeData.is_block ? '#bdbdbd' : (parseInt(lodgeObject.price) != parseInt(rangeData.price) ? '#4a90e2' : '#787878');
                  _tempObj.is_block = rangeData.is_block || CONST_HELPER.ZERO,
                  _tempObj.end = m.format('MM/DD/YYYY').toString();
                  _tempObj = JSON.parse(JSON.stringify(_tempObj));
                  previousEvent.push(_tempObj);
                }
              }
            });
          }
          $scope.showLoader();
        }
        $scope.newPackage.lodge_id = $scope.package_case != CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE ? ($scope.newPackage.lodge_id || ($scope.newPackage.lodgingDetail && $scope.newPackage.lodgingDetail.length > CONST_HELPER.ZERO ? $scope.newPackage.lodgingDetail[CONST_HELPER.ZERO].id : null)) : null;

        setTimeout(function () {
          var noOfYears = CONST_HELPER.TWO;
          $scope.newPackage.window_id == CONST_HELPER.ZERO ? noOfYears = CONST_HELPER.ZERO : null;
          if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) && !$scope.newPackage.lodge_id) {
            // CASE NO LODGING
            for (var m = moment($scope.bookingWindowRange.startDate); m.isSameOrBefore(moment($scope.bookingWindowRange.endDate).add(noOfYears, 'year')); m.add(CONST_HELPER.ONE, 'days')) {
              var isDateAfterEndDate = m.isAfter($scope.bookingWindowRange.endDate);
              var fEvntPrst = previousEvent.find(function (evnt) {
                return moment(evnt.date).format('YYYY-MM-DD') == m.format('YYYY-MM-DD') && evnt.lodge_id == $scope.newPackage.lodge_id;
              });
              if (!fEvntPrst) {
                var obj = {
                  date: m.format('YYYY-MM-DD'),
                  price: $scope.newPackage.base_price,
                  available_seat: $scope.newPackage.max_seat_available,
                  max_capacity: $scope.newPackage.max_seat_available,
                  // Rolling: Operator setting is_block to Zero Rolling for all events
                  // 3 oct is_block: isDateAfterEndDate ? CONST_HELPER.ONE : CONST_HELPER.ZERO,
                  is_block: CONST_HELPER.ZERO,
                  forceActive: isDateAfterEndDate ? CONST_HELPER.ZERO : CONST_HELPER.ONE,
                  title: $filter('currency')($scope.newPackage.base_price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO),
                  start: m.format('YYYY-MM-DD'),
                  end: m.format('YYYY-MM-DD'),
                  stick: true,
                  textColor: '#787878',
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  className: ['myTitle']
                };
                // After 2 years
                isDateAfterEndDate ? obj.isAfterBookingRange = isDateAfterEndDate : delete obj.isAfterBookingRange;
                event.push(obj);
              } else {
                fEvntPrst.isPreviousEvent = true;
                if (fEvntPrst.isAfterBookingRange && m.isSameOrBefore($scope.bookingWindowRange.endDate)) {
                  fEvntPrst.is_block = CONST_HELPER.ZERO;
                } else {
                  fEvntPrst.is_block = isDateAfterEndDate ? CONST_HELPER.ZERO : (fEvntPrst.is_block ? CONST_HELPER.ONE : CONST_HELPER.ZERO);
                  // (fEvntPrst.is_block ? true : CONST_HELPER.ZERO);
                }
                $scope.newPackage.window_id != CONST_HELPER.ZERO ? event.push(fEvntPrst) : null;
              }
            };
            lodgeObject = {
              id: CONST_HELPER.ZERO,
              price: $scope.newPackage.base_price,
              max_seat_available: $scope.newPackage.max_seat_available,
              max_capacity: $scope.newPackage.max_seat_available
            };
          } else if ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && $scope.newPackage.lodge_id) {
            if ($scope.newPackage.event_lodging_status != CONST_HELPER.ZERO) {
              lodgeObject = $scope.newPackage.lodgingDetail.find(function (lodge) {
                return lodge.id == $scope.newPackage.lodge_id;
              });
            } else {
              lodgeObject = null;
            }
            // FOR CUSTOM DATES WE ADD 0 YEARS
            var noOfYears = CONST_HELPER.TWO;
            $scope.newPackage.window_id == CONST_HELPER.ZERO ? noOfYears = CONST_HELPER.ZERO : null;
            for (var m = moment($scope.bookingWindowRange.startDate); m.isSameOrBefore(moment($scope.bookingWindowRange.endDate).add(noOfYears, 'year')); m.add(CONST_HELPER.ONE, 'days')) {
              var isDateAfterEndDate = m.isAfter($scope.bookingWindowRange.endDate);
              var fEvntPrst = previousEvent.find(function (evnt) {
                return moment(evnt.date).format('YYYY-MM-DD') == m.format('YYYY-MM-DD') && evnt.lodge_id == $scope.newPackage.lodge_id;
              });
              if (!fEvntPrst) {
                var obj = {
                  date: m.format('YYYY-MM-DD'),
                  price: lodgeObject ? lodgeObject.price : ($scope.newPackage.base_price ? $scope.newPackage.base_price : CONST_HELPER.ZERO),
                  private_price: lodgeObject ? lodgeObject.private_price : CONST_HELPER.ZERO,
                  total_bed: lodgeObject ? lodgeObject.max_guests * $scope.newPackage.available_room : CONST_HELPER.ZERO,
                  max_capacity: $scope.newPackage.max_seat_available,
                  available_seat: $scope.newPackage.max_seat_available,
                  available_room: $scope.newPackage.available_room,
                  // Rolling: Operator setting is_block to Zero Rolling for all events
                  // 3 oct is_block: isDateAfterEndDate ? CONST_HELPER.ONE : CONST_HELPER.ZERO,
                  is_block: CONST_HELPER.ZERO,
                  forceActive: isDateAfterEndDate ? CONST_HELPER.ZERO : CONST_HELPER.ONE,
                  title: $filter('currency')((lodgeObject ? lodgeObject.price : ($scope.newPackage.base_price ? $scope.newPackage.base_price : CONST_HELPER.ZERO)), $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO),
                  start: m.format('YYYY-MM-DD'),
                  end: m.format('YYYY-MM-DD'),
                  stick: true,
                  textColor: '#787878',
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  className: ['myTitle']
                };
                // After 2 years
                isDateAfterEndDate ? obj.isAfterBookingRange = isDateAfterEndDate : delete obj.isAfterBookingRange;
                event.push(obj);
              } else {
                fEvntPrst.isPreviousEvent = true;
                if (fEvntPrst.isAfterBookingRange && m.isSameOrBefore($scope.bookingWindowRange.endDate)) {
                  fEvntPrst.is_block = CONST_HELPER.ZERO;
                } else {
                  fEvntPrst.is_block = isDateAfterEndDate ? CONST_HELPER.ZERO : (fEvntPrst.is_block ? CONST_HELPER.ONE : CONST_HELPER.ZERO);
                }
                // (fEvntPrst.is_block ? true : CONST_HELPER.ZERO);
                $scope.newPackage.window_id != CONST_HELPER.ZERO ? event.push(fEvntPrst) : null;
              }
            }
          } else if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ONE || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION)) {
            var noOfYears = CONST_HELPER.TWO;
            $scope.newPackage.window_id == CONST_HELPER.ZERO ? noOfYears = CONST_HELPER.ZERO : null;

            for (var m = moment($scope.bookingWindowRange.startDate); m.isSameOrBefore(moment($scope.bookingWindowRange.endDate).add(noOfYears, 'year')); m.add(CONST_HELPER.ONE, 'days')) {
              var isDateAfterEndDate = m.isAfter($scope.bookingWindowRange.endDate);
              var fEvntPrst = previousEvent.find(function (evnt) {
                return moment(evnt.date).format('YYYY-MM-DD') == m.format('YYYY-MM-DD') && (evnt.lodge_id == $scope.newPackage.lodge_id || !evnt.lodge_id);
              });
              // if seat in other lodging is not equal to default seat per day...
              if (!fEvntPrst) {
                var obj = {
                  date: m.format('YYYY-MM-DD'),
                  price: lodgeObject ? lodgeObject.price : CONST_HELPER.ZERO,
                  private_price: lodgeObject ? lodgeObject.private_price : CONST_HELPER.ZERO,
                  total_bed: lodgeObject ? lodgeObject.max_guests * $scope.newPackage.available_room : CONST_HELPER.ZERO,
                  available_seat: $scope.newPackage.max_seat_available,
                  available_room: $scope.newPackage.available_room,
                  max_capacity: $scope.newPackage.max_seat_available,
                  // Rolling: Operator setting is_block to Zero Rolling for all events
                  // 3 oct is_block: isDateAfterEndDate ? CONST_HELPER.ONE : CONST_HELPER.ZERO,
                  is_block: CONST_HELPER.ZERO,
                  forceActive: isDateAfterEndDate ? CONST_HELPER.ZERO : CONST_HELPER.ONE,
                  title: $filter('currency')((lodgeObject ? lodgeObject.price : CONST_HELPER.ZERO), $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO),
                  start: m.format('YYYY-MM-DD'),
                  end: m.format('YYYY-MM-DD'),
                  stick: true,
                  textColor: '#787878',
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  className: ['myTitle']
                };
                // After 2 years
                isDateAfterEndDate ? obj.isAfterBookingRange = isDateAfterEndDate : delete obj.isAfterBookingRange;
                // }
                event.push(obj);
              } else {
                fEvntPrst.isPreviousEvent = true;
                if (fEvntPrst.isAfterBookingRange && m.isSameOrBefore($scope.bookingWindowRange.endDate)) {
                  fEvntPrst.is_block = CONST_HELPER.ZERO;
                } else {
                  fEvntPrst.is_block = isDateAfterEndDate ? CONST_HELPER.ZERO : (fEvntPrst.is_block ? CONST_HELPER.ONE : CONST_HELPER.ZERO);
                }
                // (fEvntPrst.is_block ? true : CONST_HELPER.ZERO);
                $scope.newPackage.window_id != CONST_HELPER.ZERO ? event.push(fEvntPrst) : null;
              }
            }
          }

          // update previous events and add new events
          $scope.events.splice(CONST_HELPER.ZERO);
          // uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('option', 'visibleRange', { start: '2019-04-01', end: '2019-05-05' });
          uiCalendarConfig.calendars['operatorCalendar'] ? uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('removeEvents') : null;
          uiCalendarConfig.calendars['operatorCalendar'] ? uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('renderEvents', convertToMoment(event), true) : null; // renderEvents(event);
          // uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('setOption', 'visibleRange');
          setTimeout(function () {
            uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('render');
          }, 500);

          createLodgingObject(event, lodgeObject, false, null);
          if (package_id && $scope.nightly_slot_lodgings && $scope.nightly_slot_lodgings.length > 0) {
            if ($scope.nightly_slot_lodgings[0] && $scope.nightly_slot_lodgings[0].calendar) {
              var eventsOblocekd = $scope.nightly_slot_lodgings[0].calendar.filter(function (event) {
                return event.is_block && (moment(event.date).isBetween($scope.bookingWindowRange.startDate, $scope.bookingWindowRange.endDate) || moment(event.date).isSame($scope.bookingWindowRange.startDate) || moment(event.date).isSame($scope.bookingWindowRange.endDate));
              });
              if (eventsOblocekd) {
                findEventForAllLodging(eventsOblocekd || [], $scope.nightly_slot_lodgings, false);
              }
            }
          }
        }, 100);

        setTimeout(function () {
          $scope.hideLoader();
        }, 1500);
      };

      function getLocationName (latLng, lat, lng) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
          'location': latLng
        }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[CONST_HELPER.ZERO]) {
              $scope.newPackage.location_name = results[CONST_HELPER.ZERO].formatted_address;
              $scope.newPackage.latitude = lat;
              $scope.newPackage.longitude = lng;
              $timeout(angular.noop);
            } else {
              $scope.showPopup('#errModal');
            }
          } else {
            $scope.showPopup('#errModal');
          }
        });
      }

      // get operator commissions for first time signup...
      $scope.getOperatorProfileDetail();

      /* START This function used for update and edit package detail */

      $scope.getPackageDetail = function (source) { // get package detail based on package ID
        $scope.showLoader();
        var requestObject = {
          package_id: package_id,
          is_operator_edit: true,
          token: $scope.user.token
        };
        createAdventureService.getPackageDetail(requestObject).then(function (response) { // fetch from the service
          if (response && response.data && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.heliCommission = response.data.heli_commission;
            $scope.newPackage = response.data.package;
            $scope.newPackage.deposit_type = String(response.data.package.deposit_type);
            $scope.newPackage.publish_start_date = response.data.package.publish_start_date;
            $scope.newPackage.publish_end_date = response.data.package.publish_end_date;
            $scope.newPackage.is_collect_percentage_taxes_on_the_deposit = $scope.newPackage.is_collect_percentage_taxes_on_the_deposit ? 1 : 0;
            $scope.bookinLimitPlaceholder = $scope.newPackage.booking_type == 2 &&  $scope.newPackage.daily_rate_activity == 0  ? 'nights' : 'days';
            $scope.marker = {
              'lat': $scope.newPackage.latitude,
              'lng': $scope.newPackage.longitude
            };
            $scope.newPackage.event_eventName = response.data.package.name;
            $scope.primaryActivity = response.data.package.package_sports_id;
            $scope.newPackage.event_description = response.data.package.description;
            $scope.newPackage.event_inclusions = response.data.package.inclusions;
            $scope.newPackage.event_packinglist = response.data.package.packing_list;
            $scope.newPackage.event_lodging_status = JSON.stringify(response.data.package.lodging_status);
            $scope.newPackage.duration_Day = response.data.package.duration;
            $scope.newPackage.currency_id = response.data.package.currency_id;
            $scope.currencyChange($scope.newPackage.currency_id, 'firstLoad');
            $scope.newPackage.booking_type = response.data.package.booking_type ? response.data.package.booking_type
              : $scope.type.package_type = JSON.stringify($scope.newPackage.package_type);
            $scope.package_case = JSON.stringify($scope.newPackage.package_case);
            $scope.type.package_type = JSON.stringify($scope.newPackage.package_type);
            if ($scope.newPackage.booking_type == 1) {
              $scope.newPackage.booking_type_choise = 1;
            } else if ($scope.newPackage.booking_type == 2 && $scope.newPackage.single_day_activity == 0 && $scope.newPackage.daily_rate_activity == 0) {
              $scope.newPackage.booking_type_choise = 2;
            } else if ($scope.newPackage.booking_type == 2 && $scope.newPackage.single_day_activity == 1 && $scope.newPackage.daily_rate_activity == 0) {
              $scope.newPackage.booking_type_choise = 3;
            } else if ($scope.newPackage.booking_type == 2 && $scope.newPackage.single_day_activity == 0 && $scope.newPackage.daily_rate_activity == 1) {
              $scope.newPackage.booking_type_choise = 4;
            }
            $rootScope.packageType = $scope.type.package_type;
            $rootScope.packageCase = $scope.package_case;
            $rootScope.packageBookingType = $scope.newPackage.booking_type_choise;
            $state.params.package_type = $rootScope.packageType;
            $state.params.package_case = $rootScope.packageCase;
            $state.params.booking_type = $rootScope.packageBookingType;
            $location.search($state.params);
            $scope.newPackage.lodgingDetail = response.data.package.lodgings || [];
            $scope.packageForms = response.data.package.guest_forms;
            var addedArr = [];

            if(!!$scope.newPackage.waiver_id){
              $scope.newPackage.waiver = $scope.waiverAdventureDocuments.find(function (waiverFind) {
                return waiverFind.id == $scope.newPackage.waiver_id;
              });
            }

            if ($scope.newPackage.lodging_included != 1 && $scope.newPackage.lodging_included != 0) {
              if ($scope.newPackage.package_case == 3) {
                $scope.newPackage.lodging_included = String($scope.newPackage.event_lodging_status);
              } else if ($scope.newPackage.event_lodging_status == 0 && $scope.newPackage.package_case == 1) {
                $scope.newPackage.lodging_included = '0';
              }else if ($scope.newPackage.event_lodging_status == 1 && $scope.newPackage.package_case == 1) {
                $scope.newPackage.lodging_included = '1';
              }
            } else {
              if ($scope.newPackage.lodging_included == 1) {
                $scope.newPackage.lodging_included = '1';
              } else {
                $scope.newPackage.lodging_included = '0';
              }
            }
            if ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
              $scope.newPackage.event_lodging_status = '1';
              $scope.newPackage.lodging_included = '1';
            }

            if($scope.newPackage.booking_limit == 0) {
              $scope.newPackage.booking_minimum_days = '';
              $scope.newPackage.booking_maximum_days = '';
            }

            if ($scope.newPackage.tax) {
              // push to select already saved taxes
              if ($scope.newPackage.tax.domestic && $scope.newPackage.tax.domestic.length) {
                $scope.operatorTaxFeeDomestic = []
                $scope.newPackage.tax.domestic.forEach(function(taxObj){
                  // add parametr label for displaying name tax on multiselect
                  taxObj.label = taxObj.name;
                  $scope.operatorTaxFeeDomestic.push(taxObj)
                })
              } else {
                $scope.newPackage.tax.domestic = [];
                $scope.operatorTaxFeeDomestic = [];
              }

              if ($scope.newPackage.tax.international && $scope.newPackage.tax.international.length) {
                $scope.operatorTaxFeeInternational = [];
                $scope.newPackage.tax.international.forEach(function(taxObj){
                  // add parametr label for displaying name tax on multiselect
                  taxObj.label = taxObj.name;
                  $scope.operatorTaxFeeInternational.push(taxObj)
                })
              } else {
                $scope.newPackage.tax.international = [];
                $scope.operatorTaxFeeInternational = [];
              }
            } else {
              $scope.newPackage.tax = {};
              $scope.newPackage.tax.international = $scope.operatorTaxFeeInternational;
              $scope.newPackage.tax.domestic = $scope.operatorTaxFeeDomestic;
              $scope.newPackage.tax.country_id = $scope.user.operator_policy.operator_taxes.country_id ? $scope.user.operator_policy.operator_taxes.country_id : '';
            }

            //Nightly booking not available for single day activity adventures
            if ($scope.newPackage.booking_type != CONST_HELPER.ONE) {
              // NIGHTLY BOOKING
              $scope.newPackage.window_id = response.data.package.nightly_slots ? response.data.package.nightly_slots.booking_window : $scope.bookingWindow[CONST_HELPER.ZERO].id;
              $scope.bookingWindowRange = {
                startDate: moment(response.data.package.nightly_slots ? response.data.package.nightly_slots.from_date : new Date()),
                endDate: moment(response.data.package.nightly_slots ? response.data.package.nightly_slots.to_date : new Date())
              };
              $scope.customEventStartDate = moment(response.data.package.nightly_slots ? response.data.package.nightly_slots.from_date : new Date());
              $scope.customEventEndDate = moment(response.data.package.nightly_slots ? response.data.package.nightly_slots.to_date : new Date());
              $scope.newPackage.advance_notice_id = response.data.package.nightly_slots ? response.data.package.nightly_slots.advance_notice_days : $scope.advanceNotice[CONST_HELPER.ZERO].id;
              $scope.newPackage.lodge_id = $scope.newPackage.lodgingDetail && $scope.newPackage.lodgingDetail[CONST_HELPER.ZERO] ? $scope.newPackage.lodgingDetail[CONST_HELPER.ZERO].id : '';
              $scope.newPackage.package_discounts = response.data.package.package_discounts;
              $scope.newPackage.base_price = $state.params.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE ? response.data.package.nightly_slots.base_price : (response.data.package.nightly_slots && response.data.package.nightly_slots.nightly_slot_lodgings && response.data.package.nightly_slots.nightly_slot_lodgings[CONST_HELPER.ZERO] ? response.data.package.nightly_slots.nightly_slot_lodgings[CONST_HELPER.ZERO].price : CONST_HELPER.ZERO);
              if ($state.params.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && !$scope.newPackage.base_price) {
                // CASE C BASE PRICE SETTINGS
                if (response.data.package.nightly_slots.nightly_slot_lodgings && response.data.package.nightly_slots.nightly_slot_lodgings[CONST_HELPER.ZERO]) {
                  $scope.newPackage.base_price = response.data.package.nightly_slots.nightly_slot_lodgings[CONST_HELPER.ZERO].price;
                }
              }
              $scope.newPackage.max_seat_available = response.data.package.nightly_slots.per_day_available_seat;
              $scope.newPackage.nightlySlots = response.data.package.nightly_slots;
              $scope.nightly_slot_lodgings = $scope.newPackage.nightlySlots ? $scope.newPackage.nightlySlots.nightly_slot_lodgings : [];

              $scope.nightly_slot_lodgings ? $scope.nightly_slot_lodgings.map(function (lodge) {
                lodge.calendar = JSON.parse(lodge.calendar);
                lodge.updated_dates = lodge.updated_dates ? JSON.parse(lodge.updated_dates) : [];
                return lodge;
              }) : null;

              if ($scope.newPackage.lodgingDetail && $scope.newPackage.lodgingDetail.length > CONST_HELPER.ZERO) {
                var logding_id = $scope.newPackage.lodgingDetail[CONST_HELPER.ZERO].id;
                if (logding_id && $scope.newPackage.nightlySlots && $scope.newPackage.nightlySlots.nightly_slot_lodgings) {
                  var findNightslotLodging = $scope.newPackage.nightlySlots.nightly_slot_lodgings.find(function (nightlySlots) {
                    return nightlySlots.lodging_id == logding_id;
                  });
                  $scope.rangesData = findNightslotLodging && findNightslotLodging.updated_dates;
                  $scope.newPackage.available_room = findNightslotLodging ? findNightslotLodging.available_room : CONST_HELPER.ZERO;
                }
              }
              $scope.initilizeArray();
            }

            setTimeout(function () {
              $scope.packageForms.forEach(function (form) {
                var form_item = $scope.operatorFormOptions.find(function (opForm) {
                  return (opForm.id == form.parent_id || opForm.id == form.id);
                });
                if (form && form.parent_id) {
                  if (addedArr.indexOf(form.parent_id) == CONST_HELPER.MINUS_ONE) {
                    $scope.operatorFormModal.push({
                      id: form.parent_id
                    });
                    addedArr.push(form.parent_id);
                  }
                }
              });
            }, 500);

            // checkExpiredSlots($scope.newPackage);
            if ($scope.newPackage.is_booking === CONST_HELPER.ONE && source !== 'other') {
              $scope.disableNightlyPriceSeats = true;
              $scope.showPopup('#editDetailModal');
            }
            if ($scope.newPackage.duration_Day === CONST_HELPER.ZERO) {
              $scope.newPackage.duration_Day = '';
            }

            $timeout(function () { //
              $('.selectpicker').selectpicker(); // initialize selectpicker...
            }, CONST_HELPER.TEXTLIMIT);
            setSelectedLodging(); // check if package is associated with location and update data accordingly
            $scope.durationDayTemp = $scope.newPackage.duration_Day;
            $scope.package_sports = response.data.package.package_package_sports;
            $scope.newPackage.itinerary_arrival_instruction = response.data.package.arrival_instruction;
            $scope.newPackage.itinerary_depature_instruction = response.data.package.depature_instruction;

            if (!response.data.package.package_day_details.length) {
              $scope.newPackage.itinerary_day_description = [];
              $scope.newPackage.itinerary_day_description.push(itinerary);
            } else {
              $scope.newPackage.itinerary_day_description = response.data.package.package_day_details;
            }
            $scope.cancelPolicyDetail = response.data.package.package_cancellation_policies;
            $scope.packageLoading = false;
            angular.forEach(response.data.package.package_skiier_abilities, function (ability) { // set package_skiier_abilities in scope
              $scope.package_skiier_abilities.push({
                'id': ability.skiier_ability_id,
                'name': ability.ability_name
              });
            });
            $timeout(function () {
              checkForCkeditorLoading(CKEDITOR.instances); // CHECK IF CKEDITOR IS LOADED AUR NOT...
            }, 500);

            angular.forEach(response.data.package.package_amenities, function (amenity) { // set package_amenities in scope
              $scope.amenities_package_list.push({
                'id': amenity.amenity_id,
                'name': amenity.amenity_name
              });
            });

            $scope.checkBookingStatus($scope.package_skiier_abilities, 'id', 'abilityArr'); // call function for check  checbox is checked for abilities
            $scope.checkBookingStatus($scope.package_sports, 'package_category_id', 'sportsArr'); // call function for check  checbox is checked  for package sports
            $scope.checkBookingStatus($scope.amenities_package_list, 'id', 'amenityArr'); // call function for check  checbox is checked for amenities

            if (response.data.package.package_medias) { // categories media with data
              angular.forEach(response.data.package.package_medias, function (media) {
                if (media.media_type === CONST_HELPER.ZERO) { // set feature image
                  if (media.is_main === CONST_HELPER.ONE) {
                    $scope.showUploadImage = false;
                    $scope.showCrop = true;
                    $scope.featured_image = media;
                  }

                  if (media.is_main === CONST_HELPER.ZERO) { // set gallery images
                    $scope.gallery_media.push(media);
                    $scope.myImage.push(media);
                  }
                } else if (media.media_type === CONST_HELPER.ONE) { // set video url
                  $scope.videoUrl.push({
                    'url': media.url
                  });
                }
              });
              maxImageLength = CONST_HELPER.PACKAGE.MAX_GALLERY_IMAGES - $scope.myImage.length - $scope.uploadImages.length;
            }
            $scope.hideLoader();
            $timeout(function () {
              if ($scope.ifEditSlot) {
                if (!$scope.$$phase) {
                  $('.z-index5 a').trigger('click');
                }
                // TODO SET EVENTS NOT WORKING HERE
                $scope.updateBookingWindow(false, false, false);
                $scope.setEvents();

              }
            }, 100);
            if($scope.editPackage){
              $timeout(function () { // for custom plugin
                checkForGreenTicksEvent();
                checkForGreenTicksLodging();
                checkForGreenTicksDuration();
                checkForGreenTicksItinerary();
                checkForGreenTicksGallery();
                checkForGreenTicksAmenities();
                checkForGreenTicksBookingInformation();
                if (($scope.currentLocation.latitude == '' && $scope.currentLocation.longitude == '') || !$scope.newPackage.location_name || $scope.noLocationSelected) {
                  $scope.showGreenTickLocation = false;
                } else {
                  $scope.showGreenTickLocation = true;
                }
              },0);
            }
          } else {
            $state.go('operator-dashboard');
          }
        }, function () { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });

      };

      function checkForCkeditorLoading (editorInstance) {
        if (Object.keys(editorInstance).length === 0) { // if no ckeditor loaded then reload the page...
          if (package_id) { // if package id then call package detail function
            $scope.getPackageDetail();
            $scope.packageLoading = true;
          } else {
            $scope.packageLoading = true;
            $scope.getCancelPolicyDeatil(); // get cancel policy detail
          }
        }
      }
      // destroy the editor on route change...
      $scope.$on('$destroy', function () {
        for (var editor in window.CKEDITOR.instances) {
          if (window.CKEDITOR.instances.hasOwnProperty(editor)) {
            window.CKEDITOR.instances[editor].destroy();
          }
        }
      });

      function setSelectedLodging () {
        // find selected location if package is associated with location...
        if ($scope.locationDataFetched) {
          if ($scope.newPackage.op_location_id) { // check if package is associated with location data...
            $scope.currentLocation = $scope.allLocations.find(function (location) {
              return location.id === $scope.newPackage.op_location_id;
            });
            if ($scope.currentLocation) {
              $scope.locationType = $scope.currentLocation.id ? CONST_HELPER.LOCATION.PRE_DEFINED : CONST_HELPER.LOCATION.CUSTOM;
              $scope.currentSelectedLocation = $scope.currentLocation;
              setPackageLocationData($scope.currentLocation);
            } else {
              $scope.currentLocation = $scope.allLocations[$scope.allLocations.length - CONST_HELPER.ONE];
              $scope.currentSelectedLocation = $scope.currentLocation;
              setPackageLocationData($scope.currentLocation);
            }
          } else {
            $scope.currentLocation = $scope.allLocations[$scope.allLocations.length - CONST_HELPER.ONE];
            // resetPackageLocationData();
          }
        } else {
          $timeout(function () {
            setSelectedLodging();
          });
        }
      }
      /* END This function used for update and edit package detail */

      $scope.getCancelPolicyDeatil = function () { // get cancel policy detail
        createAdventureService.getCancelPolicyDeatil($scope.user.token).then(function (response) { // fetch from the service
          if (response) {
            if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.cancelPolicyDetail.push(response.data.data); // add to scope
              $scope.heliCommission = response.data.data.heli_commission;
              $scope.packageLoading = false;
              $timeout(function () {
                checkForCkeditorLoading(CKEDITOR.instances); // CHECK IF CKEDITOR IS LOADED AUR NOT...
              }, 500);
              $scope.newPackage.is_partial = response.data.data.is_partial;
              $scope.newPackage.deposit_type = String(response.data.data.deposit_type);
              $scope.newPackage.amount_percentage = response.data.data.amount_percentage;
            }
          }
        }, function () { // check for error
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.currencyChange = function (id, firstLoad) { // to get currency code
        angular.forEach($scope.currencyList, function (currency) {
          if (currency.id === id) {
            $scope.currency_code = currency.code; // set default value in currency code scope
          }
        });
        if (firstLoad) { // when edit a package, prevent ui-calendar events to get updated.
          return;
        }
        if (uiCalendarConfig.calendars && uiCalendarConfig.calendars['operatorCalendar']) {
          var events = uiCalendarConfig.calendars && uiCalendarConfig.calendars['operatorCalendar'] ? uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('clientEvents') : [];
          events.map(function (event) {
            event['title'] = $filter('currency')(event.price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO);
            return event;
          });
          uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('removeEvents');
          uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('renderEvents', convertToMoment(events), true);
          // uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('addEventSource', convertToMoment(events));
          setTimeout(function () {
            uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('render');
          }, 500);
        }
      };

      if (package_id) { // if package id then call package detail function
        $scope.getPackageDetail();
        $scope.packageLoading = true;
      } else {
        $scope.packageLoading = true;
        $scope.getCancelPolicyDeatil(); // get cancel policy detail
        $scope.getCurrency();
        // default lodging status
        $scope.newPackage.event_lodging_status = $rootScope.packageBookingType == 4 ? '0' : '1';
      }

      /* get operator locations */
      function getOperatorLocations () {
        $scope.showLoader();
        operatorProfileService.getOperatorLocations({
          token: $scope.user.token
        }).then(function (response) { // fetch from the service
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK || response.data.code === CONST_HELPER.API_RESPONSE.NO_DATA) {
            $scope.hideLoader();
            $scope.allLocations.push({
              'name': 'Select a Location',
              'latitude': '',
              'longitude': ''
            });
            $scope.allLocations = $scope.allLocations.concat(response.data.data); // add to scope
            $scope.locationDataFetched = true;
            // add custom Location option in dropdown
            $scope.allLocations.push({
              'name': 'Custom Location',
              'latitude': CONST_HELPER.DEFAULT_LATITUDE,
              'longitude': CONST_HELPER.DEFAULT_LONGITUDE
            });
            $scope.currentLocation = $scope.allLocations[CONST_HELPER.ZERO];
            $scope.locationType = CONST_HELPER.LOCATION.CUSTOM; // on package creation inititally set location type to custom...
            // initally reset the package location data

            if (!package_id) {
              resetPackageLocationData();
            };
          }
        }, function () { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }
      getOperatorLocations(); // GET ALL OPERATOR LOACTION

      // function to change the selected location
      $scope.changeLocation = function (selectedLocation, $select) {
        $scope.ui_select = $select;
        $scope.currentLocation = selectedLocation;
        $scope.locationType = $scope.currentLocation.id ? CONST_HELPER.LOCATION.PRE_DEFINED : CONST_HELPER.LOCATION.CUSTOM;
        $scope.noLocationSelected = false;
        // if ($scope.currentSelectedLocation && selectedLocation.id === $scope.currentSelectedLocation.id) {
        //   return;
        // }
        // check location type and set/reset location data
        if (!package_id && $scope.newPackage.booking_type == CONST_HELPER.TWO || ($scope.newPackage.booking_type == CONST_HELPER.ONE && $scope.newPackage.package_slots && !$scope.newPackage.package_slots.length)) {
          $scope.currentSelectedLocation = selectedLocation;
          if ($scope.locationType === CONST_HELPER.LOCATION.CUSTOM) {
            resetPackageLocationData(selectedLocation);
          } else {
            $scope.lodgingChangeConfirmed(selectedLocation);
          }
        } else {
          $scope.showPopup('#locationChangeConfirmation');
        }
      };

      $scope.filterValue = function ($event) {
        var regExpression = new RegExp('^[a-zA-Z0-9$@$!%*?&#^-_.,/ +]+$');
        var lacationValue = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);
        if (regExpression.test(lacationValue)) {
          return true;
        }
        $event.preventDefault();
        return false;
      };

      // function to set the LOCATION object to package data.
      function setPackageLocationData (selectedLocation) {
        if (selectedLocation && !selectedLocation.hasOwnProperty('description')) {
          return; // return if location is associated...
        }

        $scope.newPackage.event_location_description = (package_id && !$scope.newPackage.location_description_synced) ? $scope.newPackage.location_description : selectedLocation.description;
        $scope.newPackage.itinerary_arrival_instruction = (package_id && !$scope.newPackage.arrival_instruction_synced) ? $scope.newPackage.arrival_instruction : selectedLocation.arrival_instructions;
        $scope.newPackage.itinerary_depature_instruction = (package_id && !$scope.newPackage.depature_instruction_synced) ? $scope.newPackage.depature_instruction : selectedLocation.departure_instructions;
        $scope.newPackage.location_name = selectedLocation.address;
        $scope.newPackage.latitude = selectedLocation.latitude;
        $scope.newPackage.longitude = selectedLocation.longitude;
        if (package_id && !$scope.newPackage.publish_start_date && !$scope.newPackage.lodgingDetail.length) {
          $scope.checkAndUpdatePakcageLodgingData(selectedLocation);
        }
        $scope.newPackage.op_location_airports = selectedLocation.op_location_airports;
        $scope.newPackage.op_location_medias = selectedLocation.op_location_medias;
        $scope.newPackage.op_location_videos = selectedLocation.op_location_videos;
        $scope.marker = {
          'lat': selectedLocation.latitude,
          'lng': selectedLocation.longitude
        };
        // synced fields...
        $scope.location_description_synced = package_id ? !!$scope.newPackage.location_description_synced : true;
        $scope.arrival_instruction_synced = package_id ? !!$scope.newPackage.arrival_instruction_synced : true;
        $scope.depature_instruction_synced = package_id ? !!$scope.newPackage.depature_instruction_synced : true;
        $scope.location_description_editable = !$scope.location_description_synced;
        $scope.arrival_instruction_editable = !$scope.arrival_instruction_synced;
        $scope.depature_instruction_editable = !$scope.depature_instruction_synced;
      }

      // function to reset the LOCATION object to package data.
      function resetPackageLocationData (selectedLocation) {
        $scope.newPackage.event_location_description = '';
        $scope.newPackage.itinerary_arrival_instruction = '';
        $scope.newPackage.itinerary_depature_instruction = '';
        $scope.newPackage.location_name = CONST_HELPER.DEFAULT_LOCATION_NAME;
        $scope.newPackage.latitude = CONST_HELPER.DEFAULT_LATITUDE;
        $scope.newPackage.longitude = CONST_HELPER.DEFAULT_LONGITUDE;
        $scope.newPackage.op_location_airports = [];
        $scope.newPackage.op_location_medias = [];
        $scope.newPackage.op_location_videos = [];
        $scope.newPackage.lodgingDetail = [];
        $scope.newPackage.package_slots = [];
        $scope.marker = {
          'lat': CONST_HELPER.DEFAULT_LATITUDE,
          'lng': CONST_HELPER.DEFAULT_LONGITUDE
        };
        $scope.arrival_instruction_editable = true;
        $scope.depature_instruction_editable = true;
        $scope.location_description_editable = true;
        $scope.location_description_synced = false;
        $scope.arrival_instruction_synced = false;
        $scope.depature_instruction_synced = false;
      }

      $scope.lodgingChangeConfirmed = function (selectedLocation) {
        $scope.oldLodgingBeforeChangingLocation = [];
        if (package_id && $scope.newPackage.lodgings && $scope.newPackage.lodgings.length) {
          $scope.newPackage.lodgings.forEach(function (lodge) {
            $scope.oldLodgingBeforeChangingLocation.push(lodge);
          });
        };
        if ($scope.newPackage.booking_type == 2) {
          $scope.newPackage.nightlySlots = [];
          $scope.nightly_slot_lodgings = [];
          $scope.newPackage.lodge_id = '';
        }
        if ($scope.locationType !== CONST_HELPER.LOCATION.CUSTOM) {
          setPackageLocationData(selectedLocation);
          $scope.checkAndUpdatePakcageLodgingData(selectedLocation);
          setLodgingData(); // set the location lodging data to actual lodging data
          if ($scope.newPackage.package_slots && $scope.newPackage.package_slots.length) {
            $scope.newPackage.package_slots = [];
          }
        } else {
          resetPackageLocationData();
        }
        if ($scope.oldLodgingBeforeChangingLocation && $scope.oldLodgingBeforeChangingLocation.length) {
          $scope.showLoader();
          $scope.oldLodgingBeforeChangingLocation.map(function (lodging) {
            var deleteObj = {
              'lodging_id': lodging.id
            };
            operatorCalendarService.deleteLodging(deleteObj, $scope.user.token)
          });
          $scope.hideLoader();
          delete $scope.oldLodgingBeforeChangingLocation;
        };
      };
      $scope.cancelConfirmation = function () {
        var location = $scope.allLocations.find(function (location) {
          return location.id === $scope.currentSelectedLocation.id;
        });
        $timeout(function () {
          $scope.ui_select.selected = location;
        });
      };

      // check if location is changed and update the location lodging and created slots...
      $scope.checkAndUpdatePakcageLodgingData = function (selectedLocation) {
        if (parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
          return;
        }
        if ($scope.newPackage.lodgingDetail && $scope.newPackage.lodgingDetail.length) {
          // selectedLocation.op_lodgings.map(function (lodging) {
          //   if($scope.newPackage.lodgingDetail.indexOf())
          // });
          $scope.newPackage.lodgingDetail = $scope.newPackage.lodgingDetail.concat(selectedLocation.op_lodgings ? selectedLocation.op_lodgings : []);
          $scope.newPackage.lodgingDetail = getUnique($scope.newPackage.lodgingDetail);

          var lodgingCopy = angular.copy($scope.newPackage.lodgingDetail);
          lodgingCopy = $scope.newPackage.lodgingDetail.filter(function (lodging) {
            return lodging.op_location_id && lodging.op_location_id == selectedLocation.id;
          });
          if (selectedLocation.guest_can_select_room) {
            $scope.newPackage.lodging_included = '1';
            $scope.newPackage.event_lodging_status = '1';
            $scope.newPackage.lodgingDetail = lodgingCopy;
          } else {
            $scope.newPackage.lodgingDetail = []
            $scope.newPackage.lodging_included = '1';
            $scope.newPackage.event_lodging_status = '0';
          }

          package_temp_id = ''; // SET package_temp_id TO BLANK TO DEIFFERNCIATE LODGINGS...
        } else {
          if (selectedLocation.guest_can_select_room) {
            $scope.newPackage.lodging_included = '1';
            $scope.newPackage.event_lodging_status = '1';
            $scope.newPackage.lodgingDetail = selectedLocation.op_lodgings;
          } else {
            $scope.newPackage.lodgingDetail = []
            $scope.newPackage.lodging_included = '1';
            $scope.newPackage.event_lodging_status = '0';
          }
        }
        if ($scope.newPackage.booking_type == 2 && $scope.newPackage.single_day_activity == 1) {
          $scope.newPackage.lodgingDetail = []
          $scope.newPackage.lodging_included = '0';
          $scope.newPackage.event_lodging_status = '0';
        }
      };
      // function to get unique json
      function getUnique (data) {
        var uniqueLocationLodging = [];
        for (var i = CONST_HELPER.ZERO; i < data.length; i++) {
          if (uniqueLocationLodging.indexOf(data[i].id) === CONST_HELPER.MINUS_ONE) {
            uniqueLocationLodging.push(data[i]);
          }
        }
        return uniqueLocationLodging;
      }

      // function to merge the location lodging media to actual lodging_media
      function setLodgingData () {
        $scope.newPackage.lodgingDetail.map(function (lodging) {
          if (lodging.op_lodging_medias && lodging.op_lodging_medias.length) {
            lodging.lodging_medias = angular.copy(lodging.op_lodging_medias);
          }
        });
      }

      // unlink location data
      $scope.unsyncLocationData = function (key, editableKey) {
        $scope[key] = false;
        $scope[editableKey] = false;
      };

      $scope.editLocationDescription = function (key, val, editableField) { // edit synced data from location
        $scope[key] = val;
        if (val === false) { // if operator cancel the editing reset to previous value...
          $scope.newPackage[editableField] = $scope[editableField];
        }
        $scope[editableField] = $scope.newPackage[editableField]; // store the previous state...
      };

      $scope.currentEditableField = function (field, editableField) {
        $scope.currentField = field;
        $scope.editableField = editableField;
        $scope.currentFieldDesc = $scope.currentField === 'location_description_synced' ? 'location description' : $scope.currentField === 'arrival_instruction_synced' ? 'arrival instruction' : $scope.currentField === 'depature_instruction_synced' ? 'departure instruction' : '';
      };

      // check for expired slots...
      $scope.hasExpiredSlots = false;
      $scope.hasActiveSlots = false;
      $scope.checkExpiredSlots = function (slot) {
        var slotStartDate = new Date(slot.from_date);
        var currentDate = new Date();
        var diffDays = Math.floor((Date.UTC(slotStartDate.getFullYear(), slotStartDate.getMonth(), slotStartDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (CONST_HELPER.TEXTLIMIT * CONST_HELPER.SIXTY * CONST_HELPER.SIXTY * CONST_HELPER.TWENTYFOUR));
        if (diffDays < CONST_HELPER.ZERO) {
          $scope.hasExpiredSlots = true;
          slot.is_expired = true;
          return false;
        } else {
          $scope.hasActiveSlots = true;
          slot.is_expired = false;
          return true;
        }
      };

      $scope.createPackage = function (packageCreationForm) { // create package send form data
        if ($location.hash() === 'itinerary' && $scope.packageCreation.$error.minlength) { // prevent save from Save and exit.if itinerary input values have invalid data.
          document.getElementById('itinerary_day_description').focus(); // show focus on input field on itinerary page.
          return false;
        }

        if ($location.hash() === 'eventDetails' && $scope.packageCreation.$error.minlength) { // prevent save from Save and exit.if event detail input values have invalid data.
          document.getElementById('event-desc').focus(); // show focus on input field on event deatil page.
          return false;
        }
        if (!$scope.primaryActivity) {
          $scope.showRedTickEvent = true;
          $scope.publishError = true;
          return false;
        }
        if ($scope.packageCreation.$error.pattern) { // prevent save from Save and exit.if duration input values have invalid data.
          // eslint-disable-next-line no-mixed-operators
          if ($scope.newPackage.booking_type == CONST_HELPER.ONE && $scope.newPackage.duration_Day && $scope.newPackage.duration_Day < CONST_HELPER.PACKAGE.MIN_SLOT_DAYS || $scope.newPackage.duration_Day > CONST_HELPER.PACKAGE.MAX_SLOT_DAYS) {
            document.getElementById('dayDuration').focus(); // show focus on input field on duration page.
            return false;
          }

          // eslint-disable-next-line no-use-before-define
          if ($location.hash() === 'duration' && fieldName === 'amount_percentage') { // prevent save from Save and exit.if event detail input values have invalid data.
            document.getElementById('amountPercentage').focus(); // show focus on input field on duration page.
            return false;
          }

          var invalidFields = $scope.packageCreation.$error.pattern;
          for (var i = CONST_HELPER.ZERO; i < invalidFields.length; i++) { // add flag for invalid field's and show error
            var fieldName = invalidFields[i].$name; // set error field name in variable
            // eslint-disable-next-line no-mixed-operators
            if ($scope.newPackage.booking_type == CONST_HELPER.ONE && ($location.hash() === 'duration' && fieldName === 'duration_Day' || $scope.newPackage.duration_Day && $scope.newPackage.duration_Day < CONST_HELPER.PACKAGE.MIN_SLOT_DAYS || $scope.newPackage.duration_Day > CONST_HELPER.PACKAGE.MAX_SLOT_DAYS)) { // prevent save from Save and exit.if event detail input values have invalid data.
              document.getElementById('dayDuration').focus(); // show focus on input field on duration page.
              return false;
            }

            if ($location.hash() === 'duration' && fieldName === 'amount_percentage') { // prevent save from Save and exit.if event detail input values have invalid data.
              document.getElementById('amountPercentage').focus(); // show focus on input field on duration page.
              return false;
            }
          }
        }
        if ($location.hash() === 'gallery' && $scope.packageCreation.$error.pattern) { // prevent save from Save and exit.if gallery input values have invalid data.
          document.getElementById('galleryVideoUrl').focus(); // show focus on input field on duration page.
          return false;
        }

        if ($location.hash() === 'duration') {
          var day = parseInt($scope.newPackage.duration_Day);
          if ($scope.newPackage.booking_type == CONST_HELPER.ONE && (day < CONST_HELPER.PACKAGE.MIN_SLOT_DAYS || day > CONST_HELPER.PACKAGE.MAX_SLOT_DAYS)) { // prevent save from Save and exit.if event detail input values have invalid data.
            document.getElementById('dayDuration').focus(); // show focus on input field on duration page.
            return false;
          }
        }

        if ($scope.cancelPolicyDetail && !$scope.cancelPolicyDetail[CONST_HELPER.ZERO].min_num_cancellation_days || $scope.cancelPolicyDetail[CONST_HELPER.ZERO].min_num_cancellation_days < CONST_HELPER.PACKAGE.MIN_CANCELLATION_DAYS) {
          return false;
        }

        if (!$scope.cancelPolicyDetail[CONST_HELPER.ZERO].cancellation_description || $scope.depositErr || $scope.showAmountPercErr || ($scope.newPackage.is_partial && $scope.showAmountPercErrZero)) {
          return false;
        }



        setRequestObj();
        $scope.showLoader();
        $scope.booking_type == CONST_HELPER.ONE ? delete $scope.requestObject.nightly_slots : null;
        delete $scope.requestObject['gallery_images[]'];
        if (package_id) { // update package deatil if package id exist
          $scope.publishPackageFormValidate(packageCreationForm, 'edit');
          if ($scope.newPackage.publish_start_date || $scope.newPackage.publish_end_date) {
            var publishStartYear = new Date($scope.newPackage.publish_start_date).getFullYear();
            var publisheEndYear = new Date($scope.newPackage.publish_end_date).getFullYear();
          }
          if (!$scope.saveAndExitError && $scope.newPackage.publish_start_date !== '' && $scope.newPackage.publish_end_date !== '' && publishStartYear > CONST_HELPER.TWENTYSIXTEEN && publisheEndYear > CONST_HELPER.TWENTYSIXTEEN) {
            $scope.hidePopup('#myPublishModal');
            $scope.publishPackage('edit');
          } else {
            $scope.requestObject.package_id = package_id;
            $scope.requestObject.old_gallery_images = $scope.gallery_media.length ? $scope.gallery_media : [{id: 0}];
            createAdventureService.createPackage($scope.requestObject, $scope.uploadImages).then(function (response) { // post to the server the form data
              if (response) {
                if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                  $scope.hidePopup('#myPublishModal');
                  resetCookies();
                  $state.go('operator-dashboard');
                  $scope.saveErr = false;
                  $scope.hideLoader();
                } else {
                  $scope.saveErr = true;
                  $scope.saveErrMessage = 'Some params are missing.';
                  $scope.hideLoader();
                }
              }
            }, function () { // check for error
              $scope.hideLoader();
              $scope.hidePopup('#myPublishModal'); // close publish modal
              $scope.showPopup('#serverErrModal');
            });
          }
        } else { // create and save package
          createAdventureService.createPackage($scope.requestObject, $scope.uploadImages).then(function (response) { // post to the server the form data
            if (response) {
              if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                $scope.hidePopup('#myPublishModal');
                $rootScope.user.package_count = parseInt($rootScope.user.package_count) + CONST_HELPER.ONE;
                // $rootScope.user.is_bank_details_completed = true;
                $cookies.put('user', JSON.stringify($rootScope.user)); // set local storage again
                resetCookies();
                $state.go('operator-dashboard');
                $scope.saveErr = false;
                $timeout(angular.noop);
                $scope.hideLoader();
              } else {
                $scope.saveErr = true;
                $scope.saveErrMessage = 'Some params are missing.';
                $scope.hideLoader();
              }
            }
          }, function () { // check for error
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }
      };

      $scope.showErrorEventDescription = function () {
        if (!$scope.newPackage.event_description || !$scope.packageCreation.event_eventName.$error.minlength && $scope.showEventName) {
          $scope.descriptionEmptyError = true;
        } else {
          $scope.descriptionEmptyError = false;
        }
      };

      $scope.nextTab = function () {
        $timeout(function () {
          $window.scrollTo(CONST_HELPER.ZERO, CONST_HELPER.ZERO);
        }, 150);
        checkForGreenTicks();

        if (($scope.currentLocation.latitude == '' && $scope.currentLocation.longitude == '') || !$scope.newPackage.location_name || $scope.noLocationSelected) {
          $scope.showGreenTickLocation = false;
        } else {
          $scope.showGreenTickLocation = true;
        }
      };

      $scope.getSkiAbilities = function () {
        /* get all the skiAbilities list */
        $scope.homeRedirection(); // check for authorized user
        createAdventureService.getSkiierAbilities().then(function (response) { // fetch from the service
          if (response) {
            $scope.skiAbilities = response.data.skiier_abilities; // add to scope
          }
        }, function () { // check for error
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.getAmenities = function () {
        /* get all the amentities list */
        $scope.homeRedirection(); // check for authorized user
        createAdventureService.getPackageAmenities().then(function (response) { // fetch from the service
          if (response) {
            $scope.packageAmenities = response.data.amenities; // add to scope
          }
        }, function () { // check for error
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.getSportsList = function () {
        /* get all the sports list along with categories */
        createAdventureService.getSportsList().then(function (response) { // fetch from the service
          if (response) {
            $scope.sportsName = response.data.sports; // add to scope
            $scope.activityCategories = response.data.activity_categories; // add to scope
          }
        }, function () { // check for error
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.getOperatorForms = function () {
        /* get all the sports list along with categories */
        $scope.showLoader();
        console.debug('operatorCreateAdventure.js: operatorProfileService.getOperatorForms: $scope.user.token: %O', $scope.user.token);
        operatorProfileService.getOperatorForms($scope.user.token).then(function (response) { // fetch from the service
          console.debug('operatorCreateAdventure.js: operatorProfileService.getOperatorForms.then: response: %O', response);
          $scope.hideLoader();
          if (response) {
            $scope.operatorFormOptions = [];
            if (response.data && response.data.data) {
              $scope.operatorFormOptions = response.data.data.filter(function (form) {
                return form.deleted == null;
              });
            }
            // add to scope
            $scope.operatorFormOptions ? $scope.operatorFormOptions.map(function (form) {
              form.label = form.title;
            }) : null;
          }
        }, function () { // check for error
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.addSports = function () {
        var selectedProducts = [];
        angular.forEach($scope.sportsName, function (sports) {
          if (sports.selected) {
            selectedProducts.push(sports);
          }
        });
      };

      $scope.toggleCategorySelection = function (sportId, sportName, categoryId, categoryName) { // toggle selection of sport categories
        var index = $scope.package_sports.length; // length of total sports array
        var categoryPresent = false; // to check if category already present or not
        while (index--) { // for each package sport present
          if ($scope.package_sports[index].package_category_id === categoryId) { // if category is present
            categoryPresent = true;
            $scope.package_sports.splice(index, CONST_HELPER.ONE); // remove the category
            return false;
          }
        }
        if (!categoryPresent) { // if the category was not present
          pushObj(sportId, sportName, categoryId, categoryName); // add it to the package sport
        } else { // if category was present and after removing the sport completely got removed then add sport value with category null
          //   var sportPresent = false// assuming sport got completely removed
          var index = $scope.package_sports.length; // length of total sports array
          while (index--) { // for each package sport present
            if ($scope.package_sports[index].package_sport_id === sportId) { // if sport is present
              sportPresent = true; // sport is present
              break;
            }
          }
        }
      };

      function pushObj (sportId, sportName, categoriesId, categoryName) { // add sport to package_sport
        var obj = {
          'package_sport_id': sportId,
          'package_sport_name': sportName,
          'package_category_id': categoriesId,
          'package_category_name': categoryName,
          'package_subcategory_id': null,
          'package_subcategory_name': null
        };
        $scope.package_sports.push(obj); // add it to the package sport
      }

      $scope.toggleSkiAbilitySelection = function (abilityId, abilityName) {
        var index = $scope.package_skiier_abilities.length; // length of total ability array
        var abilityPresent = false; // to check if ability already present or not
        while (index--) { // for each package ability present
          if ($scope.package_skiier_abilities[index].id === abilityId) { // if ability is present
            abilityPresent = true;
            $scope.package_skiier_abilities.splice(index, CONST_HELPER.ONE);
            return false;
          }
        }
        if (!abilityPresent) { // if the ability was not already present
          var obj = {
            'id': abilityId,
            'name': abilityName
          };
          $scope.package_skiier_abilities.push(obj); // add it to the package ability
        }
      };

      $scope.toggleAmenitiesSelection = function (amenityId, amenityName) {
        var index = $scope.amenities_package_list.length; // length of total ability array
        var amenityPresent = false; // to check if ability already present or not
        while (index--) { // for each package ability present
          if ($scope.amenities_package_list[index].id === amenityId) { // if ability is present
            amenityPresent = true;
            $scope.amenities_package_list.splice(index, CONST_HELPER.ONE);
            return false;
          }
        }
        if (!amenityPresent) { // if the ability was not already present
          var obj = {
            'id': amenityId,
            'name': amenityName
          };
          $scope.amenities_package_list.push(obj); // add it to the package ability
        }
      };

      // lodging section code here
      var package_temp_id;
      $scope.addLodgingOption = function (lodging, requestType) {
        if (requestType === 'cancel') {
          var canceledLodging = $scope.lodgingObjCopy.filter(function (l) {
            return l.id === lodging.id;
          });
          canceledLodging = canceledLodging[CONST_HELPER.ZERO];
          // lodging = canceledLodging;
          resetLodgingKeys(lodging, canceledLodging);
          lodging.editing = false;
          $scope.lodgingObjCopy.splice($scope.lodgingObjCopy.indexOf(canceledLodging), CONST_HELPER.ONE);
          // $scope.lodgingObjCopy={};
          lodging.lodgingUploadImage = [];
          return;
        }

        if (!lodging.name || !lodging.description || !lodging.max_guests || !lodging.price || !(lodging.lodging_medias && lodging.lodging_medias.length || lodging.lodgingUploadImage.length) || (lodging.is_private && !lodging.private_price) || (lodging.is_private && !lodging.min_private_guests)) {
          lodging.lodgingFormInvalid = true;
          return;
        }
        var lodgingObj = {
          'package_id': package_id,
          'package_temp_id': getPackageTempId(),
          'name': lodging.name,
          'description': lodging.description,
          'max_guests': lodging.max_guests,
          'price': lodging.price,
          'private_price': lodging.is_private ? lodging.private_price : '',
          // 'id': lodging.id ? lodging.id : '',
          'old_lodging_medias': getOldlodgingMedias(lodging.lodging_medias),
          'min_private_guests': lodging.min_private_guests,
          'op_location_id': lodging.op_location_id ? lodging.op_location_id : ''
        };
        // if location lodging update...
        if (lodging.op_location_id && lodging.op_lodging_id) {
          lodgingObj.id = lodging.id ? lodging.id : '';
          lodgingObj.op_lodging_id = lodging.op_lodging_id;
        } else if (!lodging.op_location_id) { // if custom location
          lodgingObj.id = lodging.id ? lodging.id : '';
        } else { // if location lodging add
          lodgingObj.op_lodging_id = lodging.id;
          lodgingObj.id = '';
        }
        $scope.showLoader();
        // de8b212f-abce-4548-be0b-b8279dfb25f2 local token...
        operatorCalendarService.saveLodging(lodgingObj, lodging.lodgingUploadImage, $scope.user.token).then(function (response) {
          $scope.hideLoader();
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            if (lodging.id) {
              var index = $scope.newPackage.lodgingDetail.indexOf(lodging);
              $scope.newPackage.lodgingDetail[index] = response.data.data;
              lodging.op_lodging_medias ? $scope.newPackage.lodgingDetail[index].op_lodging_medias = lodging.op_lodging_medias : '';
              lodging.op_location_id ? $scope.newPackage.lodgingDetail[index].op_location_id = lodging.op_location_id : '';
              lodging.editing = false;
              if ($scope.newPackage.package_slots && $scope.newPackage.package_slots.length) {
                $scope.newPackage.package_slots.map(function (slot) {
                  var slot_lodging = slot.slot_lodgings.filter(function (sl) {
                    return sl.lodging_id === response.data.data.id;
                  });
                  if (slot_lodging.length) {
                    slot_lodging[CONST_HELPER.ZERO].max_guests = response.data.data.max_guests;
                    slot_lodging[CONST_HELPER.ZERO].name = response.data.data.name;
                    slot_lodging[CONST_HELPER.ZERO].price = response.data.data.price;
                    slot_lodging[CONST_HELPER.ZERO].private_price = response.data.data.private_price;
                  }
                });
              }
              if ($scope.newPackage.booking_type == CONST_HELPER.TWO) {
                // set max seats and available seats
                if ($scope.nightly_slot_lodgings && $scope.nightly_slot_lodgings[CONST_HELPER.ZERO] && $scope.nightly_slot_lodgings[CONST_HELPER.ZERO].calendar && $scope.nightly_slot_lodgings[CONST_HELPER.ZERO].calendar.length > CONST_HELPER.ZERO) {
                  var nightlySlots = $scope.nightly_slot_lodgings && $scope.nightly_slot_lodgings.length > 0 ? $scope.nightly_slot_lodgings.find(function (lodge) {
                    return (lodge.lodging_id == response.data.data.op_lodging_id || lodge.lodging_id == response.data.data.id);
                  }) : null;
                  if (nightlySlots) {
                    nightlySlots.base_price = response.data.data.price;
                    nightlySlots.price = response.data.data.price;
                    nightlySlots.private_price = response.data.data.private_price;
                    nightlySlots.max_capacity = response.data.data.max_guests;
                    nightlySlots.lodging_id = response.data.data.id;
                    nightlySlots.calendar.map(function (CalEvet) {
                      CalEvet.base_price = response.data.data.price;
                      CalEvet.price = response.data.data.price;
                      CalEvet.private_price = response.data.data.private_price;
                      CalEvet.max_capacity = response.data.data.max_guests;
                      CalEvet.title = $filter('currency')(response.data.data.price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO);
                      return CalEvet;
                    });
                    createLodgingObject(nightlySlots.calendar, response.data.data);
                  }
                } else {
                  $scope.renderCalender();
                }
              }
            } else {
              $scope.newPackage.lodgingDetail.push(response.data.data);
              $window.scrollTo(CONST_HELPER.ZERO, CONST_HELPER.ZERO);
              if (package_id && $scope.newPackage.booking_type == CONST_HELPER.TWO) {
                // set max seats and available seats
                if ($scope.nightly_slot_lodgings && $scope.nightly_slot_lodgings[CONST_HELPER.ZERO] && $scope.nightly_slot_lodgings[CONST_HELPER.ZERO].calendar && $scope.nightly_slot_lodgings[CONST_HELPER.ZERO].calendar.length > CONST_HELPER.ZERO) {
                  $scope.setEvents(true);
                  // $scope.updateBookingWindow(true, true, false);
                } else {
                  $scope.renderCalender();
                }
              } else if (!package_id && $scope.newPackage.booking_type == CONST_HELPER.TWO) {
                findEventForAllLodging([], $scope.nightly_slot_lodgings, response.data.data);
                $scope.updateBookingWindow(null, true, false, true);
              }
            }
            $scope.noLodgingError = false;
            $scope.lodging = {};
            lodging.lodgingFormInvalid = false;
            lodging.lodgingUploadImage = [];
            $scope.setAmountPerc($scope.newPackage.amount_percentage, $scope.newPackage.deposit_type);
          }
        }, function () {

        });
      };

      // reset lodging to default values in case of lodging edit cancel...
      function resetLodgingKeys (lodging, preLodging) {
        for (var key in lodging) {
          lodging[key] = preLodging[key];
        }
      }

      function createLodgingObject (events, lodgeObject, update, val) {
        // Delete source to prevent circular dependency,
        events.map(function (event) {
          delete event.source;
          return event;
        });
        if (lodgeObject == null && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO) {
          lodgeObject = {
            id: CONST_HELPER.ZERO,
            price: $scope.newPackage.base_price,
            max_seat_available: $scope.newPackage.max_seat_available,
            max_capacity: $scope.newPackage.max_seat_available
          };
        }
        if (($scope.currentHash === 'lodging' || $scope.currentHash === 'duration') && $scope.newPackage.lodgingDetail && $scope.newPackage.lodgingDetail.length > 1) {
          $scope.newPackage.lodgingDetail.forEach(function (lodge) {
            var findSlotDetails = $scope.nightly_slot_lodgings.find(function (nlodging) {
              return nlodging.lodging_id == lodge.id;
            });
            if (findSlotDetails && findSlotDetails.calendar.length == 0) {
              var requiredEvents = events.filter(function (ent) {
                return ent.lodge_id ? ent.lodge_id == findSlotDetails.lodging_id : true;
              });
              if (lodge.id != $scope.newPackage.lodge_id && $scope.newPackage.window_id == CONST_HELPER.ZERO) {
                for (var m = moment($scope.bookingWindowRange.startDate); m.isSameOrBefore(moment($scope.bookingWindowRange.endDate)); m.add(CONST_HELPER.ONE, 'days')) {
                  var foundEvent = requiredEvents.filter(function (evnt) { return moment(moment(evnt.date).format('YYYY-MM-DD')).isSame(m.format('YYYY-MM-DD')); });
                  if (foundEvent && foundEvent.length == CONST_HELPER.ZERO) {
                    requiredEvents.push({
                      date: m.format('YYYY-MM-DD'),
                      price: lodge.price,
                      available_seat: $scope.newPackage.max_seat_available,
                      available_room: $scope.newPackage.available_room,
                      max_capacity: $scope.newPackage.max_seat_available,
                      // Rolling: Operator setting is_block to Zero Rolling for all events
                      // 3 oct is_block: isDateAfterEndDate ? CONST_HELPER.ONE : CONST_HELPER.ZERO,
                      is_block: CONST_HELPER.ZERO,
                      // forceActive: isDateAfterEndDate ? CONST_HELPER.ZERO : CONST_HELPER.ONE,
                      forceActive: CONST_HELPER.ONE,
                      title: $filter('currency')(lodge.price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO),
                      start: m.format('YYYY-MM-DD'),
                      end: m.format('YYYY-MM-DD'),
                      stick: true,
                      textColor: '#787878',
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                      className: ['myTitle']
                    });
                  } else if (foundEvent && foundEvent.length > CONST_HELPER.ONE) {
                    var newEvent = foundEvent.find(function (fe) { return !fe.lodge_id; });
                    requiredEvents.splice(requiredEvents.indexOf(newEvent), 1);
                    // requiredEvents
                  }
                }
              }

              findSlotDetails.calendar = JSON.parse(JSON.stringify(requiredEvents));
              findSlotDetails.base_price = lodge.price;
              findSlotDetails.price = lodge.price;
              findSlotDetails.private_price = lodge.private_price;
              findSlotDetails.available_room = findSlotDetails.available_room || $scope.newPackage.available_room;
              findSlotDetails.available_seat = $scope.newPackage.max_seat_available;
              findSlotDetails.max_capacity = $scope.newPackage.max_seat_available;
              // CREATING NEW LODGING OBJECT HERE NEED TO SET PRICE AND PRIVATE PRICE SAME AS
              findSlotDetails.calendar.map(function (calEvent) {
                var findEventInUpdated = null;
                if (findSlotDetails.updated_dates) {
                  findEventInUpdated = findSlotDetails.updated_dates.find(function (updatedEvent) {
                    return (moment(moment(calEvent.date).format('YYYY-MM-DD')).isSame(moment(updatedEvent.start).format('YYYY-MM-DD')) || moment(moment(calEvent.date).format('YYYY-MM-DD')).isBetween(moment(updatedEvent.start).format('YYYY-MM-DD'), moment(updatedEvent.end).format('YYYY-MM-DD')) || moment(moment(calEvent.date).format('YYYY-MM-DD')).isSame(moment(updatedEvent.end).format('YYYY-MM-DD'))) && updatedEvent.lodge_id == calEvent.lodge_id;
                  });
                } else {
                  findSlotDetails = lodge;
                }

                calEvent.base_price = findEventInUpdated ? findEventInUpdated.price : lodge.price;
                calEvent.price = findEventInUpdated ? findEventInUpdated.price : lodge.price;
                calEvent.private_price = lodge.private_price;
                calEvent.available_seat = findEventInUpdated ? findEventInUpdated.available_seat : calEvent.available_seat;
                calEvent.max_capacity = findEventInUpdated ? findEventInUpdated.max_capacity : lodge.max_guests;
                calEvent.title = $filter('currency')((findEventInUpdated ? findEventInUpdated.price : lodge.price), $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO);
                return calEvent;
              });
            } else if (findSlotDetails && findSlotDetails.calendar.length != 0 && $scope.newPackage.lodge_id == findSlotDetails.lodging_id) {
              // In case Booking window is updated we need to change events also
              if (findSlotDetails.calendar.length != events.length) {
                findSlotDetails.calendar = JSON.parse(JSON.stringify(events));
              }
              findSlotDetails.available_room = $scope.newPackage.available_room;
            } else if (findSlotDetails && findSlotDetails.calendar.length != 0 && $scope.newPackage.lodge_id != findSlotDetails.lodging_id) {
              // In case Booking window is updated we need to change events also
              if (findSlotDetails.calendar.length != events.length) {
                events.length > CONST_HELPER.ZERO ? events.forEach(function (evnt) {
                  var findEvnt = findSlotDetails.calendar.find(function (fent) {
                    return moment(fent.date).format('YYYY-MM-DD') == moment(evnt.date).format('YYYY-MM-DD');
                  });
                  var isDateAfterEndDate = moment(evnt.date).isAfter($scope.bookingWindowRange.endDate);
                  if (!findEvnt) {
                    var obj = {
                      date: moment(evnt.date).format('YYYY-MM-DD'),
                      available_seat: $scope.newPackage.max_seat_available,
                      available_room: lodge.available_room,
                      is_block: $scope.newPackage.window_id == CONST_HELPER.ZERO ? CONST_HELPER.ZERO : (isDateAfterEndDate ? CONST_HELPER.ONE : CONST_HELPER.ZERO),
                      start: moment(evnt.date).format('YYYY-MM-DD'),
                      end: moment(evnt.date).format('YYYY-MM-DD'),
                      stick: true,
                      textColor: '#787878',
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                      className: ['myTitle'],
                      base_price: lodge.price,
                      lodge_id: lodge.id,
                      price: lodge.price,
                      private_price: lodge.private_price,
                      max_capacity: lodge.max_guests,
                      title: $filter('currency')(lodge.price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO)
                    };

                    // After 2 years
                    if (isDateAfterEndDate) {
                      obj.isAfterBookingRange = isDateAfterEndDate;
                    } else {
                      delete obj.isAfterBookingRange;
                    }

                    findSlotDetails.calendar.push(obj);
                  }
                }) : findSlotDetails.calendar = JSON.parse(JSON.stringify(events));
              }
            } else if (findSlotDetails && findSlotDetails.calendar.length != 0 && events.length == CONST_HELPER.ZERO) {
              findSlotDetails.calendar = JSON.parse(JSON.stringify(events));
            } else if (!findSlotDetails) {
              var newEvents = [];
              events.forEach(function (evet) {
                var _obj = evet;
                _obj.base_price = lodge.price;
                _obj.price = lodge.price;
                _obj.private_price = lodge.private_price;
                _obj.max_capacity = lodge.max_guests;
                _obj.title = $filter('currency')(lodge.price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO);
                newEvents.push(_obj);
              });
              $scope.nightly_slot_lodgings.push({
                lodging_id: lodge.id,
                base_price: lodge.price,
                price: lodge.price,
                private_price: lodge.private_price,
                available_room: $scope.newPackage.available_room,
                available_seat: $scope.newPackage.max_seat_available,
                max_capacity: $scope.newPackage.max_seat_available,
                calendar: JSON.parse(JSON.stringify(newEvents))
              });
            }
          });
        } else {
          if ($scope.nightly_slot_lodgings && lodgeObject) {
            //  else {
            var find = $scope.nightly_slot_lodgings.find(function (nlodging) {
              return lodgeObject.id === undefined ? nlodging.lodging_id == lodgeObject.lodging_id : (nlodging.lodging_id == lodgeObject.id || nlodging.lodging_id == lodgeObject.lodging_id);
            });

            if (find) {
              find.calendar = JSON.parse(JSON.stringify(events));
              find.base_price = lodgeObject.price;
              find.price = lodgeObject.price;
              find.private_price = lodgeObject.private_price;
              find.available_room = $scope.newPackage.available_room;
              find.available_seat = $scope.newPackage.max_seat_available;
              find.max_capacity = $scope.newPackage.max_seat_available;
            } else {
              $scope.nightly_slot_lodgings.push({
                lodging_id: lodgeObject.id,
                base_price: lodgeObject.price,
                price: lodgeObject.price,
                private_price: lodgeObject.private_price,
                available_room: $scope.newPackage.available_room,
                available_seat: $scope.newPackage.max_seat_available,
                max_capacity: $scope.newPackage.max_seat_available,
                calendar: JSON.parse(JSON.stringify(events))
              });
            }
            if (update) {
              $scope.nightly_slot_lodgings.forEach(function (lodging) {
                lodging['available_seat'] = val;
                lodging.calendar.forEach(function (event) {
                  event['available_seat'] = val;
                });
              });
            }
            // }
          } else if (!$scope.nightly_slot_lodgings && lodgeObject) {
            $scope.nightly_slot_lodgings = [];
            $scope.nightly_slot_lodgings.push({
              lodging_id: lodgeObject.id,
              base_price: lodgeObject.price,
              price: lodgeObject.price,
              private_price: lodgeObject.private_price,
              available_room: '',
              available_seat: $scope.newPackage.max_seat_available,
              max_capacity: $scope.newPackage.max_seat_available,
              calendar: JSON.parse(JSON.stringify(events))
            });
          }
        }

        var currentSelectedLodging = $scope.nightly_slot_lodgings.find(function (nlodging) {
          return nlodging.lodging_id == $scope.newPackage.lodge_id;
        });
        if (currentSelectedLodging) {
          if ($scope.newPackage.window_id === CONST_HELPER.ZERO) {
            currentSelectedLodging.calendar = currentSelectedLodging.calendar.filter(function (c) {
              return c.lodge_id ? c.lodge_id === $scope.newPackage.lodge_id : true;
            });
          }
          uiCalendarConfig.calendars['operatorCalendar'] ? uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('removeEvents') : null;
          uiCalendarConfig.calendars['operatorCalendar'] ? uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('renderEvents', convertToMoment(currentSelectedLodging.calendar), true) : null; // renderEvents(event);
          // uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('setOption', 'visibleRange');
          setTimeout(function () {
            uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('render');
          }, 500);
        }
      };

      $scope.canDeleteLodging = function (lodging) {
        if (lodging.is_lodging_booked) {
          return;
        }
        $scope.currentLodging = lodging;
        // deleteLodgingFromSlots($scope.currentLodging);
        $scope.showPopup('#confirmDeleteLodging');
      };

      $scope.deleteLodging = function (lodging) {
        var deleteObj = {
          'lodging_id': $scope.currentLodging.id
        };
        // if it is location lodging then delete it from coppied array only.
        if ($scope.currentLodging.op_location_id && !$scope.currentLodging.op_lodging_id) {
          var index = $scope.newPackage.lodgingDetail.indexOf($scope.currentLodging);
          $scope.newPackage.lodgingDetail.splice(index, CONST_HELPER.ONE);
          $scope.nightly_slot_lodgings = $scope.nightly_slot_lodgings.filter(function (lodging, index) {
            return lodging.lodging_id !== $scope.currentLodging.id;
          });
          return;
        }
        $scope.showLoader();
        operatorCalendarService.deleteLodging(deleteObj, $scope.user.token).then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            if (package_id && $scope.newPackage.booking_type == CONST_HELPER.ONE && $scope.newPackage.package_slots && $scope.newPackage.package_slots.length) { // while editing package
              $scope.getPackageDetail('other');
              $scope.currentLodging = {};
            } else {
              $scope.hideLoader();
              $scope.newPackage.lodgingDetail.splice($scope.newPackage.lodgingDetail.indexOf($scope.currentLodging), CONST_HELPER.ONE);
              if ($scope.newPackage.booking_type == CONST_HELPER.ONE) {
                deleteLodgingFromSlots($scope.currentLodging); // while creating package
              } else {
                var indx = -1;
                $scope.nightly_slot_lodgings.forEach(function (lodging, index) {
                  lodging.lodging_id == $scope.currentLodging.id ? indx = index : null;
                });
                indx != -1 ? $scope.nightly_slot_lodgings.splice(indx, CONST_HELPER.ONE) : null;
                $scope.newPackage.lodge_id = $scope.newPackage.lodgingDetail && $scope.newPackage.lodgingDetail[CONST_HELPER.ZERO] ? $scope.newPackage.lodgingDetail[CONST_HELPER.ZERO].id : '';
                $scope.updateBookingWindow(null, false, false); // while creating package
              }
            }
          }
        });
      };

      function deleteLodgingFromSlots (lodging) { // if lodging get deleted then delete it from already added slots
        if ($scope.newPackage.package_slots && $scope.newPackage.package_slots.length) {
          $scope.newPackage.package_slots.map(function (slot) {
            slot.slot_lodgings = slot.slot_lodgings.filter(function (slotLodging) {
              return slotLodging.lodging_id !== lodging.id;
            });
            if (!slot.slot_lodgings.length) {
              $scope.newPackage.package_slots.splice($scope.newPackage.package_slots.indexOf(slot), CONST_HELPER.ONE);
            }
          });
        }
      }

      $scope.editLodging = function (lodgingObj) {
        $scope.lodgingObjCopy.push(angular.copy(lodgingObj));
        lodgingObj.editing = true;
        $scope.lodgingCompleted = true;
      };

      $scope.deleteLodgingAfterChangeBookinType = function (lodging) {
       var deleteObj = {
         'lodging_id': lodging.id
       };
       // if it is location lodging then delete it from coppied array only.
       if (lodging.op_location_id && !lodging.op_lodging_id) {
         var index = $scope.newPackage.lodgingDetail.indexOf(lodging);
         $scope.newPackage.lodgingDetail.splice(index, CONST_HELPER.ONE);
         $scope.nightly_slot_lodgings = $scope.nightly_slot_lodgings.filter(function (lodging, index) {
           return lodging.lodging_id !== lodging.id;
         });
         return;
       }
       $scope.showLoader();
       operatorCalendarService.deleteLodging(deleteObj, $scope.user.token).then(function (response) {
         if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
           $scope.hideLoader();
           $scope.newPackage.lodgingDetail.splice($scope.newPackage.lodgingDetail.indexOf(lodging), CONST_HELPER.ONE);
         }
       });
     };

      $scope.lodgingChange = function (lodging_status, checkNightlyBooking) {
        switch ($scope.package_case) {
          case '1': // if package case is A(seat pricing)
            if (lodging_status === '0' && $scope.newPackage.lodgingDetail.length > CONST_HELPER.ZERO) { // if lodigng yes -> no
              $scope.lodgingDeleteError = true;
              $timeout(function () {
                $scope.newPackage.lodging_included = '1';
                $scope.newPackage.event_lodging_status = String(CONST_HELPER.EVENT_LODGING.YES);
              });
            } else if (lodging_status === '1' && $scope.newPackage.package_slots && $scope.newPackage.package_slots.length) { // if lodigng no -> yes
              $scope.showPopup('#confirmLodgingChange');
            } else if (lodging_status === '0' && !$scope.newPackage.lodgingDetail.length && $scope.newPackage.lodging_included == 1) {
              $scope.newPackage.event_lodging_status = String(CONST_HELPER.EVENT_LODGING.NO);
              $scope.newPackage.lodging_status  = String(CONST_HELPER.EVENT_LODGING.NO);
            } else {
              $scope.lodgingDeleteError = false;
            }
            break;
        }
      };
      $scope.lodgingChangeType = function (status) {
        if ( status == 1 ) {
          $scope.newPackage.event_lodging_status = '1';
          $scope.lodgingChange('1', false)
        } else {
          $scope.newPackage.event_lodging_status = '0';
          $scope.lodgingChange('0', false);
        }
      }
      $scope.setSingleDayBooking = function (val) {
        if (val == CONST_HELPER.ZERO) {
          $scope.newPackage.single_day_activity = CONST_HELPER.ZERO;
          $scope.newPackage.event_lodging_status = '' + CONST_HELPER.ONE;
        } else {
          $scope.newPackage.single_day_activity = CONST_HELPER.ONE;
          $scope.newPackage.daily_rate_activity = CONST_HELPER.ZERO;
          $scope.newPackage.booking_type_choise = CONST_HELPER.THREE;
        }
      };

      $scope.deleteLodgingSlots = function () {
        $scope.newPackage.package_slots = [];
      };
      $scope.cancelLodgingSlots = function () {
        $scope.newPackage.event_lodging_status = String(CONST_HELPER.EVENT_LODGING.NO);
      };

      function getOldlodgingMedias (media) {
        if (!media) {
          return [];
        }

        var old_images = media.filter(function (val) {
          return val.id;
        });
        if (old_images.length) {
          return old_images;
        } else {
          return [];
        }
      }
      // expandCollaps
      $scope.expandCollaps = function (slot) {
        slot.isCollaps = !slot.isCollaps;
      };

      // update lodging available seats...
      $scope.updateTotalBedsSeats = function (lodge, type) {
        var availableUnits = lodge.available_units || CONST_HELPER.ZERO;
        if (type === 'manual') {
          // eslint-disable-next-line no-self-assign
          lodge.total_beds = lodge.total_beds;
          if (lodge.total_beds > availableUnits * lodge.max_guests) {
            lodge.total_beds = availableUnits * lodge.max_guests;
          }
        } else {
          lodge.total_beds = availableUnits * lodge.max_guests;
        }

        getTotalBedsAndRoom();
        $scope.checkAvailableSeats();
        // $scope.addSlot.available_seat = $scope.total_beds;
      };

      function getTotalBedsAndRoom () {
        // update the available seats...
        $scope.total_beds = CONST_HELPER.ZERO;
        $scope.total_available_rooms = CONST_HELPER.ZERO;
        $scope.addSlot.slot_lodgings.map(function (val) {
          $scope.total_beds += parseInt(val.total_beds || CONST_HELPER.ZERO);
          $scope.total_available_rooms += parseInt(val.available_units ? val.available_units : CONST_HELPER.ZERO);
        });
      }

      // check if available seats is less than the total beds
      $scope.checkAvailableSeats = function () {
        // if(!$scope.total_beds)
        //     return;
        $scope.total_beds = $scope.total_beds || CONST_HELPER.ZERO;
        if ($scope.addSlot.available_seat > $scope.total_beds) {
          $scope.maxAvailableSeatErr = true;
        } else {
          $scope.maxAvailableSeatErr = false;
        }
        if ($scope.total_available_rooms) {
          $scope.validation.available_rooms = false;
        }
      };

      // generate unique cart session id...
      function getPackageTempId () {
        var s4 = function () {
          return Math.floor((CONST_HELPER.ONE + Math.random()) * 0x10000)
            .toString(16)
            .substring(CONST_HELPER.ONE);
        };
        package_temp_id = package_temp_id || s4() + s4();
        return package_temp_id;
      }

      // lodging end

      $scope.addAddOnsList = function (addon) { // add addOns in array
        if (addon) {
          $scope.newPackage.package_addons[$scope.newPackage.package_addons.indexOf(addon)] = addon;
          addon.editMode = false;
          return;
        }
        // eslint-disable-next-line no-unused-expressions
        $scope.package_addons.child_price ? null : $scope.package_addons.child_price = '';
        $scope.newPackage.package_addons.push($scope.package_addons); // push created addOns in array
        $scope.package_addons = {}; // set blank  adon object
      };

      $scope.addonsCheckboxClick = function (value) { // same as adult price check box
        if (value === true) { // check checkbox is checked or not
          $scope.package_addons.child_price = $scope.package_addons.adult_price; // if checked then set adult price in child price
        } else { // else set child price blank
          $scope.package_addons.child_price = '';
        }
      };

      $scope.addonsNotApplicableForChilds = function (value) { // Not applicable for childs.
        if (value === true) { // check checkbox is checked or not
          $scope.package_addons.child_price = '';
          $scope.package_addons.checked = false;
          // $scope.package_addons.not_applicable_for_child = 1; // if checked then set Not applicable for childs.
        } else { // else set child price blank
          // $scope.package_addons.not_applicable_for_child = CONST_HELPER.ZERO;
        }
      };

      $scope.addOnAdultPrice = function () { // set addon child price same as adult price
        if ($scope.package_addons.checked) { // if same as adult price checked
          $scope.package_addons.child_price = $scope.package_addons.adult_price;
        }
      };

      $scope.editAddOn = function (index) { // edit existing addon
        $scope.editorEnabled = index; // set index on editable row
        var selectedField = $scope.newPackage.package_addons[index]; // get clicked object and set in variable
        $scope.editAddOnRow.inclusionName = selectedField.inclusion_name;
        $scope.editAddOnRow.adultPrice = selectedField.adult_price;
        $scope.editAddOnRow.childPrice = selectedField.child_price;
        angular.copy($scope.newPackage.package_addons[index], $scope.addPrevious); // make a copy of selected object into array in $scope.addPrevious
      };
      $scope.editNewAddOn = function (addon, index) {
        addon.editMode = true;
      };

      $scope.updateAddOn = function (index) { // update addon
        var selectedField = {};
        if ($scope.editAddOnRow.inclusionName === '' || !$scope.editAddOnRow.adultPrice || !$scope.editAddOnRow.childPrice) { // if any field is blank then disable button
          $scope.disabledEdit = true; // add disabled flag true
        } else {
          selectedField.inclusion_name = $scope.editAddOnRow.inclusionName;
          selectedField.adult_price = $scope.editAddOnRow.adultPrice;
          selectedField.child_price = $scope.editAddOnRow.childPrice;
          $scope.newPackage.package_addons[index] = selectedField; // update edited row in array
          $scope.editorEnabled = CONST_HELPER.MINUS_ONE;
        }
      };

      $scope.deleteAddOn = function (index) { // delete selected array from addon
        $scope.newPackage.package_addons.splice(index, CONST_HELPER.ONE);
      };

      $scope.setPreviousAddOn = function (index) { // set previous value in object on click croos button in addon
        $scope.newPackage.package_addons[index] = $scope.addPrevious;
        $scope.editorEnabled = CONST_HELPER.MINUS_ONE;
      };

      /* Package duration function start here */
      $scope.checkDurationDate = function (value) { // check number of days for duration
        // if(value === $scope.newPackage.duration_Day)
        //     return;
        if ($scope.newPackage.package_slots && $scope.newPackage.package_slots.length) { // if duration day change then flow this loop
          if (confirm('Changing the date will delete all the saved slots. Do you want to continue?') === true) {
            // "You pressed OK!";
            $scope.newPackage.package_slots = [];
          } else {
            $scope.newPackage.duration_Day = $scope.durationDayTemp;
          }
        }

        if (value < CONST_HELPER.ONE) { // check for less then CONST_HELPER.ZERO value
          $scope.showZeroDateErr = true; // show error message
        }
        if (value >= CONST_HELPER.PACKAGE.MAX_SLOT_DAYS) { // check for greater then 50 value
          $scope.showValidDateErr = true; // show error message
        }
      };

      $scope.checkDuration = function () { // check day duration value on addslot button click
        $('.datepicker').datepicker('destroy'); // destroy datepicker
        if (!$scope.newPackage.duration_Day) { // if value of day duration false show alert
          $scope.addSlotDurationEmpty = true;
          $('#dayDuration').focus();
          return false;
        }
        if ($scope.showValidDateErr || $scope.showZeroDateErr) {
          $('#dayDuration').focus();
          return false;
        } else { // Show add slot popup
          showAddSlotPopups();
        }
      };

      // checks for showing add slot popup...
      function showAddSlotPopups () {
        if (
          (
            parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_SEAT
            ||
            parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION
          )
          &&
          parseInt($scope.newPackage.lodging_included) === CONST_HELPER.EVENT_LODGING.YES
          &&
          (
            parseInt($scope.newPackage.lodging_status) === CONST_HELPER.EVENT_LODGING.YES
            ||
            parseInt($scope.newPackage.event_lodging_status) === CONST_HELPER.EVENT_LODGING.YES
          )
        ) {
          // if lodging added then bind it with addSlot object
          if ($scope.newPackage.lodgingDetail.length) {
            $scope.addSlot = $scope.addSlot ? $scope.addSlot : {};
            $scope.addSlot.available_seat = $scope.addSlot.available_seat || CONST_HELPER.ZERO;
            $scope.addSlot.slot_lodgings = angular.copy($scope.newPackage.lodgingDetail);
            $scope.addSlot.slot_lodgings.map(function (val) {
              val.lodging_id = val.id;
              delete val.id;
              delete val.lodging_medias;
              delete val.package_temp_id;
              delete val.description;
              delete val.is_lodging_booked;
            });
            $scope.showPopup('#addSlotModal');
            $scope.noLodgingError = false;
          } else {
            $scope.noLodgingError = true; // show error msg if no lodging added.
          }
        } else {
          $scope.showPopup('#addSlotModal');
          $scope.noLodgingError = false;
        }
      }

      $scope.slotDateChange = function (value, index) { // change date function for slot
        $scope.sameDateError = false;
        $scope.validation.from_date = false;
        if ($scope.newPackage.package_slots && $scope.newPackage.package_slots.length) { // check for date already exist or not
          angular.forEach($scope.newPackage.package_slots, function (e, i) {
            if ($scope.slotIndex - CONST_HELPER.ONE !== i && e.from_date === value) {
              $scope.sameDateError = true;
              value = '';
            }
          });
        }
        if (value) { // if value doesn't exist false
          $scope.validation.from_date = false;
          var myDate = new Date(value); // get start date value
          var newDate = $scope.setEndDate(myDate, $scope.newPackage.duration_Day - CONST_HELPER.ONE); // call enddate function
          $scope.addSlot.to_date = $filter('date')(new Date(newDate), 'MM/dd/yyyy'); // convert end date in mm-dd-yyyy format
          $('.datepicker').datepicker('destroy'); // destroy datepicker
        }
      };

      $scope.setEndDate = function (myDate, days) { // calculate end date for package slot
        return new Date(myDate.getTime() + days * CONST_HELPER.TWENTYFOUR * CONST_HELPER.SIXTY * CONST_HELPER.SIXTY * CONST_HELPER.TEXTLIMIT);
      };

      $scope.addMoreSlots = function () { // upadte conditions for modal set up
        $scope.slotIndex = CONST_HELPER.ZERO;
        $scope.editSlotFlag = false;
        $scope.total_available_rooms = CONST_HELPER.ZERO;
        showAddSlotPopups();
      };

      $scope.saveSlot = function () { // save slot
        if ($scope.addSlot.max_capacity < CONST_HELPER.ZERO) { // Check for safari browser
          $scope.maxCapacityErr = true;
        } else {
          if (parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
            $scope.addSlot.available_seat = $scope.addSlot.max_capacity;
          }
          $scope.addSlot.available_seat = $scope.addSlot.available_seat || 0;
          $scope.maxCapacityErr = false;
        }
        if (!$scope.addSlot.available_seat) { // Check for safari browser
          $scope.availableSeatErr = true;
        } else {
          $scope.availableSeatErr = false;
        }

        if ($scope.addSlotForm.$invalid) { // check form is valid or not
          var invalidFields = $scope.addSlotForm.$error.required;
          if (invalidFields) {
            for (var i = CONST_HELPER.ZERO; i < invalidFields.length; i++) { // add flag for invalid field's and show error
              $scope.validation[invalidFields[i].$name] = true; // if field is required set them required true
            }
            angular.element('input.ng-invalid').first().focus();
            return false;
          }
        }

        // package case lavel changes
        if (parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_SEAT) {
          if ($scope.addSlot.available_seat < CONST_HELPER.ZERO) {
            return false;
          }
        } else if (parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
          if ($scope.total_available_rooms < CONST_HELPER.ZERO) {
            $scope.validation.available_rooms = true;
            return;
          } else {
            $scope.validation.available_rooms = false;
            $scope.addSlot.available_seat = $scope.total_available_rooms;
            $scope.addSlot.max_capacity = $scope.addSlot.available_seat;
          }
        }

        if ($scope.addSlot.max_capacity < CONST_HELPER.ZERO) { // check for addSlot max_capacity value not less than CONST_HELPER.ZERO
          $scope.validation.max_capacity = true;
          return false;
        } else {
          $scope.validation.max_capacity = false;
        }
        if ($scope.addSlot.available_seat < CONST_HELPER.ZERO && parseInt($scope.package_case) !== CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) { // check for addSlot available_seat value not less than CONST_HELPER.ZERO
          $scope.validation.available_seat = true;
          return false;
        } else {
          $scope.validation.available_seat = false;
        }

        if ($scope.slotIndex !== CONST_HELPER.ZERO) { // update add slot
          if (!$scope.sameDateError) {
            $scope.newPackage.package_slots[$scope.slotIndex - CONST_HELPER.ONE] = $scope.addSlot; // update package sloat into array
            $scope.addSlot = {}; // Set addslot object blank
            $scope.hidePopup('#addSlotModal'); // Hide add slot popup
            $scope.sameDateError = false;
          }
        } else { // create new slot
          $scope.durationDayTemp = $scope.newPackage.duration_Day;
          $scope.addSlot.commission = $scope.user.operator_commissions;
          $scope.newPackage.package_slots.push($scope.addSlot); // push package sloat into array
          $scope.addSlot = {}; // Set addslot object blank
          $scope.hidePopup('#addSlotModal'); // Hide add slot popup
          $scope.sameDateError = false;
        }
        $scope.slotIndex = CONST_HELPER.ZERO; // set slotIndex value ZERO
        $timeout(function () { //
          $('.selectpicker').selectpicker(); // initialize selectpicker...
        });
        $scope.setAmountPerc($scope.newPackage.amount_percentage, $scope.newPackage.deposit_type); // check For deposit amount for update slot or new slot...
        $scope.getMaxAndMinDate(); // get the minimum and maximum publish end date...
        $scope.newPackage.publish_end_date = maxDate;
      };

      $scope.editSlot = function (packageSlot) {
        /* Edit Added slot */
        $scope.showPopup('#addSlotModal');
        $scope.editSlotFlag = true;
        var index = $scope.newPackage.package_slots.indexOf(packageSlot);
        $scope.slotIndex = index + CONST_HELPER.ONE;

        // if new lodging added add that in existing slots.
        if ($scope.newPackage.package_case && parseInt($scope.newPackage.package_case) !== CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && parseInt($scope.newPackage.lodging_included) === CONST_HELPER.EVENT_LODGING.YES) {
          if (packageSlot.slot_lodgings && packageSlot.slot_lodgings.length < $scope.newPackage.lodgingDetail.length) {
            var newLodging = angular.copy($scope.newPackage.lodgingDetail);
            var uniqueLodging = newLodging.filter(function (obj) { // find the differnent lodging in existing slots and available Lodging.
              return packageSlot.slot_lodgings.map(function (s) {
                return s.lodging_id;
              }).indexOf(obj.id) === CONST_HELPER.MINUS_ONE;
            });
          }
          if (uniqueLodging && uniqueLodging.length) {
            for (var i = CONST_HELPER.ZERO; i < uniqueLodging.length; i++) {
              uniqueLodging[i]['lodging_id'] = uniqueLodging[i]['id'];
              delete uniqueLodging[i]['id'];
              delete uniqueLodging[i]['lodging_medias'];
              delete uniqueLodging[i]['package_temp_id'];
              delete uniqueLodging[i]['package_id'];
              delete uniqueLodging[i]['is_lodging_booked'];
              delete uniqueLodging[i]['description'];
              delete uniqueLodging[i]['modified'];
            }
            packageSlot.slot_lodgings = packageSlot.slot_lodgings.concat(uniqueLodging);
          }
        }
        $scope.addSlot = packageSlot;
        angular.copy(packageSlot, tempStore);
        // update the total rooms and available beds
        if (parseInt($scope.newPackage.package_case) !== CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && parseInt($scope.newPackage.event_lodging_status) === CONST_HELPER.EVENT_LODGING.YES) {
          getTotalBedsAndRoom();
        }
      };

      $scope.closePopup = function () {
        /* Close Add Slot Popup */
        $scope.sameDateError = false;
        $scope.editSlotFlag = false;
        $scope.maxCapacityErr = false;
        $scope.availableSeatErr = false;
        // in case of edit slot reset the slot
        if ($scope.slotIndex) {
          $scope.newPackage.package_slots[$scope.slotIndex - CONST_HELPER.ONE] = tempStore;
        }
        tempStore = {};
        $scope.hidePopup('#addSlotModal');
        $scope.addSlot = {}; // Set addslot object blank
        $('#addSlotModal').on('hidden.bs.modal', function () {
          var invalidFields = $scope.addSlotForm.$error.required;
          for (var i = CONST_HELPER.ZERO; i < invalidFields.length; i++) { // add flag for invalid field's and show error
            $scope.validation[invalidFields[i].$name] = false; // if field is required set them required true
          }
        });
        $scope.slotIndex = CONST_HELPER.ZERO; // reset slot
      };

      $scope.deleteSlot = function (slot) {
        /* Delete Added slot */
        var index = $scope.newPackage.package_slots.indexOf(slot);
        $scope.newPackage.package_slots.splice(index, CONST_HELPER.ONE);
        if ($scope.newPackage.package_slots && $scope.newPackage.package_slots.length === CONST_HELPER.ZERO) {
          $scope.newPackage.amount_percentage = CONST_HELPER.ZERO;
          $scope.newPackage.is_partial = false;
          $scope.showAmountPercErr = false;
          $scope.showAmountPercErrZero = false;
          $scope.depositErr = false;
          // $scope.partialCheckbox(false);
        }
        $scope.setAmountPerc($scope.newPackage.amount_percentage, $scope.newPackage.deposit_type);
        $scope.getMaxAndMinDate(); // get the minimum and maximum publish end date...
        $scope.newPackage.publish_end_date = maxDate;
      };
      $scope.convertToDate = function (stringDate) { // convert Date format for addslot
        var dateOut = new Date(stringDate);
        dateOut.setDate(dateOut.getDate());
        return dateOut;
      };
      /* Package duration functions End here */

      /* Package itinerary functions Start here */

      $scope.deleteTimePeriod = function (index) {
        $scope.newPackage.itinerary_day_description.splice(index, CONST_HELPER.ONE);
      };
      $scope.addTimePeriod = function () {
        if ($scope.newPackage.itinerary_day_description) {
          $scope.newPackage.itinerary_day_description.push({
            time_period: '',
            day_description: ''
          });
        }
      };

      /* Package itinerary functions End here */

      /* Package gallery functions Start here */

      // eslint-disable-next-line no-unused-vars
      var maxDate, minDate;
      $scope.bounds = {};
      $scope.gallery = {};
      $scope.savedImage = {};
      $scope.myImage = [];
      $scope.uploadImages = [];
      $scope.gallery_media = [];
      $scope.gallery.sourceImage = null;
      $scope.gallery.croppedImage = null;
      $scope.bounds.left = CONST_HELPER.ZERO;
      $scope.bounds.right = CONST_HELPER.ZERO;
      $scope.bounds.top = CONST_HELPER.ZERO;
      $scope.bounds.bottom = CONST_HELPER.ZERO;
      $scope.showCrop = false;
      $scope.showUploadImage = true;
      $scope.endDateCheckErr = false;
      $scope.publishEndDateErr = false;
      $scope.publishEndDateErr2 = false;
      $scope.publishStartDateErr = false;

      $scope.handleSingleFileSelect = function (evt) { // for single file upload
        $scope.minSingleImgSize = false; // hide error message
        $scope.maxSingleImgSize = false; // hide error message
        $scope.invalidFormatImg = false;
        if (evt.currentTarget.files[CONST_HELPER.ZERO].size < CONST_HELPER.MEDIA.MIN_SIZE) { // check size of image is less then 50KB
          if ($scope.savedImage.croppedImage) { // if value already croped then set cropped value as well
            evt.currentTarget.value = '';
            $scope.gallery.croppedImage = $scope.savedImage.croppedImage;
          }
          $scope.minSingleImgSize = true; // show error message
          $timeout(angular.noop);
          return false;
        }

        var file = evt.currentTarget.files[CONST_HELPER.ZERO];
        var fileExtnArr = file.name.split('.');
        var extn = fileExtnArr[fileExtnArr.length - CONST_HELPER.ONE].toLowerCase();

        if (extn === 'png' || extn === 'jpg' || extn === 'jpeg' || extn === 'gif') { // check valid file extention
          $scope.showPopup('#cropImagePopup'); // show crop image popup
          if ($scope.featured_image) {
            $scope.showPopup('#cropImagePopup'); // hide crop image popup
            return false;
          }
          if (!$scope.savedImage.croppedImage) {
            $scope.showCrop = false;
          }
        } else {
          $scope.invalidFormatImg = true;
          return false;
        }
      };

      angular.element(document.querySelector('#singleFileInput')).on('change', $scope.handleSingleFileSelect); // image change function
      angular.element(document.querySelector('#changePhoto')).on('change', $scope.handleSingleFileSelect); // image change function

      $scope.compressImage = function (file, callback) {
        var image = new Image();
        image.file = file;
        image.onload = function () {
          var quality = 1;
          var fileName = this.file.name;
          if (this.file.size > CONST_HELPER.MEDIA.MAX_SIZE) {
            quality = CONST_HELPER.MEDIA.MAX_SIZE / this.file.size;
          }
          var canvas = document.createElement('canvas');
          canvas.width = this.width * quality;
          canvas.height = this.height * quality;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(this, 0, 0, this.width * quality, this.height * quality);
          callback(
            canvas.toDataURL('image/jpeg', 0.5)
          );
        };
        image.src = window.URL.createObjectURL(file);
      };

      $scope.cropImage = function () { // crop image function
        $scope.hidePopup('#cropImagePopup'); // hide crop image popup
        $scope.showUploadImage = false;
        $scope.showCrop = true;
        $scope.featured_image = '';

        var file = $scope.dataURLtoFile($scope.gallery.croppedImage);

        $scope.compressImage(file, function (compressedImage) {
          $scope.gallery.croppedImage = compressedImage;
          angular.copy($scope.gallery, $scope.savedImage);
          $scope.$digest();
        });
      };

      $scope.dataURLtoFile = function (dataurl) {
        var arr = dataurl.split(',');
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], {
          type: mime
        });
      };

      $scope.cancelImageCrop = function (popupId) { // cancel image crop
        if ($scope.featured_image) {
          $scope.hidePopup('#' + popupId); // hide crop image popup
          document.getElementById('changePhoto').value = ''; // set image file value empty
          return false;
        }
        if ($scope.savedImage.croppedImage) {
          $scope.gallery.croppedImage = $scope.savedImage.croppedImage;
          $scope.showUploadImage = false;
          $scope.hidePopup('#cropImagePopup'); // hide crop image popup
          document.getElementById('changePhoto').value = ''; // set image file value empty
        } else {
          $scope.showUploadImage = true;
          $scope.hidePopup('#cropImagePopup'); // hide crop image popup
          document.getElementById('singleFileInput').value = ''; // set image file value empty
        }
      };

      $scope.minMultipleImgSize = false;
      $scope.maxMultipleImgSize = false;
      $scope.handleMultiFileSelect = function (evt) { // upload gallery images
        $scope.minMultipleImgSize = false;
        var imagesCanByUploedCount = CONST_HELPER.PACKAGE.MAX_GALLERY_IMAGES - $scope.myImage.length;
        var uploadedImagesCount = evt.currentTarget.files.length;
        if (uploadedImagesCount > imagesCanByUploedCount) {
          uploadedImagesCount = imagesCanByUploedCount;
        }
        for (var i = CONST_HELPER.ZERO; i < uploadedImagesCount; i++) {
          if (evt.currentTarget.files[i].size < CONST_HELPER.MEDIA.MIN_SIZE) { // check size of image is less then 50KB
            evt.currentTarget.value = '';
            $scope.minMultipleImgSize = true;
            $timeout(angular.noop);
            if (!$scope.$$phase) {
              $scope.$apply();
            }
            return false;
          } else {
            $scope.minMultipleImgSize = false;
          }

          var file = evt.currentTarget.files[i];
          var fileExtnArr = file.name.split('.');
          var extn = fileExtnArr[fileExtnArr.length - CONST_HELPER.ONE].toLowerCase();
          if (extn === 'png' || extn === 'jpg' || extn === 'jpeg' || extn === 'gif') {
            $scope.compressImage(file, function (compressedImage) {
              $scope.myImage.push(compressedImage);
              fetch(compressedImage)
                .then(function (res) {
                  return res.blob();
                })
                .then(function (blob) {
                  $scope.uploadImages.push(new File([blob], file.name));
                });
              $scope.$digest();
            });
          } else {
            /* if image extn invalid then show alert message */
            file = '';
            $scope.invalidGalleryFormatImg = true;
            $timeout(angular.noop);
          }
        }
      };

      $scope.deleteImage = function (imgId, index) { // delete images from gallery
        $scope.myImage.splice(index, CONST_HELPER.ONE);
        if (imgId) {
          var uploadImageIndex = Math.abs(index - (maxImageLength + CONST_HELPER.ONE));
          $scope.uploadImages.splice(uploadImageIndex, CONST_HELPER.ONE);
          angular.forEach($scope.gallery_media, function (img) {
            if (img.id === imgId) {
              $scope.gallery_media.splice(index, CONST_HELPER.ONE);
            }
          });
          maxImageLength++;
        } else {
          $scope.uploadImages.splice(index, CONST_HELPER.ONE);
        }
      };

      angular.element(document.querySelector('#multipleFileInput')).on('change', $scope.handleMultiFileSelect);
      angular.element(document.querySelector('#addMultipleFileInput')).on('change', $scope.handleMultiFileSelect);

      $scope.addMoreUrl = function () { // add more url for embbeded video
        if ($scope.videoUrl.length < CONST_HELPER.TWO) {
          $scope.videoUrl.push({
            'url': $scope.newPackage.video
          }); // push into array
          $scope.newPackage.video = ''; // make video input blank
          $scope.videoUrlErr = false; // hide video error message
        } else {
          $scope.videoUrlErr = true; // show video error message
        }
      };

      $scope.deleteVideoUrl = function (index) { // delete added video url for array
        $scope.videoUrl.splice(index, CONST_HELPER.ONE);
        $scope.videoUrlErr = false; // hide video error message
      };

      /* Package gallery functions End here */

      $scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) { // get new hash value as the tab is switched
        $scope.currentHash = newUrl.split('#')[CONST_HELPER.ONE] || 'location';
        if ($scope.currentHash === 'eventDetails') {
          if ($scope.newPackage.event_eventName === '' || $scope.packageCreation.event_eventName.$invalid) {
            $scope.disableNext = true;
          } else {
            $scope.disableNext = false;
          }
          $scope.mobileHash = 'EVENT DETAILS';
        } else if ($scope.currentHash === 'lodging') {
          $scope.mobileHash = 'LODGING';
          if ($scope.newPackage.id && $scope.newPackage.lodgingDetail.length && $scope.bookingTypeWasChanged) {
            $scope.newPackage.lodgingDetail.map(function(lodge){
             delete lodge.max_guests;
             delete lodge.price;
           })
          }
        } else if ($scope.currentHash === 'duration') {
          $scope.mobileHash = 'DURATION';
        } else if ($scope.currentHash === 'itinerary') {
          $scope.mobileHash = 'ITINERARY';
        } else if ($scope.currentHash === 'amenities') {
          $scope.mobileHash = 'AMENITIES & ADD-ONS';
        } else if ($scope.currentHash === 'gallery') {
          $scope.mobileHash = 'GALLERY';
        } else if ($scope.currentHash === 'cancellationPolicy') {
          $scope.mobileHash = 'CANCELLATION POLICY';
        } else if ($scope.currentHash === 'location') {
          $scope.mobileHash = 'LOCATION';
        } else if ($scope.currentHash === 'taxesandfee') {
          $scope.mobileHash = 'TAXAS AND FEES';
        }
        $window.scrollTo(CONST_HELPER.ZERO, CONST_HELPER.ZERO); // redirect on top when hash change
      });

      /* Cancel Policy Functions Start here */

      $scope.publishStartDate = function (startDate) {
        /* Set publish start date */
        $scope.publishStartDateErr = false;
        $scope.endDateCheckErr = false;
        $scope.publishStartDateErr1 = false;
        $scope.getMaxAndMinDate();
        $scope.DateCheck();
      };

      $scope.publishEndDate = function (endDate) {
        /* Set publish end date */
        $scope.publishEndDateErr = false;
        $scope.endDateCheckErr = false;
        $scope.publishEndDateErr1 = false;
        $scope.publishEndDateErr2 = false;
        $scope.DateCheck();
      };

      $scope.DateCheck = function () { // function for end date not greater then start end
        // if ($scope.newPackage.booking_type == CONST_HELPER.ONE) {
        var StartDate = document.getElementById('publishStart').value;
        var EndDate = document.getElementById('publishEnd') ? document.getElementById('publishEnd').value : '';
        var eDate = new Date(EndDate);
        var sDate = new Date(StartDate);

        $scope.getMaxAndMinDate();
        var date1 = new Date(EndDate);
        var date2 = new Date(maxDate);
        var diffDays = Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) / (CONST_HELPER.TEXTLIMIT * CONST_HELPER.SIXTY * CONST_HELPER.SIXTY * CONST_HELPER.TWENTYFOUR));

        if (StartDate !== '' && StartDate !== '' && sDate > eDate) {
          $scope.endDateCheckErr = true;
          $scope.publishEndDateErr = false;
          return false;
        } else if (diffDays < CONST_HELPER.ZERO) {
          $scope.publishEndDateErr = true;
          $scope.endDateCheckErr = false;
          return false;
        }
        // For Nightly Booking Check this.
        if ($scope.newPackage.booking_type == 2 && moment(EndDate).isAfter(maxDate)) {
          $scope.publishEndDateErr2 = true;
          $scope.endDateCheckErr = false;
        }
      };

      $scope.getMaxAndMinDate = function () {
        /* To get Max and min date from package_slots array */
        if ($scope.newPackage.booking_type == CONST_HELPER.ONE) {
          var publishStartDate = [];
          var publishEndDate = [];
          angular.forEach($scope.newPackage.package_slots, function (date) {
            publishEndDate.push(new Date(date.to_date));
            publishStartDate.push(new Date(date.from_date));
          });
          maxDate = $filter('date')(new Date(Math.max.apply(null, publishStartDate)), 'MM/dd/yyyy');
          minDate = $filter('date')(new Date(Math.min.apply(null, publishStartDate)), 'MM/dd/yyyy');
        } else {
          var events = uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('clientEvents') || [];
          var minMax = getMinAndMaxDateForCustomEvents(events);
          maxDate = $filter('date')(minMax.max, 'MM/dd/yyyy');
          minDate = $filter('date')(minMax.min, 'MM/dd/yyyy');
        }
      };

      /* Cancel Policy Functions End here */
      $scope.publishPackageFormValidate = function (form, ifEdit) {
        var header = [];
        $scope.errors = [];
        if ($scope.minSingleImgSize || $scope.maxSingleImgSize || $scope.invalidFormatImg) {
          $scope.errors.push('gallery_croppedImage');
          header.push('gallery');
        }

        // lodging is yes and no-lodge is added,
        if ((parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_SEAT || parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) && !$scope.newPackage.lodgingDetail.length && parseInt($scope.newPackage.event_lodging_status) === CONST_HELPER.EVENT_LODGING.YES) {
          $scope.errors.push('lodging_rooms');
          header.push('lodging');
        }

        if (form.$invalid || $scope.errors.length || ($scope.newPackage.booking_type == CONST_HELPER.ONE && ($scope.newPackage.package_slots && $scope.newPackage.package_slots.length === CONST_HELPER.ZERO)) || $scope.minSingleImgSize || $scope.maxSingleImgSize || $scope.invalidFormatImg || $scope.showCustomDateError || tempArr.includes(false)) { // if form is invalid
          angular.forEach(form.$error, function (value, key) { // look for errors
            for (var i = CONST_HELPER.ZERO; i < value.length; i++) {
              header.push((value[i].$name).split('_')[CONST_HELPER.ZERO]);
              $scope.errors.push(value[i].$name);
            }
          });

          if ($scope.newPackage.booking_type == CONST_HELPER.ONE && ($scope.newPackage.package_slots && $scope.newPackage.package_slots.length === CONST_HELPER.ZERO || !$scope.hasActiveSlots) || $scope.showCustomDateError) {
            header.push('duration');
            $scope.errors.push('duration');
          } // check for duration slots length
          if (tempArr.includes(false) && $scope.newPackage.booking_type == CONST_HELPER.TWO && $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT) {
            header.push('duration');
            $scope.errors.push('duration');
          }
          var uniqueNames = [];
          $.each(header, function (i, el) {
            if ($.inArray(el, uniqueNames) === CONST_HELPER.MINUS_ONE) uniqueNames.push(el);
          });
          if (ifEdit === 'edit' && uniqueNames.length > CONST_HELPER.ZERO) {
            $scope.saveAndExitError = true;
            return false;
          }
          for (var i = CONST_HELPER.ZERO; i < uniqueNames.length; i++) {
            switch (uniqueNames[i]) {
              case 'event':
                $scope.showRedTickEvent = true;
                $scope.showGreenTickEvent = false;
                break;
              case 'lodging':
                $scope.showRedTickLodging = true;
                $scope.showGreenTickLodging = false;
                break;
              case 'duration':
                $scope.showRedTickDuration = true;
                $scope.showGreenTickDuration = false;
                break;
              case 'itinerary':
                $scope.showRedTickItinerary = true;
                $scope.showGreenTickItinerary = false;
                break;
              case 'gallery':
                $scope.showRedTickGallery = true;
                $scope.showGreenTickGallery = false;
                break;
              case 'amenities':
                $scope.showRedTickAmenities = true;
                $scope.showGreenTickAmenities = false;
                break;
            }
          }
          $scope.publishError = true;
          // fetch name of error  option to show red cross and show all errors
          // alert('Please fill missing information in tabs highlighted in red.');
          $scope.missingErrorMsg = 'Please fill missing information in tabs highlighted in red.';
          $scope.showPopup('#missingInfoPackagePublish');
        } else { // if everything is valid and form can be submiited
          if (!$scope.cancelPolicyDetail[CONST_HELPER.ZERO].min_num_cancellation_days || $scope.cancelPolicyDetail[CONST_HELPER.ZERO].min_num_cancellation_days < CONST_HELPER.ONE) {
            return false;
          }

          if (!$scope.cancelPolicyDetail[CONST_HELPER.ZERO].cancellation_description || $scope.depositErr || $scope.showAmountPercErr || ($scope.newPackage.is_partial && $scope.showAmountPercErrZero)) {
            return false;
          }
          $scope.getMaxAndMinDate(); // get the minimum and maximum publish end date...
          if (!package_id || !$scope.newPackage.publish_end_date || !$scope.newPackage.publish_start_date) { // on package
            $scope.newPackage.publish_end_date = maxDate;
          }
          $scope.showPopup('#myPublishModal'); // show the modal window for publish
        }
      };

      $scope.publishPackage = function (action) { // package publishing
        if (!$scope.newPackage.publish_start_date || $scope.newPackage.publish_start_date === '') {
          $scope.publishStartDateErr1 = true;
          return false;
        }
        if (!$scope.newPackage.publish_end_date || $scope.newPackage.publish_end_date === '') {
          $scope.publishEndDateErr1 = true;
          return false;
        }

        if ($scope.newPackage.publish_start_date && !action) {
          var publishYear = new Date($scope.newPackage.publish_start_date).getFullYear();
          if (publishYear < CONST_HELPER.TWENTYSIXTEEN) {
            $scope.publishStartDateErr = true;
            return false;
          }
        }
        if ($scope.publishStartDateErr || $scope.publishEndDateErr || $scope.publishEndDateErr2 || $scope.endDateCheckErr) { // check for if invalid date error msg shown
          return false;
        }

        if ($scope.newPackage.booking_type == CONST_HELPER.TWO && !action) {
          $scope.updateBookingWindow(null, false, false);
        }

        setRequestObj();
        $scope.requestObject.publish_start_date = $filter('date')(new Date($scope.newPackage.publish_start_date), 'MM/dd/yyyy'); // add start date
        $scope.requestObject.publish_end_date = $filter('date')(new Date($scope.newPackage.publish_end_date), 'MM/dd/yyyy'); // add end date
        $scope.showLoader();
        console.log('Package Creation Object', $scope.requestObject);
        delete $scope.requestObject['gallery_images[]'];

        if (package_id) { // update package deatil if package id exist
          $scope.requestObject.package_id = package_id;
          $scope.requestObject.old_gallery_images = $scope.gallery_media.length ? $scope.gallery_media : [{id: 0}];
          createAdventureService.publishPackage($scope.requestObject, $scope.uploadImages).then(function (response) { // post to the server the form data
            if (response) {
              if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // package saved
                if (action === 'edit') {
                  $scope.hidePopup('#myPublishModal');
                  resetCookies();
                  $state.go('operator-dashboard');
                  $scope.saveErr = false;
                  $scope.hideLoader();
                } else {
                  $scope.hidePopup('#myPublishModal'); // close publish modal
                  $scope.showPopup('#myDoneModal'); // show done success modal
                  resetCookies();
                  $scope.publishPackageErr = false;
                  $scope.hideLoader();
                }
              } else {
                $scope.publishPackageErr = true;
                $scope.publishPackageMessage = 'Some Parameters are missing.';
                $scope.hideLoader();
              }
            }
          }, function () { // check for error
            $scope.hideLoader();
            $scope.hidePopup('#myPublishModal'); // close publish modal
            $scope.showPopup('#serverErrModal');
          });
        } else {
          createAdventureService.publishPackage($scope.requestObject, $scope.uploadImages).then(function (response) { // post to the server the form data
            if (response) {
              if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // package saved
                $scope.hidePopup('#myPublishModal'); // close publish modal
                $scope.showPopup('#myDoneModal'); // show done success modal
                $scope.publishPackageErr = false;
                $rootScope.user.package_count = parseInt($rootScope.user.package_count) + CONST_HELPER.ONE;
                // $rootScope.user.is_bank_details_completed = true;
                resetCookies();
                $cookies.put('user', JSON.stringify($rootScope.user)); // set local storage again
                $timeout(angular.noop);
                $scope.hideLoader();
              } else {
                $scope.publishPackageErr = true;
                $scope.publishPackageMessage = 'Some Parameters are missing.';
                $scope.hideLoader();
              }
            }
          }, function () { // check for error
            $scope.hideLoader();
            $scope.hidePopup('#myPublishModal'); // close publish modal
            $scope.showPopup('#serverErrModal');
          });
        }
      };

      function resetCookies () {
        // reset cookies after package is being saved.
        $cookies.put('package_type', '');
        $cookies.put('package_case', '');
      }

      $scope.closePublishPopup = function () {
        $scope.hidePopup('#myPublishModal'); // close publish modal
      };

      function getDays () {
        if ($scope.newPackage.booking_type == CONST_HELPER.ONE) {
          return $scope.newPackage.duration_Day ? $scope.newPackage.duration_Day : CONST_HELPER.ZERO;
        } else if ($scope.bookingWindowRange && $scope.newPackage.booking_type == CONST_HELPER.TWO) {
          var events = uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('clientEvents') || [];
          var minMax = getMinAndMaxDateForCustomEvents(events);
          var a = moment(minMax.min);
          var b = moment(minMax.max);
          return b.diff(a, 'days') + CONST_HELPER.ONE;
          // return $scope.newPackage.duration_Day ? $scope.newPackage.duration_Day : CONST_HELPER.ZERO
        } else {
          return CONST_HELPER.ZERO;
        }
      }

      function setRequestObj () {
        if ($scope.gallery.croppedImage === null || $scope.minSingleImgSize || $scope.invalidFormatImg) {
          $scope.gallery.croppedImage = '';
        }
        var lodging_obj = [];
        if ($scope.newPackage.booking_type == CONST_HELPER.TWO && $scope.nightly_slot_lodgings && $scope.nightly_slot_lodgings.length > CONST_HELPER.ZERO) {
          var obj = $scope.nightly_slot_lodgings;
          obj.map(function (lodge) {
            if (lodge.calendar) {
              lodge.calendar.map(function (cal) {
                cal['max_capacity'] = cal['max_capacity'] ? cal['max_capacity'] : cal['available_seat'] || lodge.max_capacity;
                delete cal.source;
                return cal;
              });
            }
            if ($scope.newPackage.event_lodging_status == CONST_HELPER.ZERO || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
              lodge.price = ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT) ? $scope.newPackage.base_price : undefined;
            }
            if ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
              lodge.max_capacity = lodge.available_room;
            } else {
              lodge.max_capacity = $scope.newPackage.max_seat_available;
            }
            return lodge;
          });
          lodging_obj = obj;
          var events = uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('clientEvents') || [];
          var minMax = getMinAndMaxDateForCustomEvents(events);
        }

        if(lodging_obj && lodging_obj.length){
          lodging_obj.forEach(function(eachLoging){
            eachLoging.updated_dates = [];
          });
        }

        if ($scope.newPackage.package_discounts && $scope.newPackage.package_discounts.length > CONST_HELPER.ZERO) {
          $scope.newPackage.package_discounts.map(function (lodge) {
            delete lodge.$$hashKey;
            return lodge;
          });
        }
        var form_ids = [];
        if ($scope.packageForms && $scope.packageForms.length > 0) {
          $scope.packageForms.forEach(function (key) {
            form_ids.push(key._id ? key._id : key.id);
          });
        }

        $scope.requestObject = {
          token: $scope.user.token,
          name: $scope.newPackage.event_eventName,
          single_day_activity:  $scope.newPackage.single_day_activity,
          daily_rate_activity:  $scope.newPackage.daily_rate_activity,
          duration: getDays(),
          description: $scope.newPackage.event_description,
          package_sports_id: $scope.primaryActivity,
          inclusions: $scope.newPackage.event_inclusions ? $scope.newPackage.event_inclusions : '',
          packing_list: $scope.newPackage.event_packinglist ? $scope.newPackage.event_packinglist : '',
          currency_id: $scope.newPackage.currency_id,
          location_name: $scope.newPackage.location_name,
          latitude: $scope.newPackage.latitude,
          longitude: $scope.newPackage.longitude,
          arrival_instruction: $scope.newPackage.itinerary_arrival_instruction,
          depature_instruction: $scope.newPackage.itinerary_depature_instruction,
          lodging_status: $scope.newPackage.event_lodging_status,
          lodging_included: $scope.newPackage.lodging_included,
          package_addons: $scope.newPackage.package_addons,
          package_amenities: removeDuplicates($scope.amenities_package_list),
          package_day_details: $scope.newPackage.itinerary_day_description,
          package_package_sports: $scope.package_sports,
          package_slots: $scope.newPackage.booking_type == CONST_HELPER.ONE ? getPackageSlots() : [],
          'gallery_images[]': $scope.uploadImages,
          package_skiier_abilities: removeDuplicates($scope.package_skiier_abilities),
          featured_image: $scope.featured_image ? null : $scope.gallery.croppedImage,
          video: $scope.videoUrl,
          package_cancellation_policies: $scope.cancelPolicyDetail,
          amount_percentage: $scope.newPackage.amount_percentage ? $scope.newPackage.amount_percentage : CONST_HELPER.ZERO,
          deposit_type: $scope.newPackage.is_partial ? $scope.newPackage.deposit_type : CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE,
          is_collect_percentage_taxes_on_the_deposit: $scope.newPackage.is_collect_percentage_taxes_on_the_deposit,
          is_partial: $scope.newPackage.is_partial && $scope.newPackage.amount_percentage ? $scope.newPackage.is_partial : CONST_HELPER.ZERO,
          package_type: $scope.type.package_type,
          package_case: $scope.package_case,
          package_temp_id: package_temp_id,
          // location lodging data
          op_location_id: $scope.currentLocation.id ? $scope.currentLocation.id : '',
          location_description: $scope.currentLocation.id ? $scope.newPackage.event_location_description : '',
          location_description_synced: $scope.location_description_synced ? CONST_HELPER.ONE : CONST_HELPER.ZERO,
          arrival_instruction_synced: $scope.arrival_instruction_synced ? CONST_HELPER.ONE : CONST_HELPER.ZERO,
          depature_instruction_synced: $scope.depature_instruction_synced ? CONST_HELPER.ONE : CONST_HELPER.ZERO,
          // Nightly booking
          booking_type: $scope.newPackage.booking_type,
          package_discounts: $scope.newPackage.package_discounts,
          guest_forms: form_ids,
          waiver_id: $scope.newPackage.waiver ? $scope.newPackage.waiver.id : 0,
          tax: $scope.newPackage.tax,
          nightly_slots: {
            booking_limit:  $scope.newPackage.booking_limit ? $scope.newPackage.booking_limit : 0,
            booking_maximum_days: $scope.newPackage.booking_maximum_days ? $scope.newPackage.booking_maximum_days : null,
            booking_minimum_days: $scope.newPackage.booking_minimum_days ? $scope.newPackage.booking_minimum_days : null,
            total_nightly_available_seat: getTotalSeats(lodging_obj),
            booking_window: $scope.newPackage.window_id,
            base_price: (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE)) ? $scope.newPackage.base_price : undefined,
            from_date: $scope.bookingWindowRange && minMax ? moment(minMax.min).format('MM/DD/YYYY') : '',
            to_date: $scope.bookingWindowRange && minMax ? moment(minMax.max).format('MM/DD/YYYY') : '',
            advance_notice_days: $scope.newPackage.advance_notice_id,
            per_day_available_seat: $scope.newPackage.max_seat_available,
            max_capacity: ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) ? $scope.newPackage.max_seat_available : undefined,
            nightly_slot_lodgings: lodging_obj
          }
        };
      }

      // function to remove the dublicate data
      function removeDuplicates (arr, _prop) {
        var arrId = [];
        var newArr = [];
        newArr = arr.filter(function (v) {
          if (arrId.indexOf(parseInt(v.id)) < 0) {
            arrId.push(parseInt(v.id));
            return true;
          }
          return false;
        });
        return newArr;
      }

      // calculate total nightly seats.
      function getTotalSeats (obj) {
        var totalSeats = CONST_HELPER.ZERO;
        if ($scope.packageCase == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
          obj.forEach(function (lodgingObject) {
            if (lodgingObject && lodgingObject.calendar) {
              lodgingObject.calendar.map(function (e) {
                totalSeats = totalSeats + parseInt(e.available_room);
              });
            }
          });
        } else {
          if (obj && obj.length > CONST_HELPER.ZERO) {
            var lodgingObject = obj[CONST_HELPER.ZERO];
            if (lodgingObject && lodgingObject.calendar) {
              lodgingObject.calendar.map(function (e) {
                totalSeats = totalSeats + parseInt(e.available_seat);
              });
            }
          }
        }
        return totalSeats;
      }

      function getPackageSlots () {
        $scope.newPackage.package_slots.map(function (slot) {
          if (slot.is_private && slot.private_price == 0) {
            slot.private_price = '';
          }
          delete slot.isCollaps;
          delete slot.is_private;
          delete slot.is_expired;
          delete slot.minimumDepositAmount;
          delete slot.min_price;
          delete slot.expired;
          delete slot.usd_adult_price;
          delete slot.usd_child_price;
          delete slot.package_adult_price;
          delete slot.package_child_price;
        });
        return $scope.newPackage.package_slots;
      }

      $scope.checkBookingStatus = function (arr, key, obj) { // disabled checkbox if package purchase
        for (var i = CONST_HELPER.ZERO; i < arr.length; i++) {
          $scope.isCheckbox[obj].push(arr[i][key]);
        }
      };

      $scope.renderCalender = function () {
        $scope.updateBookingWindow(null, true, true);
      };
      var tempArr = [];

      function checkForGreenTicksEvent () {
        if (($.inArray('event_description', $scope.errors) > CONST_HELPER.MINUS_ONE) || $scope.packageCreation.event_description.$invalid) {
          if ($scope.packageCreation.event_description.$invalid) {
            $scope.showGreenTickEvent = false;
            $scope.showRedTickEvent = true;
            $scope.nextTabErrorEventDetail = true;
          } else {
            $scope.showGreenTickEvent = true;
          }
        } else {
          $scope.showGreenTickEvent = true;
        }

        if (!$scope.primaryActivity) {
          $scope.showGreenTickEvent = false;
          $scope.showRedTickEvent = true;
          $scope.nextTabErrorEventDetail = true;
        }

        if ($scope.showGreenTickEvent) {
          if (($.inArray('event_skiAbility[]', $scope.errors) > CONST_HELPER.MINUS_ONE) || ($scope.package_skiier_abilities.length === CONST_HELPER.ZERO)) {
            if ($scope.package_skiier_abilities.length === CONST_HELPER.ZERO) {
              $scope.showGreenTickEvent = false;
              $scope.showRedTickEvent = true;
              $scope.nextTabErrorEventDetail = true;
            } else {
              $scope.showGreenTickEvent = true;
            }
          } else {
            $scope.showGreenTickEvent = true;
          }
          if ($scope.showGreenTickEvent) {
            if (($.inArray('event_category[]', $scope.errors) > CONST_HELPER.MINUS_ONE) || ($scope.package_sports.length === CONST_HELPER.ZERO)) {
              if ($scope.package_sports.length === CONST_HELPER.ZERO) {
                $scope.showGreenTickEvent = false;
                $scope.showRedTickEvent = true;
                $scope.nextTabErrorEventDetail = true;
              } else {
                $scope.showGreenTickEvent = true;
              }
            } else {
              $scope.showGreenTickEvent = true;
            }
          }
          if ($scope.showGreenTickEvent) {
            if (($.inArray('event_inclusions[]', $scope.errors) > CONST_HELPER.MINUS_ONE) || $scope.packageCreation.event_inclusions.$invalid) {
              $scope.showGreenTickEvent = false;
              $scope.showRedTickEvent = true;
              $scope.nextTabErrorEventDetail = true;
            } else {
              $scope.showGreenTickEvent = true;
            }
          }
          if ($scope.showGreenTickEvent) {
            if (($.inArray('event_packinglist[]', $scope.errors) > CONST_HELPER.MINUS_ONE) || $scope.packageCreation.event_packinglist.$invalid) {
              $scope.showGreenTickEvent = false;
              $scope.showRedTickEvent = true;
              $scope.nextTabErrorEventDetail = true;
            } else {
              $scope.showGreenTickEvent = true;
            }
          }
        }
        if ($scope.showGreenTickEvent) {
          $scope.showRedTickEvent = false;
        }
      }
      function checkForGreenTicksLodging () {
        /* Change View */
        if ($scope.newPackage.booking_type == CONST_HELPER.TWO) {
          // set max seats and available seats
          if ($scope.nightly_slot_lodgings && $scope.nightly_slot_lodgings[CONST_HELPER.ZERO] && $scope.nightly_slot_lodgings[CONST_HELPER.ZERO].calendar && $scope.nightly_slot_lodgings[CONST_HELPER.ZERO].calendar.length > CONST_HELPER.ZERO) {
            $scope.updateBookingWindow(false, false, false);
            $scope.setEvents();
          } else {
            $scope.renderCalender();
          }
        }

        if (($.inArray('lodging_status', $scope.errors) > CONST_HELPER.MINUS_ONE) || ($scope.newPackage.lodging_included == 1 && $scope.packageCreation.lodging_status)) {
          if ($scope.packageCreation.lodging_status.$invalid) {
            $scope.showGreenTickLodging = false;
            $scope.showRedTickLodging = true;
            $scope.nextTabErrorLodging = true;
          } else {
            $scope.showGreenTickLodging = true;
          }
        } else {
          if (parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_SEAT || parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
            if ($scope.newPackage.lodging_included == 1 && !$scope.newPackage.lodgingDetail.length && parseInt($scope.newPackage.event_lodging_status) === CONST_HELPER.EVENT_LODGING.YES) {
              $scope.showGreenTickLodging = false;
              $scope.showRedTickLodging = true;
              $scope.nextTabErrorLodging = true;
            } else {
              $scope.showGreenTickLodging = true;
            }
          } else if (parseInt($scope.package_case) === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
            $scope.showGreenTickLodging = true;
          }
        }
        $scope.lodgingDeleteError = false;
        if ($scope.showGreenTickLodging) {
          $scope.showRedTickLodging = false;
        }
      }
      function checkForGreenTicksDuration () {
        tempArr = [];
        if ($scope.newPackage.booking_type == CONST_HELPER.ONE) {
          //  slot booking case
          if (($.inArray('duration_Day', $scope.errors) > CONST_HELPER.MINUS_ONE) || $scope.packageCreation.duration_Day.$invalid) {
            if ($scope.packageCreation.duration_Day.$invalid) {
              $scope.showGreenTickDuration = false;
              $scope.showRedTickDuration = true;
              $scope.nextTabErrorDuration = true;
              tempArr.push(false);
            } else {
              $scope.showGreenTickDuration = true;
            }
          } else {
            $scope.showGreenTickDuration = true;
          }

          if ($scope.showGreenTickDuration) {
            if ($scope.newPackage.package_slots && $scope.newPackage.package_slots.length === CONST_HELPER.ZERO || !$scope.hasActiveSlots) {
              $scope.showGreenTickDuration = false;
              $scope.showRedTickDuration = true;
              $scope.nextTabErrorDuration = true;
              tempArr.push(false);
            } else {
              $scope.showGreenTickDuration = true;
            }
          }
        } else {
          //  nightly booking case
          // Booking window
          if ((!$scope.newPackage.window_id && $scope.newPackage.window_id != CONST_HELPER.ZERO) || ($scope.newPackage.window_id == CONST_HELPER.ZERO && !$scope.bookingWindowRange)) {
            $scope.showGreenTickDuration = false;
            $scope.showRedTickDuration = true;
            $scope.nextTabErrorDuration = true;
            tempArr.push(false);
          } else if ($scope.newPackage.window_id != CONST_HELPER.ZERO && !$scope.bookingWindowRange) {
            $scope.showGreenTickDuration = false;
            $scope.showRedTickDuration = true;
            $scope.nextTabErrorDuration = true;
            tempArr.push(false);
          } else {
            $scope.showGreenTickDuration = true;
          }

          // advance_notice_id
          if (!$scope.newPackage.advance_notice_id && $scope.newPackage.advance_notice_id != 0) {
            $scope.showGreenTickDuration = false;
            $scope.showRedTickDuration = true;
            $scope.nextTabErrorDuration = true;
            tempArr.push(false);
          } else {
            $scope.showGreenTickDuration = true;
          }

          // Room
          if ($scope.package_case != CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && $scope.newPackage.event_lodging_status == 1 && !$scope.newPackage.lodge_id) {
            $scope.showGreenTickDuration = false;
            $scope.showRedTickDuration = true;
            $scope.nextTabErrorDuration = true;
            tempArr.push(false);
          } else {
            $scope.showGreenTickDuration = true;
          }

          // available seats
          if ($scope.package_case != CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && $scope.newPackage.event_lodging_status == 1 && !$scope.newPackage.available_room || $scope.newPackage.available_room < CONST_HELPER.ONE) {
            $scope.showGreenTickDuration = false;
            $scope.showRedTickDuration = true;
            $scope.nextTabErrorDuration = true;
            tempArr.push(false);
          } else {
            $scope.showGreenTickDuration = true;
          }

          // BASE PRICE IN PACKAGE TYPE C IS MUST
          if ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && (!$scope.newPackage.base_price || $scope.newPackage.base_price < CONST_HELPER.ONE)) {
            $scope.showGreenTickDuration = false;
            $scope.showRedTickDuration = true;
            $scope.nextTabErrorDuration = true;
            tempArr.push(false);
          } else {
            $scope.showGreenTickDuration = true;
          }

          // max_seat_available
          if ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && (!$scope.newPackage.max_seat_available || $scope.newPackage.max_seat_available < CONST_HELPER.ONE || ($scope.newPackage.event_lodging_status == CONST_HELPER.ONE && $scope.newPackage.max_seat_available > $scope.getTotalNoOfBedsForDuration()))) {
            $scope.showGreenTickDuration = false;
            $scope.showRedTickDuration = true;
            $scope.nextTabErrorDuration = true;
            tempArr.push(false);
          } else {
            $scope.showGreenTickDuration = true;
          }

          // BASE_PRICE
          if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO) && (!$scope.newPackage.base_price || $scope.newPackage.base_price < CONST_HELPER.ONE)) {
            $scope.showGreenTickDuration = false;
            $scope.showRedTickDuration = true;
            $scope.nextTabErrorDuration = true;
            tempArr.push(false);
          } else {
            $scope.showGreenTickDuration = true;
          }
        }

        if (tempArr.includes(false)) {
          $scope.showGreenTickDuration = false;
          $scope.showRedTickDuration = true;
          $scope.nextTabErrorDuration = true;
        }

        if ($scope.showGreenTickDuration) {
          $scope.showRedTickDuration = false;
        }
      }
      function checkForGreenTicksItinerary () {
        if (($.inArray('itinerary_arrival_instruction', $scope.errors) > CONST_HELPER.MINUS_ONE) || $scope.packageCreation.itinerary_arrival_instruction.$invalid) {
          if ($scope.packageCreation.itinerary_arrival_instruction.$invalid) {
            $scope.showGreenTickItinerary = false;
            $scope.showRedTickItinerary = true;
            $scope.nextTabErrorItinerary = true;
          } else {
            $scope.showGreenTickItinerary = true;
          }
        } else {
          $scope.showGreenTickItinerary = true;
        }

        if ($scope.showGreenTickItinerary) {
          if (($.inArray('itinerary_depature_instruction', $scope.errors) > CONST_HELPER.MINUS_ONE) || $scope.packageCreation.itinerary_depature_instruction.$invalid) {
            if ($scope.packageCreation.itinerary_depature_instruction.$invalid) {
              $scope.showGreenTickItinerary = false;
              $scope.showRedTickItinerary = true;
              $scope.nextTabErrorItinerary = true;
            } else {
              $scope.showGreenTickItinerary = true;
            }
          } else {
            $scope.showGreenTickItinerary = true;
          }
        }

        if ($scope.showGreenTickItinerary) {
          if (($.inArray('itinerary_day_description[]', $scope.errors) > CONST_HELPER.MINUS_ONE) || $scope.newPackage.itinerary_day_description.length !== CONST_HELPER.ZERO) {
            for (var i = CONST_HELPER.ZERO; i < $scope.newPackage.itinerary_day_description.length; i++) {
              var obj = $scope.newPackage.itinerary_day_description[i];
              if (!obj.day_description || obj.day_description === '' || obj.time_period === '' || !obj.time_period) {
                $scope.showGreenTickItinerary = false;
                $scope.showRedTickItinerary = true;
                $scope.nextTabErrorItinerary = true;
                $scope.timePeriodError = true;
                break;
              } else {
                $scope.showGreenTickItinerary = true;
                $scope.timePeriodError = false;
              }
            }
          } else {
            $scope.showGreenTickItinerary = true;
          }
        }

        if ($scope.showGreenTickItinerary) {
          $scope.showRedTickItinerary = false;
        }
      }
      function checkForGreenTicksGallery () {
        if ($scope.featured_image) {
          $scope.showGreenTickGallery = true;
          return false;
        }
        if (($.inArray('gallery_croppedImage', $scope.errors) > CONST_HELPER.MINUS_ONE) || $scope.packageCreation.gallery_croppedImage.$invalid || ($scope.gallery.croppedImage && ($scope.minSingleImgSize || $scope.maxSingleImgSize || $scope.invalidFormatImg))) {
          if ($scope.packageCreation.gallery_croppedImage.$invalid || ($scope.gallery.croppedImage && ($scope.minSingleImgSize || $scope.maxSingleImgSize || $scope.invalidFormatImg))) {
            $scope.showGreenTickGallery = false;
            $scope.showRedTickGallery = true;
            $scope.nextTabErrorGallery = true;
          } else {
            $scope.showGreenTickGallery = true;
          }
        } else {
          $scope.showGreenTickGallery = true;
        }
        if ($scope.showGreenTickGallery) {
          $scope.showRedTickGallery = false;
        }
        $timeout(function () { //
          $('.selectpicker').selectpicker(); // initialize selectpicker...
        });
      }
      function checkForGreenTicksAmenities () {
        if (($.inArray('amenities_package_list[]', $scope.errors) > CONST_HELPER.MINUS_ONE) || ($scope.amenities_package_list.length === CONST_HELPER.ZERO)) {
          if ($scope.amenities_package_list.length === CONST_HELPER.ZERO) {
            $scope.showGreenTickAmenities = false;
            $scope.showRedTickAmenities = true;
            $scope.nextTabErrorAmenities = true;
          } else {
            $scope.showGreenTickAmenities = true;
          }
        } else {
          $scope.showGreenTickAmenities = true;
        }
        if ($scope.showGreenTickAmenities) {
          $scope.showRedTickAmenities = false;
        }
      }

      function checkForGreenTicksBookingInformation () {
        if (!$scope.cancelPolicyDetail[CONST_HELPER.ZERO].min_num_cancellation_days || $scope.cancelPolicyDetail[CONST_HELPER.ZERO].min_num_cancellation_days < CONST_HELPER.ONE) {
          $scope.showGreenTickBookingInformation = false;
          $scope.showRedTickBookingInformation = true;
        } else {
          $scope.showGreenTickBookingInformation = true;
          $scope.showRedTickBookingInformation = false;
        }
        if (!$scope.cancelPolicyDetail[CONST_HELPER.ZERO].cancellation_description || $scope.depositErr || $scope.showAmountPercErr || ($scope.newPackage.is_partial && $scope.showAmountPercErrZero)) {
          $scope.showGreenTickBookingInformation = false;
          $scope.showRedTickBookingInformation = true;
        } else {
          $scope.showGreenTickBookingInformation = true;
          $scope.showRedTickBookingInformation = false;
        }
      }

      function checkForGreenTicks () {
        if ($scope.currentHash === 'lodging') {
          checkForGreenTicksEvent();
        } else if ($scope.currentHash === 'duration') {
          checkForGreenTicksLodging();
        } else if ($scope.currentHash === 'itinerary') {
          checkForGreenTicksDuration();
        } else if ($scope.currentHash === 'gallery') {
          checkForGreenTicksItinerary();
        } else if ($scope.currentHash === 'amenities') {
          checkForGreenTicksGallery();
        } else if ($scope.currentHash === 'cancellationPolicy') {
          checkForGreenTicksAmenities();
        } else if ($scope.currentHash === 'taxesandfee') {
          checkForGreenTicksBookingInformation();
        }
      }

      /* Cancel Policy Functionality Start Here */
      $scope.checkCancelDurationDay = function (value) { // check number of days for cancallation policy page
        if (value < CONST_HELPER.ONE) { // check for less then CONST_HELPER.ZERO value
          $scope.showCancelZeroDateErr = true; // show error message
        }
      };

      $scope.depositCheckbox = function (checked) {
        if (checked) {
          $scope.cancelPolicyDetail[CONST_HELPER.ZERO].allow_refund_balance = CONST_HELPER.ONE;
        }
      };

      /* Cancel Policy Functionality End Here */
      // function for set package deposit type for package
      $scope.setAmountPerc = function (value, deposit_type) { //
        // var commission = CONST_HELPER.HUNDRED - $scope.heliCommission

        switch (deposit_type) {
          case CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE: // if deposit type is percentage
            var percentage = parseInt(value);
            if (percentage > CONST_HELPER.HUNDRED) {
              $scope.showAmountPercErr = true;
            } else {
              $scope.showAmountPercErr = false;
            }
            if (percentage.length === CONST_HELPER.ZERO || $scope.newPackage.amount_percentage === CONST_HELPER.ZERO) {
              $scope.showAmountPercErrZero = true;
            } else {
              $scope.showAmountPercErrZero = false;
            }
            // check if deposit % is greater than (100 - slot_maximum_commission)...
            $scope.slotMaxCommission = $scope.newPackage.booking_type === CONST_HELPER.ONE ? returnMaxMinSlotCommission($scope.newPackage.package_slots, 'commission', 'maximum') : $scope.user.operator_commissions;
            if (parseFloat($scope.newPackage.amount_percentage) > (CONST_HELPER.HUNDRED - $scope.slotMaxCommission)) {
              $scope.depositErr = true;
            } else {
              $scope.depositErr = false;
            }
            break;
          case CONST_HELPER.DEPOSIT_TYPE.AMOUNT: // if deposit type is amount
            var amount = parseFloat(value);
            // check if deposit amount is greater than slot_minimum_amount)...
            if ($scope.newPackage.booking_type === CONST_HELPER.ONE) {
              if (!$scope.newPackage.package_slots.length) {
                return;
              }
              getSlotMinDepositAmount($scope.newPackage.package_slots, function (package_slots) {
                $scope.slotMinDepositAmount = returnMaxMinSlotCommission(package_slots, 'minimumDepositAmount', 'minimum');
              });
            } else {
              $scope.slotMinDepositAmount = getLodgingMinPrice(); // get the nightly lodging minimum price...
              $scope.slotMinDepositAmount = $scope.slotMinDepositAmount - $scope.slotMinDepositAmount * $scope.heliCommission / CONST_HELPER.HUNDRED;
            }
            if (amount > $scope.slotMinDepositAmount) {
              $scope.depositErr = true;
            } else {
              $scope.depositErr = false;
            }
            break;
        }
      };
      $scope.changeDepositType = function () {
        $scope.showAmountPercErr = false;
        $scope.depositErr = false;
        $('#amountPercentage').focus(); // SET FOCUS ON VALUE TEXTBOX IF DEPOSITE TYPE IS CHANGED

        if ($scope.newPackage.deposit_type == CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE) {
          $scope.newPackage.is_collect_percentage_taxes_on_the_deposit = 1;
        }
      };

      $scope.updateOtherLodge = function (start, end, val, prop, cEvent) {
        $scope.updAvlSt.push({
          start: start,
          end: end,
          val: val,
          prop: prop
        });
        $scope.nightly_slot_lodgings.forEach(function (lodging) {
          lodging.calendar.forEach(function (event) {
            if (moment(event.date).isSame(start) || moment(event.date).isSame(end) || moment(event.date).isBetween(start, end)) {
              event[prop] = val;
              event['max_capacity'] = val;
              event['is_block'] = cEvent.is_block;
            }
          });
        });
      };
      // function to update the updated dates in other lodgings as well...
      function updateLodgingRangeData () {
        $scope.newPackage.lodgingDetail ? $scope.newPackage.lodgingDetail.map(function (lodge) {
          lodge.updated_dates = $scope.rangesData;
        }) : null;
        $scope.nightly_slot_lodgings ? $scope.nightly_slot_lodgings.map(function (sl) {
          sl.updated_dates = $scope.rangesData;
        }) : null;
      }

      $scope.getTotalNoOfBedsForDuration = function () {
        var totalCount = 0;
        if ($scope.newPackage.booking_type == CONST_HELPER.TWO && $scope.newPackage.lodgingDetail && $scope.newPackage.lodgingDetail.length > 0 && $scope.nightly_slot_lodgings && $scope.nightly_slot_lodgings.length > 0) {
          //
          $scope.newPackage.lodgingDetail.forEach(function (lodge) {
            var findLodgingObject = $scope.nightly_slot_lodgings.find(function (lodging) {
              return lodging.lodging_id == lodge.id;
            });
            totalCount = totalCount + lodge.max_guests * (findLodgingObject && findLodgingObject.available_room ? findLodgingObject.available_room : 1);
          });
        }
        return totalCount;
      };

      // allow/not-allow deposit option
      $scope.depositOption = function () {
        $scope.newPackage.amount_percentage = '';
        $scope.setAmountPerc($scope.newPackage.amount_percentage, $scope.newPackage.deposit_type);
        if ($scope.newPackage.is_partial) {
          $('#amountPercentage').focus();
        } else {
          $('#amountPercentage').blur();
        }
      };

      function viewRender (view, element) {
        var arr = angular.copy($scope.events);
        renderEvents(arr);
      }

      $scope.initilizeArray = function () {
        if ($scope.newPackage.booking_type == CONST_HELPER.TWO) {
          if ($scope.nightly_slot_lodgings && $scope.nightly_slot_lodgings.length > CONST_HELPER.ZERO) {
            $scope.newPackage.lodgingDetail.forEach(function (lodge) {
              var find = $scope.nightly_slot_lodgings.find(function (l) {
                return lodge.id == l.lodging_id;
              });
              if (!find) {
                $scope.nightly_slot_lodgings.push({
                  lodging_id: lodge.id,
                  base_price: lodge.price,
                  price: lodge.price,
                  private_price: lodge.private_price,
                  available_room: $scope.newPackage.available_room,
                  available_seat: $scope.newPackage.max_seat_available,
                  calendar: []
                });
              };
            });
          } else {
            $scope.newPackage.lodgingDetail.forEach(function (lodge) {
              $scope.nightly_slot_lodgings = [];
              $scope.nightly_slot_lodgings.push({
                lodging_id: lodge.id,
                base_price: lodge.price,
                price: lodge.price,
                private_price: lodge.private_price,
                available_room: $scope.newPackage.available_room,
                available_seat: $scope.newPackage.max_seat_available,
                calendar: []
              });
            });
          }
        }
      };

      function renderEvents (events) {
        $scope.events.splice(CONST_HELPER.ZERO); // remove previous events...
        angular.forEach(events, function (e) {
          // push slot event data...
          $scope.events.push({
            title: e.title,
            start: e.start,
            end: e.end
            // className: addClass(e.available_percentage, e.expired),
            // slot: e
          });
        });
        // $scope.ifCalendarLoading = false;
      }
      $scope.eventSources = [$scope.events];
      // return Maximum/Minimum slot prop data..
      function returnMaxMinSlotCommission (arr, prop, type) {
        var propData = {};
        for (var i = CONST_HELPER.ZERO; i < arr.length; i++) {
          switch (type) {
            case 'maximum': // get maximun value of given property
              if (!propData[prop] || parseInt(arr[i][prop]) > parseInt(propData[prop])) {
                propData[prop] = arr[i][prop];
              }
              break;
            case 'minimum': // get minimun value of given property
              // if property is pricate_price ignore CONST_HELPER.ZERO value...
              if (!(prop === 'private_price' && (!arr[i][prop] || arr[i][prop] === null || parseInt(arr[i][prop]) <= CONST_HELPER.ZERO))) {
                if (!propData[prop] || parseInt(arr[i][prop]) < parseInt(propData[prop])) {
                  propData[prop] = arr[i][prop];
                  $scope.minSlotAmountCommission = arr[i]['commission'];
                }
              }
              break;
          }
        }
        return propData[prop];
      }

      // function to get the minimum deposit amount for different package type...
      function getSlotMinDepositAmount (slotArray, callback) {
        if (!slotArray || !slotArray.length) {
          return;
        }
        slotArray.forEach(function (slot, key) {
          if (slot.is_expired) {
            return;
          }

          var slot_amount = CONST_HELPER.ZERO;
          if (slot.slot_lodgings && slot.slot_lodgings.length) {
            var min_private_price = returnMaxMinSlotCommission(slot.slot_lodgings, 'private_price', 'minimum');
            var min_price = returnMaxMinSlotCommission(slot.slot_lodgings, 'price', 'minimum');

            // if slot private price is available, then compare price and private_price otherwise not...
            slot_amount = min_private_price ? Math.min(min_price, min_private_price) : min_price;
          } else {
            slot_amount = slot.adult_price;
          }
          slot.minimumDepositAmount = slot_amount - (slot_amount * slot.commission / CONST_HELPER.HUNDRED);
        });
        callback(slotArray);
      }

      // function to get the minimum deposit amount for different package type...
      function getLodgingMinPrice () {
        if ($scope.newPackage.lodgingDetail && $scope.newPackage.lodgingDetail.length) {
          var minimumPrice = CONST_HELPER.ZERO;
          $scope.newPackage.lodgingDetail.map(function (val) {
            var minLodgingPrice = 0;
            if (val.private_price) {
              minLodgingPrice = Math.min(val.price, val.private_price);
            } else {
              minLodgingPrice = val.price;
            }
            if (minimumPrice === CONST_HELPER.ZERO || minimumPrice > minLodgingPrice) {
              minimumPrice = minLodgingPrice;
            }
          });
          return minimumPrice;
        } else {
          // return base price;
          return $scope.newPackage.base_price || 0;
        }
      }

      // set is_private key with lodging in case of private price changes
      $scope.setIsprivateKey = function (lodging) {
        lodging.is_private = true;
      };

      // DISCOUNT METHODS

      $scope.openDiscount = function (discount, openIndex) {
        $scope.editDiscountFlag = !!discount;
        $scope.openDiscountIndex = openIndex;
        $scope.hideDiscountError();
        $scope.showPopup('#addDiscountModal');
        $scope.dmDiscountData = JSON.parse(JSON.stringify(discount || {
          discount_type: CONST_HELPER.DISCOUNT_TYPE.IN_PERCENTAGE,
          minimum_no_days: CONST_HELPER.ONE,
          discount_value: CONST_HELPER.ONE
        }));
      };

      $scope.closeDiscountPopup = function () {
        $scope.hidePopup('#addDiscountModal');
      };

      $scope.closeCalendarPopup = function () {
        $scope.hidePopup('#updateCalendarEvent');
      };

      $scope.updateCalendarEventNew = function (cEvent) {
        if (!$scope.validateDate()) {
          // Invalid Dates Selected
          return;
        };
        if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ONE) && cEvent.is_block == CONST_HELPER.ZERO && (!cEvent.available_room || !cEvent.available_seat || !cEvent.price || cEvent.available_room <= CONST_HELPER.ZERO || cEvent.available_seat <= CONST_HELPER.ZERO || cEvent.price <= CONST_HELPER.ZERO)) {
          // Invalid SEATS
          return;
        }

        if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO) && cEvent.is_block == CONST_HELPER.ZERO && (!cEvent.available_seat || !cEvent.price || cEvent.available_seat <= CONST_HELPER.ZERO || cEvent.price <= CONST_HELPER.ZERO)) {
          // Invalid SEATS
          return;
        }

        if ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && cEvent.is_block == CONST_HELPER.ZERO && (!cEvent.max_capacity || !cEvent.price || cEvent.max_capacity <= CONST_HELPER.ZERO || cEvent.price <= CONST_HELPER.ZERO)) {
          // Invalid SEATS
          return;
        }

        // private price validation
        if (cEvent.private_slot_available && (!cEvent.private_price || cEvent.price > cEvent.private_price)) {
          return;
        }

        $scope.showLoader();
        // if Events already present in nightlyBooking.
        var nightBooking = {};
        delete cEvent.source;
        var _tempObjEvent = JSON.parse(JSON.stringify(cEvent));
        if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) && !$scope.newPackage.lodge_id) {
          // CASE A NO LOdging
          nightBooking = $scope.nightly_slot_lodgings[0];
        } else if ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && $scope.newPackage.lodge_id) {
          // CASE C NO LODGING BUT LODGE ID YES
          nightBooking = $scope.nightly_slot_lodgings[0];
        } else if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ONE || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION)) {
          // CASE A LOdging YES
          nightBooking = $scope.nightly_slot_lodgings.find(function (lodge) {
            return lodge.lodging_id == $scope.newPackage.lodge_id;
          });
        }

        var event = nightBooking ? nightBooking.calendar : [];
        var lodgeObject = {};

        if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) && !$scope.newPackage.lodge_id) {
          // CASE A NO LODGING
          lodgeObject = {
            id: CONST_HELPER.ZERO,
            price: $scope.newPackage.base_price,
            max_seat_available: $scope.newPackage.max_seat_available,
            max_capacity: $scope.newPackage.max_seat_available
          };
        } else if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ONE || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION)) {
          $scope.newPackage.lodge_id = $scope.newPackage.lodge_id || $scope.newPackage.lodgingDetail[CONST_HELPER.ZERO].id;
          // Find Lodging Object
          lodgeObject = $scope.newPackage.lodgingDetail.find(function (lodge) {
            return lodge.id == $scope.newPackage.lodge_id;
          });
          // We Will only update events that belong to current lodging and not the other one.
          _tempObjEvent.lodge_id = $scope.newPackage.lodge_id;
        }
        $scope.rangesData = [];
        $scope.rangesData.unshift(_tempObjEvent);
        if (_tempObjEvent.is_block || _tempObjEvent.available_seat != $scope.newPackage.max_seat_available) {
          $scope.newPackage.lodgingDetail.map(function (lodge, i) {
            if (lodge.id != $scope.newPackage.lodge_id) {
              var copiedEvent = angular.copy(_tempObjEvent);
              copiedEvent.lodge_id = lodge.id;
              copiedEvent.price = lodge.price;
              copiedEvent.available_room = $scope.nightly_slot_lodgings[i].available_room;
              copiedEvent.private_price = lodge.private_price;
              $scope.rangesData.unshift(copiedEvent);
            }
          });
        }
        nightBooking.updated_dates = $scope.rangesData;
        // update the updated dates in other lodgings as well...
        updateLodgingRangeData();
        if ($scope.package_case != CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
          // Update Available Seat for other lodging.
          // $scope.updateOtherLodge(cEvent.start, cEvent.end, cEvent.available_seat, 'available_seat', cEvent);
        }
        setTimeout(function () {
          var noOfYears = CONST_HELPER.TWO;
          if ($scope.newPackage.window_id == CONST_HELPER.ZERO) {
            noOfYears = CONST_HELPER.ZERO;
            updateCustomDateEvents(event, cEvent, lodgeObject);
          } else {
            // if not in custom date.
            for (var m = moment($scope.bookingWindowRange.startDate); m.isSameOrBefore(moment($scope.bookingWindowRange.endDate).add(noOfYears, 'year')); m.add(CONST_HELPER.ONE, 'days')) {
              for (var i = CONST_HELPER.ZERO; i < $scope.rangesData.length; i++) {
                var found = false;
                if (m.isSame($scope.rangesData[i].start) || m.isBetween($scope.rangesData[i].start, $scope.rangesData[i].end) || m.isSame($scope.rangesData[i].end)) {
                  // We Will only update events that belong to current lodging and not the other one.
                  if ($scope.rangesData[i].lodge_id && $scope.rangesData[i].lodge_id != $scope.newPackage.lodge_id) {
                    // testing this.
                  } else {
                    var n = null;
                    found = event.find(function (obj, index) {
                      n = index;
                      return moment(obj.date).format('YYYY-MM-DD') == m.format('YYYY-MM-DD');
                    });
                    if (found) {
                      var isDateAfterEndDate = moment(m).isAfter($scope.bookingWindowRange.endDate);
                      event[n] = {
                        'date': m.format('YYYY-MM-DD'),
                        'price': $scope.rangesData[i].price,
                        'private_price': cEvent.private_price,
                        'available_room': $scope.rangesData[i].available_room || $scope.newPackage.available_room,
                        'available_seat': $scope.rangesData[i].available_seat,
                        'is_block': $scope.rangesData[i].is_block,
                        forceActive: $scope.rangesData[i].is_block ? 0 : 1,
                        'total_bed': lodgeObject ? lodgeObject.max_guests * $scope.rangesData[i].available_room : CONST_HELPER.ZERO,
                        title: $filter('currency')($scope.rangesData[i].price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO),
                        start: m.format('MM/DD/YYYY'),
                        end: m.format('MM/DD/YYYY'),
                        allDay: true,
                        stick: true,
                        max_capacity: cEvent.date == found.date ? cEvent.max_capacity : found.max_capacity,
                        textColor: $scope.rangesData[i].is_block ? '#bdbdbd' : (parseInt(lodgeObject.price) != parseInt($scope.rangesData[i].price) ? '#4a90e2' : '#787878'),
                        className: ['myTitle'],
                        lodge_id: $scope.rangesData[i].lodge_id
                      };
                      cEvent.forceActive = event[n].forceActive;
                      if (isDateAfterEndDate) {
                        event[n].isAfterBookingRange = isDateAfterEndDate;
                      } else {
                        delete event[n].isAfterBookingRange;
                      }
                    } else {
                      var isDateAfterEndDate = moment(m).isAfter($scope.bookingWindowRange.endDate);
                      var obj = {
                        'date': m.format('YYYY-MM-DD'),
                        'price': cEvent.price,
                        'private_price': cEvent.private_price,
                        'available_room': cEvent.available_room || $scope.newPackage.available_room,
                        'available_seat': cEvent.available_seat,
                        'is_block': cEvent.is_block,
                        forceActive: cEvent.is_block ? 0 : 1,
                        'total_bed': lodgeObject ? lodgeObject.max_guests * cEvent.available_room : CONST_HELPER.ZERO,
                        title: $filter('currency')(cEvent.price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO),
                        start: m.format('MM/DD/YYYY'),
                        end: m.format('MM/DD/YYYY'),
                        allDay: true,
                        stick: true,
                        max_capacity: cEvent.max_capacity,
                        textColor: cEvent.is_block ? '#bdbdbd' : (parseInt(lodgeObject.price) != parseInt(cEvent.price) ? '#4a90e2' : '#787878'),
                        className: ['myTitle'],
                        lodge_id: $scope.rangesData[i].lodge_id
                      };
                      // After 2 years
                      if (isDateAfterEndDate) {
                        obj.isAfterBookingRange = isDateAfterEndDate;
                      } else {
                        delete obj.isAfterBookingRange;
                      }
                      event.push(obj);
                    }
                  }
                } else {
                  found = event.find(function (obj) {
                    return moment(obj.date).format('YYYY-MM-DD') == m.format('YYYY-MM-DD');
                  });
                  if (!found) {
                    var isDateAfterEndDate = moment(m).isAfter($scope.bookingWindowRange.endDate);
                    var obj = {
                      'date': m.format('YYYY-MM-DD'),
                      'price': lodgeObject.price,
                      'private_price': cEvent.private_price,
                      'available_room': $scope.newPackage.available_room,
                      'available_seat': $scope.newPackage.max_seat_available,
                      'is_block': isDateAfterEndDate ? CONST_HELPER.ONE : CONST_HELPER.ZERO,
                      forceActive: isDateAfterEndDate ? CONST_HELPER.ZERO : CONST_HELPER.ONE,
                      'total_bed': lodgeObject ? lodgeObject.max_guests * cEvent.available_room : CONST_HELPER.ZERO,
                      title: $filter('currency')(lodgeObject.price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO),
                      start: m.format('MM/DD/YYYY'),
                      end: m.format('MM/DD/YYYY'),
                      allDay: true,
                      stick: true,
                      max_capacity: cEvent.max_capacity,
                      textColor: '#787878',
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                      className: ['myTitle'],
                      lodge_id: $scope.rangesData[i].lodge_id
                    };

                    // After 2 years
                    if (isDateAfterEndDate) {
                      obj.isAfterBookingRange = isDateAfterEndDate;
                    } else {
                      delete obj.isAfterBookingRange;
                    }
                    event.push(obj);
                  }
                }
              }
            }
          }
          setTimeout(function () {
            $scope.hideLoader();
          }, 1000);
          // Remove Previous Events

          uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('removeEvents');
          uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('renderEvents', convertToMoment(event), true);
          // uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('addEventSource', convertToMoment(event));
          setTimeout(function () {
            uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('render');
          }, 200);

          $scope.hidePopup('#updateCalendarEvent');

          createLodgingObject(event, lodgeObject);
          findEventForAllLodging([cEvent], $scope.nightly_slot_lodgings);
        }, 500);
      };

      function getMinAndMaxDateForCustomEvents (event) {
        // THIS METHOD SHOULD ONLY BE USED FOR CUSTOM EVENTS CASE.
        var minMax = {
          min: $scope.bookingWindowRange.startDate,
          max: $scope.bookingWindowRange.endDate
        };
        if ($scope.newPackage.window_id == CONST_HELPER.ZERO) {
          // event.sort(function (a, b) {
          //   return (new Date(a.date) - new Date(b.date));
          // });
          minMax = {
            min: $scope.customEventStartDate,
            max: $scope.customEventEndDate
          };
        }
        return minMax;
      }

      var updateCustomDateEvents = function (event, cEvent, lodgeObject) {
        // All Events, Current Event we want to update, lodgingObject.
        // DONT FORGET TO SORT EVENTS
        var minMax = getMinAndMaxDateForCustomEvents(event);
        for (var m = moment(minMax.min); m.isSameOrBefore(moment(minMax.max)); m.add(CONST_HELPER.ONE, 'days')) {
          for (var i = CONST_HELPER.ZERO; i < $scope.rangesData.length; i++) {
            var found = false;
            if ($scope.rangesData[i].lodge_id && $scope.rangesData[i].lodge_id != $scope.newPackage.lodge_id) {
              // testing this.
            } else {
              if (m.isSame($scope.rangesData[i].start) || m.isBetween($scope.rangesData[i].start, $scope.rangesData[i].end) || m.isSame($scope.rangesData[i].end)) {
                var n = null;
                found = event.find(function (obj, index) {
                  n = index;
                  return moment(obj.date).format('YYYY-MM-DD') == m.format('YYYY-MM-DD');
                });
                console.log('Found');
                console.log(found);
                if (found) {
                  event[n] = {
                    'date': m.format('YYYY-MM-DD'),
                    'price': cEvent.price,
                    'private_price': cEvent.private_price,
                    'available_room': cEvent.available_room || $scope.newPackage.available_room,
                    'available_seat': cEvent.available_seat,
                    'is_block': cEvent.is_block,
                    forceActive: cEvent.is_block ? 0 : 1,
                    'total_bed': lodgeObject ? lodgeObject.max_guests * cEvent.available_room : CONST_HELPER.ZERO,
                    title: $filter('currency')(cEvent.price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO),
                    start: m.format('MM/DD/YYYY'),
                    end: m.format('MM/DD/YYYY'),
                    allDay: true,
                    stick: true,
                    max_capacity: cEvent.date == found.date ? cEvent.max_capacity : found.max_capacity,
                    textColor: cEvent.is_block ? '#bdbdbd' : (parseInt(lodgeObject.price) != parseInt(cEvent.price) ? '#4a90e2' : '#787878'),
                    // backgroundColor: $scope.rangesData[i].is_block ? 'grey' : 'transparent',
                    // borderColor: 'transparent',
                    className: ['myTitle']
                  };
                }
              }
            }
          }
        }
      };

      var findEventForAllLodging = function (cEvent, lodgings, newLodgingCreated) {
        if (newLodgingCreated && lodgings && lodgings.length > 0) {
          var copyLodging = {
            lodging_id: newLodgingCreated.id,
            base_price: newLodgingCreated.price,
            price: newLodgingCreated.price,
            private_price: newLodgingCreated.private_price,
            available_room: newLodgingCreated.available_room,
            available_seat: newLodgingCreated.max_seat_available,
            max_capacity: newLodgingCreated.max_seat_available,
            calendar: JSON.parse(JSON.stringify(lodgings[0].calendar))
          };
          cEvent = copyLodging.calendar;
          copyLodging.calendar.map(function (calEvent) {
            calEvent.base_price = newLodgingCreated.price;
            calEvent.price = newLodgingCreated.price;
            calEvent.private_price = newLodgingCreated.private_price;
            calEvent.available_room = newLodgingCreated.available_room;
            calEvent.available_seat = newLodgingCreated.max_seat_available;
            calEvent.title = $filter('currency')(newLodgingCreated.price, $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO);
            return calEvent;
          });
          lodgings.push(copyLodging);
        }
        if (!newLodgingCreated && lodgings && lodgings.length > 1 && cEvent && cEvent.length > 0) {
          cEvent.forEach(function (evnt) {
            lodgings.map(function (lodge) {
              if (lodge && lodge.calendar && lodge.calendar.length > 0) {
                var clEntFnd = lodge.calendar.filter(function (calEvent) {
                  return (calEvent.date == evnt.date || moment(calEvent.date).isBetween(evnt.start, evnt.end) || moment(calEvent.start).isSame(evnt.start) || moment(calEvent.start).isSame(evnt.end));
                });
                if (clEntFnd) {
                  clEntFnd.map(function (events) {
                    events.is_block = evnt.is_block;
                    events.forceActive = evnt.forceActive;
                    return events;
                  });
                }
              }
            });
          });
        }

        if (lodgings && lodgings.length) {
          lodgings.forEach(function (lodge) {
            if (lodge.calendar && lodge.calendar.length){
              lodge.calendar.map(function (calendarEvent) {
                if (calendarEvent && !calendarEvent.available_room) {
                  calendarEvent.available_room = lodge.available_room;
                  return calendarEvent;
                }
              })
            }
          })
        }

      };

      $scope.deleteDiscountInitial = function (index) {
        $scope.deleteDiscountIndex = index;
        $scope.showPopup('#deleteDiscountModal');
      };

      $scope.cancelDiscountDelete = function () {
        $scope.hidePopup('#deleteDiscountModal');
      };

      $scope.deleteDiscount = function (index) {
        $scope.newPackage.package_discounts.splice(index, CONST_HELPER.ONE);
      };

      $scope.hideDiscountError = function () {
        $scope.showDiscountInvalidValue = false;
        $scope.showDiscountInvalidMinimumDays = false;
      };

      $scope.addDiscount = function () {
        if (($scope.dmDiscountData && ($scope.dmDiscountData.discount_value < CONST_HELPER.ONE)) || ($scope.dmDiscountData && $scope.dmDiscountData.minimum_no_days < CONST_HELPER.ONE)) {
          return false;
        }

        if ($scope.newPackage.package_discounts && $scope.newPackage.package_discounts.length > CONST_HELPER.ZERO) {
          $scope.newPackage.package_discounts.forEach(function (disc) {
            if (disc.discount_type == CONST_HELPER.DISCOUNT_TYPE.IN_PERCENTAGE) {
              $scope.addedPercentDiscount.push(disc.discount_value); // value's array
              $scope.addedPercentMinimumDays.push(disc.minimum_no_days); // minimum days array
            } else {
              $scope.addedValueDiscount.push(disc.discount_value); // value's array
              $scope.addedValueMinimumDays.push(disc.minimum_no_days); // minimum days array
            };
          });
        }
        var find = $scope.newPackage.package_discounts.find(function (discount, index) {
          if (typeof $scope.openDiscountIndex != 'undefined') {
            // for edit case/
            return discount.discount_type == $scope.dmDiscountData.discount_type && (discount.discount_value == $scope.dmDiscountData.discount_value || discount.minimum_no_days == $scope.dmDiscountData.minimum_no_days) && index != $scope.openDiscountIndex;
          } else {
            return discount.discount_type == $scope.dmDiscountData.discount_type && (discount.discount_value == $scope.dmDiscountData.discount_value || discount.minimum_no_days == $scope.dmDiscountData.minimum_no_days);
          }
        });
        if (find) {
          if (find.discount_value == $scope.dmDiscountData.discount_value) {
            $scope.showDiscountInvalidValue = true;
          } else {
            $scope.showDiscountInvalidValue = false;
          }

          if (find.minimum_no_days == $scope.dmDiscountData.minimum_no_days) {
            $scope.showDiscountInvalidMinimumDays = true;
          } else {
            $scope.showDiscountInvalidMinimumDays = false;
          }

          return false;
        }

        if ($scope.showDiscountInvalidValue || $scope.showDiscountInvalidMinimumDays) {
          return false;
        }

        if ($scope.addDiscountForm.$error && Object.keys($scope.addDiscountForm.$error).length != CONST_HELPER.ZERO) {
          return false;
        } else {
          $scope.nDiscount = $scope.dmDiscountData;
          if ($scope.editDiscountFlag) {
            $scope.newPackage.package_discounts[$scope.openDiscountIndex] = $scope.nDiscount;
          } else {
            // todo in case of add
            $scope.newPackage.package_discounts = $scope.newPackage.package_discounts ? $scope.newPackage.package_discounts : [];
            $scope.newPackage.package_discounts.push($scope.nDiscount);
          }

          $scope.hidePopup('#addDiscountModal');
        }
      };

      $scope.getMinStartDate = function () {
        $scope.singleCalendarMinDate = moment($scope.bookingWindowRange.startDate).format('MM/DD/YYYY');
        // $scope.singleCalendarEndStartDate = moment($scope.bookingWindowRange.startDate).format('mm/dd/yyyy');
        // return moment($scope.bookingWindowRange.startDate).format('mm/dd/yyyy');
      };
      $scope.getMinEndDate = function () {
        // $scope.singleCalendarMaxDate = new Date($scope.bookingWindowRange.endDate);
        // moment($scope.bookingWindowRange.endDate).format('d-MM-YYYY');
        $scope.singleCalendarMaxDate = moment($scope.bookingWindowRange.endDate).format('MM/DD/YYYY');
        // return moment($scope.bookingWindowRange.endDate).format('MM/DD/YYYY');
      };

      // Valid Start And End Date
      $scope.validateDate = function (date) {
        var noOfYears = CONST_HELPER.TWO;
        if ($scope.newPackage.window_id == CONST_HELPER.ZERO) {
          noOfYears = CONST_HELPER.ZERO;
          return true;
        }
        var tempEndDate = moment($scope.bookingWindowRange.endDate).add(noOfYears, 'year');
        if ($scope.cEvent && $scope.cEvent.start && moment($scope.cEvent.start).isSameOrAfter($scope.bookingWindowRange.startDate) && moment($scope.cEvent.start).isSameOrBefore(tempEndDate)) {
          $scope.showInvalidStartDateError = false;
        } else {
          $scope.showInvalidStartDateError = true;
        }

        if ($scope.cEvent && $scope.cEvent.end && moment($scope.cEvent.end).isSameOrAfter($scope.bookingWindowRange.startDate) && moment($scope.cEvent.end).isSameOrBefore(tempEndDate) && moment($scope.cEvent.end).isSameOrAfter($scope.cEvent.start)) {
          $scope.showInvalidEndDateError = false;
        } else {
          $scope.showInvalidEndDateError = true;
        }

        return !($scope.showInvalidEndDateError || $scope.showInvalidStartDateError);
      };

      $scope.updateAllLodging = function (prop, value) {
        var events = uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('clientEvents');
        var lodgeObject = $scope.newPackage.lodgingDetail.find(function (lodge) {
          return lodge.id == $scope.newPackage.lodge_id;
        });
        // prop == 'available_seat' && lodgeObject ? lodgeObject['max_guests'] = value : null;
        if (prop == 'available_seat' && $scope.nightly_slot_lodgings) {
          $scope.nightly_slot_lodgings.forEach(function (lodging) {
            lodging[prop] = value;
            lodging.calendar.forEach(function (event) {
              event[prop] = value;
              event['max_capacity'] = value;
            });
          });
        }
        events.map(function (event) {
          event[prop] = $scope.newPackage.max_seat_available;
          return event;
        });
        uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('removeEvents');
        uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('renderEvents', convertToMoment(events), true);
        // uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('addEventSource', convertToMoment(events));
        uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('render');
        if (($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO || $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE)) {
          lodgeObject = {
            id: CONST_HELPER.ZERO,
            price: $scope.newPackage.base_price,
            max_seat_available: $scope.newPackage.max_seat_available,
            max_capacity: $scope.newPackage.max_seat_available
          };
        };

        createLodgingObject(events, lodgeObject, prop == 'max_capacity', value);
      };

      $scope.updateEventsRooms = function (prop, value) {
        var events = uiCalendarConfig.calendars['operatorCalendar'] ? uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('clientEvents') : [];
        if (!events || events.length == CONST_HELPER.ZERO) {
          $scope.createEventsObject();
        }
        var lodgeObject = {};
        if ($scope.newPackage.event_lodging_status != CONST_HELPER.ZERO) {
          if (($scope.newPackage.lodge_id == null || $scope.newPackage.lodge_id == '') && $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
            lodgeObject = $scope.nightly_slot_lodgings[0];
          } else {
            lodgeObject = $scope.newPackage.lodgingDetail.find(function (lodge) {
              return lodge.id == $scope.newPackage.lodge_id ? $scope.newPackage.lodge_id : CONST_HELPER.ZERO;
            });
          }
        } else if ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO) {
          lodgeObject = {
            id: CONST_HELPER.ZERO,
            price: $scope.newPackage.base_price,
            max_seat_available: $scope.newPackage.max_seat_available,
            max_capacity: $scope.newPackage.max_seat_available
          };
        }
        prop == 'available_room' ? lodgeObject.available_room = value : null;
        // prop == 'available_seat' && lodgeObject ? lodgeObject['max_guests'] = value : null;
        if ((prop == 'available_seat' || prop == 'max_capacity' || prop == 'available_room') && $scope.nightly_slot_lodgings) {
          $scope.nightly_slot_lodgings.forEach(function (lodging) {
            if (prop == 'available_room') {
              if (lodging.lodging_id == $scope.newPackage.lodge_id) {
                lodging[prop] = value;
                lodging.calendar.forEach(function (event) {
                  event[prop] = value;
                  $scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION ? event['max_capacity'] = value : null;
                });
              }
            } else {
              lodging[prop] = value;
              lodging.calendar.forEach(function (event) {
                event[prop] = value;
              });
            }
          });
        }

        events.map(function (event) {
          event[prop] = value;
          prop == 'available_room' ? event['total_bed'] = (lodgeObject ? lodgeObject.max_guests * value : CONST_HELPER.ZERO) : null;
          prop == 'max_capacity' ? event['available_seat'] = value : null;
          prop == 'price' ? event['title'] = $filter('currency')(event[prop], $scope.currencySymbol[$scope.currency_code], CONST_HELPER.ZERO) : null;
          return event;
        });

        if (uiCalendarConfig.calendars['operatorCalendar'] && events && events.length > CONST_HELPER.ZERO) {
          uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('removeEvents');
          uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('renderEvents', convertToMoment(events), true);
          // uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('addEventSource', convertToMoment(events));
          uiCalendarConfig.calendars['operatorCalendar'].fullCalendar('render');
        }

        if ($scope.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.newPackage.event_lodging_status == CONST_HELPER.ZERO) {
          lodgeObject = {
            id: CONST_HELPER.ZERO,
            price: $scope.newPackage.base_price,
            max_seat_available: $scope.newPackage.max_seat_available,
            max_capacity: $scope.newPackage.max_seat_available
          };
        };
        createLodgingObject(events, lodgeObject);
        $scope.setAmountPerc($scope.newPackage.amount_percentage, $scope.newPackage.deposit_type);
      };

      // GUEST FORM METHODS
      $scope.openAddQuestionsPopup = function () {
        $scope.questionData = {
          type: '',
          questionText: ''
        };
        $scope.showPopup('#add-questions-modal');
      };

      $scope.changeQuestionType = function () {
        if ($scope.questionData.type > 3) {
          $scope.questionData.options = [{
            label: ''
          }];
        }
      };

      $scope.addMoreOptions = function () {
        $scope.questionData.options.push({
          label: ''
        });
      };

      $scope.clearAddForm = function () {
        $scope.questionData = {
          type: '',
          questionText: ''
        };
        $scope.allQuestions = [];
        $scope.formData = {
          name: ''
        };
      };

      $scope.deleteOption = function (index) {
        $scope.questionData.options.splice(index, 1);
      };

      $scope.editQuestion = function (index) {
        $scope.questionData = $scope.allQuestions[index];
        $scope.showPopup('#add-questions-modal');
      };

      $scope.closeAddQuestionPopup = function () {
        $scope.hidePopup('#add-questions-modal');
      };

      $scope.deleteQuestionConfirmation = function (index) {
        $scope.selectedQuestionDelete = index;
        $scope.showPopup('#delete-questions-modal');
      };

      $scope.deleteQuestion = function (index) {
        $scope.allQuestions.splice($scope.selectedQuestionDelete, CONST_HELPER.ONE);
        $scope.hidePopup('#delete-questions-modal');
      };

      $scope.closeDeleteQuestion = function () {
        $scope.hidePopup('#delete-questions-modal');
      };

      $scope.saveQuestion = function (saveQuestion) {
        // QUESTION TYPE AND QUESTION TEXT
        if ($scope.questionData && (!$scope.questionData.type || !$scope.questionData.questionText || $scope.questionData.questionText == '')) {
          $scope.showQuestionError = true;
        } else {
          $scope.showQuestionError = false;
        }

        if ($scope.questionData && $scope.questionData.type > CONST_HELPER.THREE) {
          // ONLY CHECK FOR MULTIPLE OPTIONS AND DROPDOWN
          var temp_arr = [];
          if ($scope.questionData.options && $scope.questionData.options.length != CONST_HELPER.ZERO) {
            $scope.questionData.options.forEach(function (option) {
              temp_arr.push(option.label == '');
            });

            $scope.showOptionsError = temp_arr.indexOf(true) != CONST_HELPER.MINUS_ONE;
          } else if ($scope.questionData.options && $scope.questionData.options.length == CONST_HELPER.ZERO) {
            $scope.showOptionsError = true;
          }
        } else {
          $scope.showOptionsError = false;
        }

        // NOT FOR STATEMENT
        if ($scope.questionData && $scope.questionData.type != CONST_HELPER.THREE) {
          if (typeof $scope.questionData.optional == 'undefined') {
            $scope.showOptionalError = true;
          } else {
            $scope.showOptionalError = false;
          }

          if ($scope.questionData && $scope.questionData.type <= CONST_HELPER.TWO && (!$scope.questionData.maxlength || typeof $scope.questionData.maxlength == 'undefined' || $scope.questionData.maxlength < CONST_HELPER.ONE)) {
            $scope.showMaxLengthError = true;
          } else {
            $scope.showMaxLengthError = false;
          }
        }

        if ($scope.showQuestionError || $scope.showOptionsError || $scope.showOptionalError || $scope.showMaxLengthError) {
          return;
        }

        if (!saveQuestion.temp_id) {
          saveQuestion.temp_id = $scope.getTempId();
          $scope.allQuestions.push(saveQuestion);
        } else {
          $scope.allQuestions.map(function (q) {
            q.temp_id == saveQuestion.temp_id ? q = saveQuestion : null;
          });
          // $scope.allQuestions.push(saveQuestion);
        }

        $scope.hidePopup('#add-questions-modal');
      };

      $scope.createForm = function () {
        if ($scope.formData && (!$scope.formData.name || $scope.formData.name == '' || $scope.formData.name.length < 3)) {
          $scope.showFormSaveError = true;
          return;
        } else {
          $scope.showFormSaveError = false;
        }

        if ($scope.allQuestions && $scope.allQuestions.length < 1) {
          $scope.showFormSaveError = true;
          return;
        } else {
          $scope.showFormSaveError = false;
        }

        $scope.addEditGuestFormApi(null, createRequestObject(true));
      };

      $scope.addEditGuestFormApi = function (id, json, removeFromParent) {
        $scope.showLoader();
        /* add a guest form */
        var form_id = id;
        if ($scope.formData) {
          if ($scope.formData._id || ($scope.formData.id && typeof $scope.formData.id == 'number')) {
            form_id = $scope.formData._id || $scope.formData.id;
          }
          json.action = $scope.formData.action == $scope.actionEdit ? $scope.actionEdit : $scope.actionAdd;
        }
        if (removeFromParent) {
          json.parent_id = $scope.formData.parent_id;
        }
        operatorProfileService.addEditOperatorForms({
          id: form_id,
          json: json,
          token: $scope.user.token
        }).then(function (response) { // fetch from the service
          $scope.hideLoader();
          if (response && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            // TODO: IF FORM ID THEN UPDATE THE FORM.
            if (response.data && response.data.data && response.data.data._id) {
              // NEW FORM ADDED FOR PACKAGE.
              var oldFormIndex = CONST_HELPER.MINUS_ONE;
              $scope.packageForms.find(function (form, index) {
                (form.id == response.data.data._id || form._id == response.data.data._id) ? oldFormIndex = index : null;
                return form.id == response.data.data._id || form._id == response.data.data._id;
              });
              if (json.parent_id && !removeFromParent) {
                // Update existing form data
                var formIndex = CONST_HELPER.MINUS_ONE;
                $scope.packageForms.find(function (form, index) {
                  formIndex = form.id == response.data.data.parent_id ? index : CONST_HELPER.MINUS_ONE;
                  return form.id == response.data.data.parent_id;
                });
                if (formIndex != CONST_HELPER.MINUS_ONE) {
                  $scope.packageForms.splice(formIndex, CONST_HELPER.ONE);
                  $scope.operatorFormModal.splice($scope.operatorFormModal.indexOf(function (form) {
                    return form.id == response.data.data.parent_id;
                  }), CONST_HELPER.ONE);
                }
              }

              if (oldFormIndex != CONST_HELPER.MINUS_ONE) {
                $scope.packageForms[oldFormIndex] = response.data.data;
                if (response.data.data.parent_id == 0) {
                  $scope.packageForms[oldFormIndex].isNewPackForm = true;
                }
              } else {
                $scope.packageForms.push(response.data.data);
                if (response.data.data.parent_id == 0) {
                  $scope.packageForms[($scope.packageForms.length - CONST_HELPER.ONE)].isNewPackForm = true;
                }
              }
            }

            // $state.go('guest-form');
            $scope.formData = {
              name: ''
            };
            $scope.allQuestions = [];
            // $scope.getOperatorForms();
          } else {
            $scope.packageForms.push(json);
          }
        }, function () { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      function createRequestObject (dontSetPackageTemp) {
        var obj = {
          'title': $scope.formData.name,
          // 'id': $scope.formData.id,
          'fields': []
        };

        $scope.allQuestions.forEach(function (question) {
          if (question && question.options) {
            question.options.map(function (option, index) {
              if (question.type == CONST_HELPER.FOUR) {
                option.ref = CONST_HELPER.QUESTION_ID_TEXT[question.type] + '_' + moment().unix() + '_' + Math.floor(Math.random() * Math.floor(CONST_HELPER.TEXTLIMIT)) + '_' + index;
              }
              delete option.$$hashKey;
              delete option.id;
            });
          }

          var tempObj = {
            ref: CONST_HELPER.QUESTION_ID_TEXT[question.type] + '_' + moment().unix() + '_' + Math.floor(Math.random() * Math.floor(CONST_HELPER.TEXTLIMIT)),
            title: question.questionText,
            type: CONST_HELPER.QUESTION_ID_TEXT[question.type],
            validations: {
              required: question.optional == 'true',
              max_length: question.maxlength
            },
            properties: {
              description: question.questionText,
              choices: question.options,
              allow_multiple_selection: question.type == 4,
              allow_other_choice: false,
              vertical_alignment: false
            }
          };
          // MAX LENGTH IS ONLY FOR SHORT TEXT & LONG TEXT
          if (question.type > CONST_HELPER.TWO) {
            delete tempObj.validations.max_length;
          }
          // FOR STATEMENT REQUIRED IS NOT NEEDED
          if (question.type == CONST_HELPER.THREE) {
            tempObj.title = '';
            tempObj.properties.hide_marks = true;
            delete tempObj.validations;
          }

          if (question.type <= CONST_HELPER.THREE) {
            delete tempObj.properties.choices;
            delete tempObj.properties.allow_multiple_selection;
            delete tempObj.properties.allow_other_choice;
            delete tempObj.properties.vertical_alignment;
          } else if (question.type == CONST_HELPER.FIVE) {
            delete tempObj.properties.allow_multiple_selection;
            delete tempObj.properties.allow_other_choice;
            delete tempObj.properties.vertical_alignment;
          }
          obj.fields.push(tempObj);
        });

        if (dontSetPackageTemp) {
          if (!package_id) {
            obj.package_temp_id = !package_temp_id ? getPackageTempId() : package_temp_id;
          } else {
            obj.package_id = package_id;
          }
        }

        obj.parent_id = $scope.formData.parent_id;
        return obj;
      }

      $scope.editSelectedForm = function (form) {
        $scope.formData = form;
        $scope.formData.action = $scope.actionEdit;
        $scope.formData.name = form.title;
        $scope.parseQuestionsData(form.guest_form_detail ? form.guest_form_detail.request : form);
      };

      $scope.confirmSelectedForm = function (form) {
        $scope.selectedForm = form;
        $scope.showPopup('#confirmGuestForm');
      };

      $scope.deleteSelectedForm = function (form) {
        var form_id = form.id;
        $scope.showLoader();
        operatorProfileService.deleteOperatorTypeForm(form_id, $scope.user.token)
          .then(function (response) { // fetch from the service

          if (response && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
              var foundIndex = CONST_HELPER.MINUS_ONE;
              $scope.packageForms.find(function (pform, index) {
                foundIndex = pform._id == form.id || pform.id == form._id ? index : CONST_HELPER.MINUS_ONE;
                return (pform._id == form.id || pform.id == form._id);
              });
              if (foundIndex != CONST_HELPER.MINUS_ONE) {
                // remove from forms array.
                $scope.packageForms.splice(foundIndex, CONST_HELPER.ONE);
              }

              var findIndexModal = CONST_HELPER.MINUS_ONE;
              $scope.operatorFormModal.find(function (pform, index) {
                findIndexModal = pform.id == form.id || pform.id == form._id || pform.id == form.parent_id ? index : CONST_HELPER.MINUS_ONE;
                return pform.id == form.id || pform.id == form._id || pform.id == form.parent_id;
              });
              // Remove from form model
              if (findIndexModal != CONST_HELPER.MINUS_ONE) {
                $scope.operatorFormModal.splice(findIndexModal, CONST_HELPER.ONE);
              }

              $scope.selectedForm = {};

          }
          $scope.hideLoader();
          });
      };
      $scope.parseQuestionsData = function (questionsReq) {
        // TODO SET ALL QUESTIONS OBJECT
        questionsReq = questionsReq && questionsReq.request ? questionsReq.request : questionsReq;
        if (questionsReq) {
          $scope.allQuestions = [];
          questionsReq.fields.forEach(function (field, index) {
            var tempObj = {
              type: CONST_HELPER.QUESTOIN_TYPE[field.type],
              questionText: field.properties.description,
              optional: field.validations ? field.validations.required : false,
              maxlength: field.validations ? field.validations.max_length : CONST_HELPER.ZERO,
              options: field.properties.choices,
              temp_id: $scope.getTempId(),
              value: index + 1
            };
            // MAX LENGTH IS ONLY FOR SHORT TEXT & LONG TEXT
            if (tempObj.type > 2) {
              delete tempObj.max_length;
            }
            // FOR STATEMENT REQUIRED IS NOT NEEDED
            if (tempObj.type == CONST_HELPER.THREE) {
              var _data = $.parseHTML(tempObj.questionText) ? $.parseHTML(tempObj.questionText) : tempObj.questionText;
              tempObj.questionText = _data[0].data;
              delete tempObj.optional;
              delete tempObj.maxlength;
            }

            if (tempObj.type <= CONST_HELPER.THREE) {
              delete tempObj.options;
            }
            $scope.allQuestions.push(tempObj);
          });
        }
      };

      $scope.setBookingLimit = function (value) {
        if(value == 0){
          $scope.newPackage.booking_maximum_days = null;
          $scope.newPackage.booking_minimum_days = null;
        }else{
          $scope.minValueBookingLimit = 1;
          $scope.newPackage.booking_minimum_days = 1;
        }
      }

      $scope.maxShowError  = false;

      $scope.validationBookingLimit = function (minMaxType, value) {

        if ($scope.newPackage.booking_maximum_days && $scope.newPackage.booking_minimum_day && $scope.newPackage.booking_maximum_days < $scope.newPackage.booking_minimum_days) {
          $scope.maxShowError  = true;
        }else{
          $scope.maxShowError  = false;
        }

        if ($scope.newPackage.booking_minimum_days && $scope.newPackage.booking_minimum_days < 1) {
          $scope.minNightlyRateLimitError = true;
        } else {
          $scope.minNightlyRateLimitError = false;
        }
      }
      $scope.waiverDocumentUpload = function() {
        if(!$scope.create_waiver_title || $scope.create_waiver_title.length < 3){
          $scope.createWaiverTitleEmpty = true;
          return;
        }else{
          $scope.createWaiverTitleEmpty = false;
        }
        if(!$scope.create_waiver_file || $scope.create_waiver_file.type != 'application/pdf'){
          $scope.createWaiverFileEmpty = true;
          return;
        }else{
          $scope.createWaiverFileEmpty = false;
        }
        $scope.showLoader();
        var readerFile = new FileReader();
        var fileWaiverAdvnture = '';
        readerFile.addEventListener("load", function () {
          fileWaiverAdvnture = readerFile.result;
        });

        readerFile.readAsDataURL($scope.create_waiver_file);

        readerFile.onloadend = function () {
          var data = {
            'title': $scope.create_waiver_title,
            'document': fileWaiverAdvnture
          }

          if(!$scope.createWaiverTitleEmpty && !$scope.createWaiverFileEmpty){
            operatorProfileService.saveWaiverDocument(data, $scope.user.token).then(function (response) {
              if (response && response.data.code == CONST_HELPER.API_RESPONSE.OK) {
                $scope.create_waiver_title = '';
                $scope.create_waiver_title = '';
                $scope.getWaiverAdventureList(response.data.data.id);
                $scope.hideLoader();
              }
            }, function () { // check for error
              $scope.hideLoader();
              $scope.showPopup('#serverErrModal');
            });
          }
        }
      }

      $scope.clearWaiverField = function() {
        $('#waiver_file').val('');
      }

      $scope.deleteSelectedWaiverDocument = function (id) {
        var selectedContainer = '#selected_waiver_document_' + id
        angular.element(document.querySelector(selectedContainer)).remove();
        $scope.newPackage.waiver = null;
      }

      $scope.changeTaxCheckboxes = function (tax) {
        tax.is_per_person = false;
        tax.is_per_day_night = false;
      }


      $scope.validateTaxValue = function (taxObj) {
        if (taxObj.calculation_type == 1 && taxObj.value >= 100) {
          taxObj.errorValuePer = true;
        } else {
          delete taxObj.errorValuePer;
        }
      }
    }
  ]);
