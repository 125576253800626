import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StateService, UIRouter } from '@uirouter/core';
import { RootScopeService } from '../../services/root-scope/root-scope.service';
import { SearchServiceService } from '../../services/search-service/search-service.service';
import { LoadAdventuresService } from '../../services/load-adventures/load-adventures.service';

@Component({
  selector: 'app-search-panel',
  templateUrl: '/ng-app/src/app/components/search-panel/search-panel.component.html',
  styleUrls: ['../../../../../ng-app/src/app/components/search-panel/search-panel.component.css'],
})
export class SearchPanelComponent implements OnInit {

  @ViewChild('select', { static: false }) select: ElementRef;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  currencySymbol = {
    'INR': 'INR',
    'USD': 'USD',
    'CAD': 'CAD',
    'EUR': 'EUR',
    'JPY': 'JPY',
    'GBP': 'GBP',
    'NZD': 'NZD',
    'AUD': 'AUD',
    'ISK': 'ISK',
    'MXN': 'MXN',
    'CHF': 'CHF',
    'BRL': 'BRL'
  };

  constructor(
    public searchService: SearchServiceService,
    public loadAdventuresService: LoadAdventuresService,
    public stateService: StateService,
    public rootScopeService: RootScopeService,
  ) { }

  ngOnInit(): void {
  }

  clearSearchBar() {
    this.rootScopeService.searchValue = '';
    this.searchInput.nativeElement.focus();
  }

}

